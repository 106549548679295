import React from 'react'

import { Wrapper } from './GridItem.styles'

const GridItem = ({ children, refId, onClick, ...props }) => {
    return (
        <Wrapper ref={refId} onClick={onClick}{...props}>{children}</Wrapper>
    )
}

export default GridItem