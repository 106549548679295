import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
// phone number optional, email before number
import Step from './Step'
import NavArrow from './NavArrow'
import Flexbox from '../Flexbox'
import ProgressBar from './ProgressBar'
import FormGrid from './FormGrid'
import PageContentContainer from '../PageContentContainer'
import GridContainer from '../GridContainer'
import FormContentContainer from './FormContentContainer'
import Button from '../Button'
import { GridWrapper } from './Form.styles'
import { RLink } from '../PrivacyPolicy'
import gameDevelopment from '../../images/game-dev.svg'
import artificialIntelligence from '../../images/artificial-intelligence.svg'
import dataAnalytics from '../../images/data-analytics.svg'
import blockchainDev from '../../images/blockchain-development.svg'
import augmentedReality from '../../images/augmented-reality.svg'
import marketplace from '../../images/marketplace.svg'
import notSure from '../../images/not-sure.svg'
import internetOfThings from '../../images/internet-of-things.svg'

import mobileApp from '../../images/mobile-app.svg'
import webDev from '../../images/web-dev.svg'
import customSoftware from '../../images/custom-software.svg'
import maintenance from '../../images/maintenance.svg'
import other from '../../images/other.svg'

import projectCost from '../../images/project-cost.svg'
import futureScalability from '../../images/future-scalability.svg'
import developmentTime from '../../images/development-time.svg'
import userExperienceProductDesign from '../../images/user-experience-product-design.svg'


import agencyDoesDesignWork from '../../images/no-design-work-needed.svg'
import agencyDoesNotDoDesignWork from '../../images/notApplicable.svg'

import enterprise from '../../images/enterprise.svg'
import communication from '../../images/communication.svg'
import postlaunchmaintenanceexpansion from '../../images/post-launch-maintenance-expansion.svg'

import onshore from '../../images/onshore.svg'
import offshore from '../../images/offshore.svg'

import node from '../../images/node.svg'
import python from '../../images/python.svg'
import mongodb from '../../images/mongodb2.svg'
import react from '../../images/react.svg'
import vue from '../../images/vue.svg'
import angular from '../../images/angular.svg'
import go from '../../images/go_1.svg'
import express from '../../images/express.svg'
import aws from '../../images/aws.svg'
import lambdaAWS from '../../images/lambdaAWS.svg'
import microsoftAzure from '../../images/microsoftAzure.svg'
import php from '../../images/php.svg'
import nosql from '../../images/nosql.svg'
import sql from '../../images/sql.svg'
import mysql from '../../images/mysql.svg'
import graphql from '../../images/graphql.svg'
import cassandra from '../../images/cassandra.svg'
import ios from '../../images/iOS.svg'
import android from '../../images/android.svg'
import firebase from '../../images/Firebase_Logo_Vertical_Lockup.svg'
import apache from '../../images/apache.svg'
import linux from '../../images/linux.svg'
import googleCloud from '../../images/googleCloud.svg'
import rubyOnRails from '../../images/ruby-on-rails.svg'
import django from '../../images/django-logo.svg'
import postgresql from '../../images/postgresql.svg'
import cardano from '../../images/cardano.svg'
import ethereum from '../../images/ethereum2.svg'
import solidity from '../../images/solidity.svg'

import API from '../../API'
import { useUnload } from '../../hooks/useUnload'

import { formatPhoneNumber, IconSelectionOption } from '../../utils'
import states from '../../utils/states.js'
import GridItem from '../GridItem'

export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function validateName(str) {
    return /\d/.test(str);
}

const Form = () => {
    const location = useLocation()
    const formAbandonment = useRef('')
    const formEntry = useRef('Embedded Header')
    const navigate = useNavigate()
    useEffect(() => {
        if (location.state && location.state.form_entry) {
            formEntry.current = location.state.form_entry
        }
    }, [])
    const [serverResponse, setServerResponse] = useState({ message: '', shouldShow: false })
    let previousFormData = window.sessionStorage.getItem('formData')

    if (previousFormData) {
        previousFormData = JSON.parse(previousFormData)
    }
    const getPrevValue = (name, defaultVal = '') => {
        return previousFormData && previousFormData[name] ? previousFormData[name] : defaultVal
    }
    const _data = {
        steps: {
            'step-intro': {
                id: 'step-intro',
                name: 'moreInfo',
                type: 'text',
                value: '',
                heading: "Ready to get started?",
                subtextInternal: true,
                subtext:
                    <p style={{ margin: '0', maxWidth: '700px' }}>In this short form we'll ask which lead types you're interested in, and ask about your development services so we can match you with the right potential clients. <br ></br><br ></br>
                        We'll also ask for a phone number or email where we can reach out to confirm pricing and get you setup to begin receiving leads.
                    </p>,
                labelInternal: true,
                validate: function () {
                    return true
                }
            },
            'step-5': {
                id: 'step-5',
                name: 'verifyPortfolio',
                type: 'textArea',
                placeholder: 'Please enter link(s) to your portfolio, client feedback on review sites, etc., where we can confirm the quality of your work.',
                label: 'How can we learn more about you?',
                labelInternal: true,
                subtextInternal: true,
                subtext: "Optional, but we'll need this before activating your account.",
                subtextStyling: {
                    // colMobile: '1 / span 2',

                    fontSize: '14px', textAlign: 'center', justifyContent: 'center', margin: '0', fontWeight: '700'
                },
                labelStyling: { justifyContent: 'center', margin: '0', },
                // inputField: '',
                handleMenuStateInternally: true,
                enum: [
                    { id: 'option-1', innerText: 'Website', value: 'Website' },

                ],
                value: getPrevValue('verifyPortfolio', ''),

            },
            'final-step': {
                id: 'final-step',
                type: 'final-step',
                name: 'finalStep',
                fieldOrder: ['field-4', 'field-5', 'field-3', 'field-1', 'field-2'],
                validate: function () {
                    let isValid = true
                    let keys = this.fieldOrder
                    console.log("validating last step...", this.fields, keys)
                    for (let index = 0; index < keys.length; index++) {
                        const element = this.fields[keys[index]];
                        if (typeof (element['validate']) === 'function') {
                            let v = element.validate()
                            if (!v['value']) {
                                isValid = false
                                setServerResponse({ message: v['message'] ? v['message'] : '', shouldShow: false })
                                break
                            }
                        }
                    }
                    if (isValid)
                        setServerResponse({ message: '', shouldShow: false })
                    return isValid
                },
                fields: {
                    'field-1':
                    {

                        id: 'field-1',
                        name: 'phoneNumber',
                        placeholder: { phoneNumber: '123-456-7890', extension: '25' },
                        label: "Phone (Optional)",
                        labelInternal: true,
                        type: "tel",
                        value: getPrevValue('phoneNumber', { phoneNumber: '', extension: '' }),
                        validate: function () {
                            return ({ value: true, message: '' })
                            if (this.value['phoneNumber'].length > 0 && !/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(this.value['phoneNumber']))
                                return ({ value: false, message: 'Invalid phone number' })
                            if (this.value['extension'] === '')
                                return ({ value: true, message: '' })
                            if (/^[0-9]+$/.test(this.value['extension']))
                                return ({ value: true, message: '' })
                            return ({ value: false, message: 'Invalid phone number' })

                        },
                        onKeyDown: function (event) {
                            console.log("Event", event)
                        },
                        onChange: function (event, type = 'phoneNumber') {
                            let _val = event.target.value.replace(/\D/g, '')
                            console.log("_val", _val, type)
                            setServerResponse({ message: '', shouldShow: false })
                            if (type === 'phoneNumber') {
                                let currValue = this.value['phoneNumber']
                                console.log("currValu", currValue)

                            }
                            setState(prev => ({
                                ...prev,
                                steps: {
                                    ...prev.steps,
                                    ['final-step']: {
                                        ...prev.steps['final-step'],
                                        fields: {
                                            ...prev.steps['final-step'].fields,
                                            [this.id]: {
                                                ...prev.steps['final-step'].fields[this.id],
                                                value: { ...prev.steps['final-step'].fields[this.id].value, [type]: _val }
                                            }
                                        }
                                    }
                                }
                            }))
                        },
                    },
                    'field-2': {
                        id: 'field-2',
                        name: 'additionalDetails',
                        type: 'textArea',
                        placeholder: 'Additional Details',
                        label: "Comments (Optional)",
                        value: getPrevValue('additionalDetails'),
                        maxlength: 500,
                        rows: 5,
                        onChange: function (event) {
                            const _val = event.target.value
                            setServerResponse({ message: '', shouldShow: false })
                            setState(prev => ({
                                ...prev,
                                steps: {
                                    ...prev.steps,
                                    ['final-step']: {
                                        ...prev.steps['final-step'],
                                        fields: {
                                            ...prev.steps['final-step'].fields,
                                            [this.id]: {
                                                ...prev.steps['final-step'].fields[this.id],
                                                value: _val
                                            }
                                        }
                                    }
                                }
                            }))

                        }
                    },
                    'field-3': {
                        id: 'field-3',
                        name: 'email',
                        placeholder: 'Enter your email',
                        label: "Email",
                        value: getPrevValue('email'),
                        validate: function () {
                            return { value: validateEmail(this.value), message: 'Invalid email' }
                        },
                        onChange: function (event) {
                            const _val = event.target.value

                            setServerResponse({ message: '', shouldShow: false })
                            setState(prev => ({
                                ...prev,
                                steps: {
                                    ...prev.steps,
                                    ['final-step']: {
                                        ...prev.steps['final-step'],
                                        fields: {
                                            ...prev.steps['final-step'].fields,
                                            [this.id]: {
                                                ...prev.steps['final-step'].fields[this.id],
                                                value: _val
                                            }
                                        }
                                    }
                                }
                            }))

                        }
                    },
                    'field-4': {
                        id: 'field-4',
                        name: 'name',
                        placeholder: 'Enter your name',
                        label: "Name",
                        value: getPrevValue('name'),
                        validate: function () {
                            return ({ value: !/\d/.test(this.value) && this.value.trim() !== '', message: 'Name required' })
                        },
                        onChange: function (event) {
                            const _val = event.target.value

                            setServerResponse({ message: '', shouldShow: false })
                            setState(prev => ({
                                ...prev,
                                steps: {
                                    ...prev.steps,
                                    ['final-step']: {
                                        ...prev.steps['final-step'],
                                        fields: {
                                            ...prev.steps['final-step'].fields,
                                            [this.id]: {
                                                ...prev.steps['final-step'].fields[this.id],
                                                value: _val
                                            }
                                        }
                                    }
                                }
                            }))

                        }
                    },
                    'field-5': {
                        id: 'field-5',
                        name: 'company',
                        placeholder: 'Company',
                        label: "Company",
                        value: getPrevValue('company'),
                        validate: function () {
                            return ({ value: !/\d/.test(this.value) && this.value.trim() !== '', message: 'Company required' })
                        },
                        onChange: function (event) {
                            const _val = event.target.value

                            setServerResponse({ message: '', shouldShow: false })
                            setState(prev => ({
                                ...prev,
                                steps: {
                                    ...prev.steps,
                                    ['final-step']: {
                                        ...prev.steps['final-step'],
                                        fields: {
                                            ...prev.steps['final-step'].fields,
                                            [this.id]: {
                                                ...prev.steps['final-step'].fields[this.id],
                                                value: _val
                                            }
                                        }
                                    }
                                }
                            }))

                        }
                    },
                },
            },
            'step-1': {
                id: 'step-1',
                name: 'platforms',
                type: 'iconSelection',
                value: getPrevValue('platforms', []),
                textAreaValue: '',
                validate: function () {
                    return this.value.length > 0
                },
                multiSelect: true,
                itemsPerRow: 3,
                itemsPerRowMobile: 1,
                itemMargin: '7.5px',
                showTextOnly: false,
                isOptional: true,
                storeFullOption: true,
                label: <>What general lead types are you looking for?
                    {/* <br />
                    <span style={{ fontSize: '14px' }}>Select one or more.</span> */}
                </>,
                labelInternal: true,
                enum: {
                    "mobile-app": new IconSelectionOption('Mobile App', mobileApp),
                    "web-dev": new IconSelectionOption('Web', webDev, 'web-dev'),
                    "other-1": new IconSelectionOption('Other', customSoftware, 'other-1')
                },
                imgProps: {
                    height: '100px',
                    width: 'auto',
                    mobileHeight: '50px',
                    minWidthMobile: '50px',
                    minHeightMobile: '50px',
                },
                tileProps: {
                    maxHeight: '200px',
                    fontSize: "calc(1/0.90 * 18px)",
                },

            },
            'step-2': {
                id: 'step-2',
                name: 'subcategory',
                type: 'iconSelection',
                multiSelect: true,
                itemsPerRow: 4,
                itemsPerRowMobile: 2,
                itemMargin: '7.5px',
                enum: {
                    "game-development": new IconSelectionOption('Game Development', gameDevelopment),
                    "artificial-intelligence": new IconSelectionOption('Machine Learning / AI', artificialIntelligence, 'artificial-intelligence'),
                    "data-analytics": new IconSelectionOption('Data Analytics', dataAnalytics),
                    "blockchain-dev": new IconSelectionOption('Blockchain Development', blockchainDev, 'blockchain-dev'),
                    "augmented-or-virtual-reality": new IconSelectionOption('Augmented or Virtual Reality', augmentedReality, 'augmented-or-virtual-reality', <>Augmented or<br />Virtual Reality</>),
                    "enterprise": new IconSelectionOption('Enterprise / Government', enterprise, 'enterprise'),
                    "internet-of-things": new IconSelectionOption('Internet of Things', internetOfThings, 'internet-of-things'),
                    "other-2": new IconSelectionOption('Other', other, 'other-2', 'Other', { height: '40px', width: '100%' }),
                },
                imgProps: { height: '50px', },
                tileProps: {
                    maxWidth: '250px',
                },
                mobileTileProps: {
                    fontSize: 'calc(14px * 1/0.9)',
                    // mobileMaxHeight: 'calc((100vh - 50px)/4 - 4 * 20px)',
                    minHeightMobile: '100px',
                    //  mobileWidth: '100%', 
                    justify: 'center',
                    display: 'flex'
                },
                value: getPrevValue('subcategory', []),
                showTextOnly: false,
                isOptional: true,
                storeFullOption: true,
                hideSubtextLabel: true,
                label: <>What specialty lead types are you looking for?<br /><span style={{ fontSize: '14px' }}>Select all that apply. (Optional)</span></>,
                // label: <>Select desired lead categories.</>,
                labelInternal: true,
                // dependency: 'platforms',
                onDependencyUpdate: function (categoryId, state = '') {
                    setState(prev => ({
                        ...prev,
                        steps: {
                            ...prev.steps,
                            [this.id]: {
                                ...prev.steps[this.id],
                                "category-enum": this.getEnum(categoryId)
                            }
                        }
                    }))
                },

            },
            'step-7': {
                id: 'step-7',
                name: 'technologies',
                type: 'iconSelection',
                multiSelect: true,
                itemsPerRow: 6,
                itemsPerRowMobile: 2,
                itemMargin: '7.5px',
                enum: {
                    "not-sure-4": new IconSelectionOption('Not Sure Yet', notSure, 'not-sure-4'),
                    "ios": new IconSelectionOption('iOS', ios, 'ios'),
                    "android": new IconSelectionOption('Android', android, 'android'),
                    "node": new IconSelectionOption('Node', node, 'node'),
                    "python": new IconSelectionOption('Python', python, 'python'),
                    "mongodb": new IconSelectionOption('MongoDB', mongodb, 'mongodb'),
                    "react": {
                        text: "React",
                        displayName: "React",
                        id: 'react',
                        img: react,
                    },
                    "vue": {
                        text: "Vue",
                        displayName: "Vue",
                        id: 'vue',
                        img: vue,
                    },
                    "angular": {
                        text: "Angular",
                        displayName: "Angular",
                        id: 'angular',
                        img: angular,
                    },
                    "go": {
                        text: "Go / Golang",
                        displayName: "Go / Golang",
                        id: 'go',
                        img: go,
                    },
                    "firebase": {
                        text: "",
                        displayName: "Firebase",
                        id: 'firebase',
                        img: firebase,
                        imgProps: { height: '100px' }
                    },
                    "google-cloud": {
                        text: "Google Cloud",
                        displayName: "Google Cloud",
                        id: 'google-cloud',
                        img: googleCloud,
                    },
                    "cardano": {
                        text: "Cardano",
                        displayName: "Cardano",
                        id: 'cardano',
                        img: cardano,
                    },
                    "ethereum": {
                        text: "Ethereum",
                        displayName: "Ethereum",
                        id: 'ethereum',
                        img: ethereum,
                    },
                    "solidity": {
                        text: "Solidity",
                        displayName: "Solidity",
                        id: 'solidity',
                        img: solidity,
                    },
                    "ruby-on-rails": {
                        text: "Ruby On Rails",
                        displayName: "Ruby On Rails",
                        id: 'ruby-on-rails',
                        img: rubyOnRails,
                    },
                    "django": {
                        text: "",
                        displayName: "Django",
                        id: 'django',
                        img: django,
                    },
                    "postgresql": {
                        text: "PostgreSQL",
                        displayName: "PostgreSQL",
                        id: 'postgresql',
                        img: postgresql,
                    },
                    "express": {
                        text: "ExpressJS",
                        displayName: "ExpressJS",
                        id: 'express',
                        img: express,
                        imgProps: { width: '80px' }
                    },
                    "apache": {
                        text: "Apache",
                        displayName: "Apache",
                        id: 'apache',
                        img: apache,
                    },
                    "linux": {
                        text: "Linux",
                        displayName: "Linux",
                        id: 'linux',
                        img: linux,
                    },
                    "aws": {
                        text: "Amazon Web Services",
                        displayName: "Amazon Web Services",
                        id: 'aws',
                        img: aws,
                    },
                    "aws-lambda": {
                        text: "AWS Lambda",
                        displayName: "AWS Lambda",
                        id: 'aws-lambda',
                        img: lambdaAWS,
                    },
                    "microsoft-azure": {
                        text: "Azure",
                        displayName: "Azure",
                        id: 'microsoft-azure',
                        img: microsoftAzure,
                    },
                    "php": {
                        text: "",
                        displayName: "php",
                        id: 'php',
                        img: php,
                    },
                    "nosql": {
                        text: "NoSQL",
                        displayName: "NoSQL",
                        id: 'nosql',
                        img: nosql,
                    },
                    "SQL": {
                        text: "SQL",
                        displayName: "SQL",
                        id: 'sql',
                        img: sql,
                    },
                    "mysql": {
                        text: "MySQL",
                        displayName: "MySQL",
                        id: 'mysql',
                        img: mysql,
                    },
                    "graphql": {
                        text: "GraphQL",
                        displayName: "GraphQL",
                        id: 'graphql',
                        img: graphql,
                    },
                    "cassandra": {
                        text: "cassandra",
                        displayName: "cassandra",
                        id: 'cassandra',
                        img: cassandra,
                    },
                },
                get sortedOptions() {
                    const _enum = this.enum
                    return Object.keys(_enum).sort((a, b) => {
                        return (_enum[a].displayName === 'Not Sure Yet' ? -1 : _enum[a].displayName.localeCompare(_enum[b].displayName))
                    })
                },
                tileProps: {
                    // flexBasis: `calc(${1 / 6 * 100}% - 5px * 6 * 2)`,
                    // width: `calc(${1 / 6 * 100}% - 5px * 6)`,
                    maxWidth: '250px',
                    // flexDirection: 'column',
                    minWidth: '100px',
                    fontSize: "calc(1/0.90 * 1.0rem)",
                    flexShrink: '0',
                    maxHeight: '150px',

                    flexBasis: `calc(${1 / 6 * 100}% - 7.5px * ${6 - 1})`,

                    width: `calc(${1 / 6 * 100}% - 7.5px * ${6 - 1})`,
                },
                mobileTileProps: {
                    mobileFontSize: 'calc(14px * 1/0.9)',
                    mobileHeight: 'auto',
                    // mobileMaxHeight: 'calc((100vh - 50px)/4 - 4 * 20px)',
                    minHeightMobile: '110px',
                    // mobileFlexBasis: `calc(${1 / 2 * 100}% - 5px * 2 * 4)`,
                    // mobileWidth: `calc(${1 / 2 * 100}% - 5px * 4)`,
                    flexGrowMobile: '0',
                    flexShrinkMobile: '1',

                },
                imgProps: { margin: '10px 0 0', height: '50px' },
                containerProps: {
                    height: 'calc(100% + 7.5px * 5)',
                    mobileHeight: '100%',
                    mobileFontSize: 'calc(14px * 1/0.9)',
                    // maxHeight: 'calc(2 * 220px + 2*10px)',
                    // mobileMaxHeight: 'calc((100vh )/4 - 4 * 20px)',
                    width: '100%',
                    // maxWidth: "1100px",
                },
                value: getPrevValue('technologies', []),
                showTextOnly: false,
                isOptional: true,
                storeFullOption: true,
                hideSubtextLabel: true,
                label: <>Clients may request specific technologies.<br /><p style={{ fontSize: '17px' }}>Please select all that apply to your company.<span style={{ fontSize: '14px' }}><br />(Optionally, you can skip this for now and tell us later)</span></p></>,
                labelInternal: true,

            },
            'step-6': {
                id: 'step-6',
                name: 'developerLocation',
                label: <>Where are you located?<p style={{ fontSize: '17px' }}>If you have locations or staff inside and outside the US, choose both.</p></>,
                labelInternal: true,
                type: 'iconSelection',
                multiSelect: true,
                itemsPerRow: 2,
                itemsPerRowMobile: 1,
                itemMargin: '7.5px',
                showTextOnly: false,
                isOptional: true,
                validate: function () {
                    return this.value.length > 0
                },
                enum: {
                    "united-states": {
                        text: "United States",
                        displayName: "United States",
                        id: 'united-states',
                        img: onshore,
                    },
                    "offshore": {
                        text: "Offshore",
                        displayName: "Offshore",
                        id: 'offshore',
                        img: offshore,
                    },
                },
                imgProps: { height: '70px', width: '100%' },
                tileProps: {

                    minWidth: '156px',
                    textPosition: 'top',
                    fontSize: "calc(1/0.90 * 1.0rem)",
                    // height: '100%', 
                    // maxHeight: '150px', 
                },
                imgProps: { margin: '10px 0 0', height: '50px', width: '100%', mobileHeight: '50px' },
                value: getPrevValue('developerLocation', []),
            },
            'step-4': {
                id: 'step-4',
                name: 'strengths',
                hideSubtextLabel: true,
                label: <>Rank your strengths.<br /><p>Select at least one option:</p></>,
                type: 'iconSelection',
                validate: function () {
                    return this.value.length > 0
                },
                multiSelect: true,
                itemsPerRow: 3,
                itemsPerRowMobile: 1,
                itemMargin: '16px',
                showIndex: true,
                enum: {
                    "budget-friendly": {
                        text: "Budget Friendly",
                        id: 'budget-friendly',
                        img: projectCost,
                    },
                    "development-time": {
                        text: "Development Time",
                        id: 'development-time',
                        img: developmentTime,
                    },
                    "future-scalability": {
                        text: "Future Scalability",
                        id: 'future-scalability',
                        img: futureScalability,
                    },
                    "user-experience-product-design": {
                        text: "UX / Product Design", //follow-up qs: what chain?
                        id: 'user-experience-product-design',
                        img: userExperienceProductDesign,
                    },
                    "communication": {
                        text: "Communication", //follow-up qs: what chain?
                        id: 'communication',
                        img: communication,
                    },
                },
                tileProps: {
                    // flexBasis: `calc(${1 / 3 * 100}% - 5px * 3 * 2)`,
                    // width: `calc(${1 / 3 * 100}% - 5px * 3)`,
                    maxWidth: '250px',
                    fontSize: 'calc(14px * 1/0.9)',
                    // margin: '15px',
                    maxHeight: '150px',
                },
                imgProps: {
                    margin: '10px 0 0',
                    height: '50px',
                },
                value: getPrevValue('strengths', []),
                showTextOnly: false,
                isOptional: true,
                labelInternal: true,
                onDependencyUpdate: function (categoryId, state = '') {
                    setState(prev => ({
                        ...prev,
                        steps: {
                            ...prev.steps,
                            [this.id]: {
                                ...prev.steps[this.id],
                                "category-enum": this.getEnum(categoryId)
                            }
                        }
                    }))
                },
            },
            'step-3': {
                id: 'step-3',
                name: 'designWork',
                type: 'iconSelection',
                multiSelect: false,
                value: getPrevValue('designWork', "N/A"),
                showTextOnly: false,
                isOptional: true,
                validate: function () {
                    return Object.keys(this.enum).includes(this.value)
                },
                label: 'Do you design user interfaces?',
                labelInternal: true,
                itemsPerRow: 2,
                itemsPerRowMobile: 1,
                itemMargin: '7.5px',
                enum: {
                    "design-agency-true": {
                        text: "Yes",
                        id: 'design-agency-true',
                        img: agencyDoesDesignWork,

                    },
                    "design-agency-false": {
                        text: "No",
                        id: 'design-agency-false',
                        img: agencyDoesNotDoDesignWork,
                    },

                },
                tileProps: {
                    minWidth: '200px',
                },
                containerProps: {
                    width: '100%',
                    minWidth: '600px',
                    mobileMinWidth: 'min-content'
                },
                onDependencyUpdate: function (categoryId, state = '') {
                    setState(prev => ({
                        ...prev,
                        steps: {
                            ...prev.steps,
                            [this.id]: {
                                ...prev.steps[this.id],
                                "category-enum": this.getEnum(categoryId)
                            }
                        }
                    }))
                },
            },
            'submit': {
                id: 'submit',
                name: 'submit',
            },


        },
        stepOrder: [
            'step-intro',
            'step-1',
            'step-2',
            'step-7',
            'step-3',
            'step-4',
            'step-5',
            'step-6',
            'final-step',
            'submit'
        ],
        get active() { return getPrevValue("active", this.stepOrder[0]) },
        startTime: Date.now(),
        endTime: null,
    }
    const [state, setState] = useState(_data)
    const [disabled, setDisabled] = useState(false)
    const [isKeyReleased, setIsKeyReleased] = useState(false)
    const initialRender = useRef(true)
    const handleChange = (name, _value = null) => (event) => {
        const stepId = getStep(name)
        if (name === 'geographicArea' && _value) {
            setState(prev => ({
                ...prev,
                steps: {
                    ...prev.steps,
                    [stepId]: {
                        ...prev.steps[stepId],
                        value: [...prev.steps[stepId].value, _value]
                    }
                }
            }))
        }
        else if (name !== 'phoneNumber' && event.target) {
            let value = event.target.value
            setState(prev => ({
                ...prev,
                steps: {
                    ...prev.steps,
                    [stepId]: {
                        ...prev.steps[stepId],
                        value
                    }
                }
            }))
        }
    }

    const { steps, active, stepOrder, } = state
    const formLength = Object.keys(stepOrder).length

    const formatData = (flatten = true) => {
        const data = {}
        data.startTime = state['startTime']
        data.endTime = Date.now()
        stepOrder.forEach(stepId => {
            if (stepId === "submit")
                return

            const step = steps[stepId]
            if (!flatten) {
                data[step.name] = step.value
                if (step.name === 'finalStep') {
                    Object.keys(step['fields']).forEach(fieldKey => {
                        const field = step.fields[fieldKey]
                        data[field.name] = field.value
                    })
                }
                return
            }
            if (step.name === 'geographicArea')
                data["ste_name"] = step.value
            if (step.name === "geographicAreaDetailed") {
                data["city"] = step.value.value
                // data["ste_name"] = step.value.state
                return
            }
            if (step.name === "testType") {
                data[step.name] = step.value.value
                // data["ste_name"] = step.value.state
                return
            }

            if (step.name === "courtDate") {

                data[step.name] = new Date(...step.value.split('-').map((it, index) => {
                    let val = parseInt(it)
                    if (index === 1)
                        val -= 1
                    return parseInt(it)
                }))

                return
            }
            if (step.name === 'finalStep') {
                Object.keys(step['fields']).forEach(fieldKey => {
                    const field = step.fields[fieldKey]
                    data[field.name] = field.value
                })
            }
            if (Array.isArray(step.value)) {
                data[step.name] = step.value.map(it => it['id'] ? it['id'].includes('not-sure') ? { id: 'not-sure', displayName: 'Not Sure' } : it['id'].includes('other') ? { id: 'other', displayName: 'Other' } : it : it.includes('not-sure') ? 'not-sure' : it)
                // data[step.name] = step.value.map(it => it.includes('not-sure') ? 'not-sure' : it)
            } else if (typeof (step.value) === 'string')
                data[step.name] = step.value.includes('not-sure') ? 'not-sure' : step.value.includes('other') ? 'other' : step.value
            else
                data[step.name] = step.value

        })
        return data
    }

    const submitForm = async () => {
        try {
            console.log("submitting")
            const data = formatData()
            console.log("DATA: ", data)
            const res = await API.submitForm(data)
            if (window.sessionStorage.getItem('formData'))
                sessionStorage.removeItem('formData')
            if (res) {
                if (window.dataLayer && Array.isArray(window.dataLayer)) {
                    let ev = {
                        'event': 'form_submission',
                        'form_id': 'PRIMARY FORM',
                        'form_entry': formEntry.current,
                        'isCAC': true
                    }
                    window.dataLayer.push(ev)
                }
            }
            navigate('/success')
        } catch (error) {
            console.log("an error occurred", error)
            if (error.response['data']) {
                const { response: { status, data: { msg } } } = error
                sessionStorage.setItem('formData', JSON.stringify({ ...formatData(false), active: stepOrder[stepOrder.length - 2] }))
                navigate('/error', { state: { error: { status, msg }, ref: 'form-submission' } })
            } else {
                sessionStorage.setItem('formData', JSON.stringify({ ...formatData(false), active: stepOrder[stepOrder.length - 2] }))
                navigate('/error', { state: { error: { status: error.response.status, msg: "An error occurred" }, ref: 'form-submission' } })
            }

        }

    }

    const getStep = (fieldName) => {
        const stepId = Object.keys(steps).find(id => {
            return steps[id].name === fieldName
        })
        return stepId
    }

    const getSelectedStates = () => {
        const stepId = getStep('geographicArea')
        if (stepId) {
            let _val = steps[stepId].value
            return steps[stepId].value
        }

    }

    useUnload((e) => {

        if (window.dataLayer && Array.isArray(window.dataLayer)) {
            if (window.dataLayer.find(it => it['event'] === 'form_submission') === undefined) {
                window.dataLayer.push({
                    'event': 'form_abandonment',
                    'eventCategory': 'Form Abandonment',
                    'eventAction': formAbandonment.current
                })
            }
        }
    })

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false
            return
        }
        validateActiveStep()
        sessionStorage.setItem('formData', JSON.stringify({ ...formatData(false), active }))
    }, [state])
    useEffect(() => {
        if (Array.isArray(window.dataLayer)) {
            if (!formAbandonment.current.includes(steps[active].name)) {
                if (formAbandonment.current === '') {
                    formAbandonment.current = steps[active].name
                }
                else
                    formAbandonment.current = formAbandonment.current + ` > ${steps[active].name}`
                window.dataLayer.push({
                    'event': 'form_engagement',
                    'eventCategory': 'Form Engagement',
                    'eventAction': formAbandonment.current
                });
            }
        }
    }, [active])

    const validateActiveStep = () => {
        if (steps[active]) {
            const valid = steps[active].validate ? steps[active].validate(steps[active].value) : true
            if (valid) {
                setDisabled(false)
            } else {
                setDisabled(true)
            }
        }
    }

    async function nextStep() {
        const activeIndex = getActiveIndex()
        const nextStep = activeIndex + 1

        if (steps[active].onNextStep) {
            let res = steps[active].onNextStep()
            if (res === false)
                return
        }
        else if (disabled)
            return
        if (isLastStep()) {
            setState(prev => ({ ...prev, active: stepOrder[nextStep] }))
            submitForm()

        }
        else if (nextStep < formLength)
            setState(prev => ({ ...prev, active: stepOrder[nextStep] }))

    }

    const getActiveIndex = (_steps = stepOrder) => _steps.findIndex(stepId => stepId === active)
    //
    const backStep = () => {
        const _steps = Object.keys(steps)
        const activeIndex = stepOrder.findIndex(stepId => stepId === active)//getActiveIndex(_steps)
        const nextStep = activeIndex - 1
        if (nextStep >= 0)
            setState(prev => ({ ...prev, active: stepOrder[nextStep] }))
    }

    const onKeyDown = (name) => (e) => {
        const { key } = e;
        if (key === 'Enter') {
            nextStep()
        }
        else {
            const stepId = getStep(name)
            let value = key
            if (name === 'phoneNumber') {
                const { fields } = steps[active]
                const matchingField = Object.keys(fields).find(key => {
                    const field = fields[key]
                    console.log("field")
                    return field.name === name
                })


                if (matchingField) {
                    let _val = steps[active].fields[matchingField].value['phoneNumber']
                    if (key === 'Backspace' && isKeyReleased) {
                        const _noDash = (_val).replace(/\D/g, '')
                        _val = _noDash.slice(0, _noDash.length - 1)
                        console.log("_noDash", _noDash, _val)
                        _val = formatPhoneNumber(_val)
                    } else {
                        _val = _val + key.replace(/\D/g, '')
                        const _noDash = (_val).replace(/\D/g, '')
                        _val = formatPhoneNumber(_noDash)

                    }
                    if (name === 'phoneNumber')
                        setState(prev => ({
                            ...prev,
                            steps: {
                                ...prev.steps,
                                [active]: {
                                    ...prev.steps[active],
                                    fields: {
                                        ...prev.steps[active].fields,
                                        [matchingField]: {
                                            ...prev.steps[active].fields[matchingField],
                                            value: { ...prev.steps[active].fields[matchingField].value, phoneNumber: _val }
                                        }
                                    }
                                }
                            }
                        }))
                }

                // }
            }
            // setIsKeyReleased(false);
        }
    }

    const onKeyUp = () => {
        setIsKeyReleased(true);
    }



    const filterDuplicatesOut = (arr, val) => {
        console.log("arr:::", arr, val)
        if (typeof (val) === 'string') {
            return arr.reduce((a, b) => {
                if (a.includes(b))
                    return a.filter(it => it !== b)
                else
                    a.push(b)
                return a
            }, [])
        } else {
            return arr.reduce((a, b, index, array) => {
                if (a.findIndex(el => el['id'] === b['id']) >= 0)
                    return a.filter(it => it['id'] !== b['id'])
                else if (!b['id'].includes('not-sure'))
                    a.push(b)
                return a
            }, [])
        }
    }
    /*
    if storeFullOption is true for the step, option is passed as an object else it's a string
   */
    const iconSelectionOnChange = (stepId, option) => {
        const step = steps[stepId]
        if (step) {

            let storeFullOption = step.storeFullOption || false
            let multiSelect = step.multiSelect || false
            let currentValue = step.value
            let newValue, arrIncludesNotSure, newValueIsNotSure;
            if (storeFullOption) {
                newValue = (({ id, displayName }) => ({ id, displayName }))(option)
            } else if (typeof (option) !== 'string') {
                newValue = option['id']
            }
            if (multiSelect) {
                arrIncludesNotSure = currentValue.findIndex(el => el['id'] ? el['id'].includes('not-sure') : el.includes('not-sure')) >= 0
                newValueIsNotSure = newValue['id'] ? newValue['id'].includes('not-sure') : newValue.includes('not-sure')

                if (!arrIncludesNotSure && newValueIsNotSure)
                    newValue = [newValue]
                else {
                    newValue = filterDuplicatesOut([...currentValue, newValue], newValue)
                }
            } else {
                newValue = option
            }

            setState(prev => ({
                ...prev,
                steps: {
                    ...prev.steps,
                    [stepId]: {
                        ...prev.steps[stepId],
                        value: newValue

                    }
                }
            }))


        }
    }
    console.log("state", steps[active])
    const isLastStep = () => getActiveIndex() + 1 === formLength - 1
    const isSubmitStep = () => getActiveIndex() + 1 === formLength
    return (
        <PageContentContainer
            marginTop="0"
            position="absolute"
            top="0"
            color="#fff"
            height="100vh"
            // height={window.innerHeight + 'px'}
            background="var(--formBackground)"
            justifyContent="start" alignItems="start" flexDirection="row" 
            padding="0 0 50px"
        >
            <ProgressBar active={active} formLength={formLength - 1} getActiveIndex={getActiveIndex} isLastStep={isLastStep()} />
            <FormGrid stepType={steps[active].type}>
                {!isSubmitStep() &&
                    <GridItem gridArea="back" alignSelf="center" className="formNavigation">
                        <NavArrow isLastStep={isLastStep()} direction="back" disabled={active === stepOrder[0]} onClick={backStep} className={isLastStep() ? 'hideOnDesktop' : undefined} />
                    </GridItem>
                }
                {
                    (steps[active] && !steps[active]['labelInternal']) &&
                    < Flexbox
                        // gridCol="2" gridRow="1" 
                        gridArea="label"
                        alignSelf="end"
                        {...steps[active]['labelStyling']}>
                        <h3 style={{ margin: 'inherit' }}>
                            {
                                (steps[active] && !steps[active]['labelInternal']) && steps[active].label
                            }
                        </h3>
                    </Flexbox>
                }
                {
                    (steps[active] && steps[active]['subtext'] && !steps[active]['subtextInternal']) &&
                    < Flexbox
                        gridCol="2" gridRow="1"
                        gridArea="subtext"
                        alignSelf="end"
                        {...steps[active]['subtextStyling']}
                    >
                        {
                            typeof (steps[active].subtext) === 'string' &&
                            <p style={{ margin: 'inherit', fontSize: 'inherit', fontWeight: 'inherit' }}>
                                {
                                    steps[active].subtext
                                }
                            </p>
                        }
                        {
                            typeof (steps[active].subtext) !== 'string' &&

                            <>
                                steps[active].subtext
                            </>
                        }

                    </Flexbox>
                }
                <FormContentContainer stepType={steps[active].type}>
                    {
                        steps[active] && <Step {...state} serverResponse={serverResponse} setServerResponse={setServerResponse} backStep={backStep} handleChange={handleChange} step={steps[active]} nextStep={nextStep} onKeyDown={onKeyDown} onKeyUp={onKeyUp}
                            iconSelectionOnChange={iconSelectionOnChange}
                            selectedStates={getSelectedStates()} />
                    }

                </FormContentContainer>
                {!isSubmitStep() &&
                    <GridItem gridArea="next" alignSelf="center" justifySelf="end" className={isLastStep() ? 'hideOnDesktop' : 'formNavigation'} width={isLastStep() ? '79px' : undefined}>
                        {
                            !isLastStep() &&
                            <NavArrow disabled={disabled} onClick={nextStep} direction={isLastStep() ? 'submit' : 'next'} />
                        }
                    </GridItem>
                }
            </FormGrid>
        </PageContentContainer >
    )
}

export default Form