import styled from "styled-components";

export const Input = styled.input`
    flex-grow: 1;
    padding: .5em 0;
    border: none;
    outline: none;
`;

export const TagsInputContainer = styled.div`
    padding: ${({ padding }) => padding || '.5em'};
    border-radius: 3px;
    width: 100%;
    max-width: ${({ maxWidth }) => maxWidth || '400px'};
    height: ${({ height }) => height };
    min-height: ${({ height }) => height };
    margin: ${({ margin }) => margin || '1px'};
    overflow: auto hidden;
    /* margin-top: 1em; */
    display: flex;
    outline: ${({ outline }) => outline};
    box-shadow: ${({ boxShadow }) => boxShadow};
    align-items: center;
    /* flex-wrap: wrap; */
    gap: .5em;
    .tag:nth-of-type(1){
        margin-left: 5px;
    }
`;


export const InnerContainer = styled.div`
    border-radius: 3px;
    width: 100%;
    overflow: auto hidden;
    display: flex;
    align-items: center;
    gap: .5em;
    min-height: ${({ minHeight }) => minHeight || '49px'};
    height: ${({ height }) => height };
    position: relative;
`;

export const TagItem = styled.div`
    background-color: rgb(218, 216, 216);
    display: inline-block;
    padding: .5em .75em;
    border-radius: 20px;
    .close{
        height: 20px;
        width: 20px;
        background-color: rgb(48, 48, 48);
        color: #fff;
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-left: .5em;
        font-size: 18px;
        cursor: pointer;
    }
`;
