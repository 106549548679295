export default `varying vec3 vertexNormal;

void main() {

    vertexNormal = normalize(normalMatrix * normal);

    // therefore, the code above becomes the code below (again, see link)
    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );


}`