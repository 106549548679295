import React from 'react'

import { Wrapper } from './GridContainer.styles'

const GridContainer = ({ refId, children, ...props }) => {
    return (
        <Wrapper ref={refId} {...props}>{children}</Wrapper>
    )
}

export default GridContainer