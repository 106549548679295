import React, { useEffect, useState, useRef } from 'react'
import Flexbox from '../Flexbox'
import { useNavigate, useLocation } from 'react-router-dom'
import GridC from '../GridContainer'
import GPTechiesLogo from '../GPTechiesLogo'
import { GridContainer } from './Navigation.styles'
import softwareDevLeadsIcon from '../../images/softwareDevLeadsLogo.svg'
import softwareDevLeads from '../../images/softwareDevLeads.svg'
import gptLogo from '../../images/gptLogo.svg'
import Dropdown from '../Dropdown'
import { fixSvgElementCoordinates } from '../../utils'

const Navigation = () => {
    const gridContainerRef = useRef(null)
    const navigate = useNavigate()
    const location = useLocation()
    const { pathname } = location
    const [showDropdown, setShowDropdown] = useState(false)
    const fillColor = (defaultColor = '#000') => location.pathname !== '/about' && !location.pathname.includes('privacy') && !location.pathname.includes('terms')
        && !location.pathname.includes('do-not-sell-my-info') && !showDropdown ? "#fff" : defaultColor

    useEffect(() => {
        if (gridContainerRef.current) {
            fixSvgElementCoordinates('.hamburgerMenu svg .upperLine', 0, 2, 5, 4)
            fixSvgElementCoordinates('.hamburgerMenu svg .lowerLine', 0, 67, 5, 67)
        }

    }, [gridContainerRef.current])
    const links = [
        // 'FAQ', 
        'Contact', 'About', 'Get Started'
        //  'Pricing', 
    ]
    if (location.pathname.includes('get-started') || location.pathname.includes('form'))
        return (
            <GridContainer height="var(--navBarHeight)" padding="var(--navBarPadding)" zIndex="3" relative boxShadow="none" background="transparent">
                <Flexbox width="min-content" className="closeOrRemoveTag" padding="16px" color="#fff" onClick={() => navigate('/')} fontWeight={location.pathname === '/' ? 'var(--navBarFontWeightActive)' : undefined}>
                    &#10006;&#xFE0E;</Flexbox>

            </GridContainer>
        )
    return (
        <GridContainer ref={gridContainerRef} height="var(--navBarHeight)" gridTemp={{ cols: 'min-content 1fr min-content min-content' }} padding="var(--navBarPadding)"
            // boxShadow={location.pathname === '/contact' ? "none" : "var(--navBarBoxShadow)"  } 
            boxShadow='none'
            zIndex="5" relative>
            <Flexbox

                // fontFamily="'Crimson Text', serif"
                // fontFamily="'Amiri', serif"
                // fontFamily="'Lusitana', serif"
                // fontFamily="'Playfair Display', serif"
                // fontWeight={location.pathname === '/' ? '700' : undefined}
                fontWeight={700}
                // fontWeight={location.pathname === '/' ? 'var(--navBarFontWeightActive)' : undefined}
                onClick={() => navigate('/')}
                width="min-content"
                // color="#386aff"
                // color="#fff"
                zIndex="6"
                color={fillColor("#386aff")}
                gap="5px"
                cursor="pointer"
                whiteSpace={"nowrap"}
            >
                {/* <img src={gptLogo} alt="GPTechies" height="35px" width="130px" objectFit="contain" style={{
                    // borderRadius: '50%',
                    // boxShadow: '0 0 2px var(--formBlue1)'
                }} /> */}
                <GPTechiesLogo fillColor={"#fff"} />

                {/* <img src={softwareDevLeads} height="14px" /> */}
            </Flexbox>
            <GridC

                color={fillColor("#386aff")}
                className="desktopMenu" justifyContent="center" gap="20px" gridTemp={{ columns: 'repeat(2, min-content)' }} justifySelf="center">
                {/* <Flexbox
                    fontWeight={location.pathname === '/about' ? '900' : undefined}
                    onClick={() => navigate('/about')}
                    justifyContent="center" >
                    About
                </Flexbox> */}
                {/* <Flexbox
                    fontWeight={location.pathname === '/faq' ? '900' : undefined}
                    onClick={() => navigate('/faq')}
                    justifyContent="center">
                    FAQ
                </Flexbox> */}
            </GridC>

            <Flexbox
                className="desktopMenu"
                gridCol="3"
                justifySelf="end"
                cursor="pointer"
                padding="10px"
                borderRadius="3px"
                fontWeight="700"
                color="var(--formBlue1)"
                background="#ffffff"
                whiteSpace={"nowrap"}
                fontSize="12px"
                height="min-content"
                alignSelf="center"
                boxShadow="0 0 5px rgba(0,0,0,0.35)"
                // boxShadowHover="0 0 7px var(--formBlue2)"
                // background="var(--formBackgroundAngled)"
                onClick={() => {
                    console.log("navigating")
                    navigate('/contact')
                }}>Contact Us
            </Flexbox>
            <Flexbox gridCol="4" gap="20px"
                justifySelf="end" alignSelf="center" className="desktopMenu">

                <Flexbox
                    whiteSpace="nowrap"
                    gridCol="3"
                    justifySelf="end"
                    cursor="pointer"
                    padding="10px"
                    borderRadius="3px"
                    fontWeight="700"
                    color="#fff"
                    fontSize="12px"
                    height="min-content"
                    alignSelf="center"
                    boxShadow="0 0 5px rgba(0,0,0,0.35)"
                    background="var(--formBackgroundAngled)"
                    onClick={() => {
                        navigate('/form')
                    }}>
                    Get Started</Flexbox>
            </Flexbox>
            <Flexbox column alignItems="end" className="hamburgerMenu"
                onClick={() => {
                    let bodyEl = document.querySelector("body");
                    if (!showDropdown) {
                        bodyEl.style.overflow = "hidden";
                    } else {
                        bodyEl.style.overflow = "visible";
                    }
                    setShowDropdown(!showDropdown);
                }}
                justifyContent="center" alignContent="end" justifyItems="end">

                <svg viewBox="0 0 100 80" width="20px" height="20px" style={{
                    zIndex: '6'
                }}>
                    <rect y="30" width="90" x="5" height="10" style={{ fill: showDropdown ? "none" : fillColor() }}></rect>
                    <rect className="upperLine" width="90" height="10" x="5" style={{ transform: showDropdown ? "rotate(45deg)" : 'none', fill: showDropdown ? 'var(--formBlue1)' : fillColor() }}></rect>
                    <rect className="lowerLine" y="60" width="90" x="5" height="10" style={{ transform: showDropdown ? "rotate(-45deg)" : 'none', fill: showDropdown ? 'var(--formBlue1)' : fillColor() }}></rect>
                </svg>
                {showDropdown &&
                    <Flexbox position="fixed" height="100%" width="100%" right="0" top="0" zIndex="5">
                        <Dropdown
                            // dropdownRef={dropdownRef}
                            // listContainerRef={listContainerRef}
                            label='hamburger'
                            headerHeight="49px"
                            fontSize="1.3rem"
                            width="100%"
                            itemHeight="min-content"
                            itemPadding="20px 50px"
                            // height="calc(100vh - var(--navBarHeight))"
                            height="100%"
                            justifyContentList="end"
                            maxHeight={'100%'}
                            // dropdownListMarginTop={'calc(var(--navBarHeight)/2)'}
                            showHeader={false}
                            name={'Suggested Tags'}
                            marginBottom={'1rem'}
                            options={[
                                { value: 'Home', innerText: 'Home', onClick: () => navigate('/') },
                                ...links.map(it => {
                                    const path = it === 'Get Started' ? 'get-started' : it.replace(' ', '-').toLowerCase()
                                    return ({ value: it, innerText: it, onClick: () => { navigate(path) } })
                                })]}
                            menuState={showDropdown}
                            handleStateChange={() => setShowDropdown(!showDropdown)}
                            // currSelection={currSelection ? currSelection : null}
                            valueX={[]}
                            id={'suggested-tags'}
                            padding="0 0 100px"
                            setValidContinue={() => { }}
                            justifyContent="end"
                            textAlign="left"
                        // letterSpacing="0.1rem"
                        // fontSize="var(--fontSmall)"
                        // fontWeight="700"
                        // boxShadow='0 0 2px #000'
                        // background="#000"
                        ></Dropdown>
                    </Flexbox>}

            </Flexbox>
        </GridContainer>
    )
}

export default Navigation