import React, { useState } from 'react'
import Flexbox from '../../Flexbox'
import InputField from '../../InputField'
import TextArea from '../../TextArea'
import Button from '../../Button'
import { NavLink } from 'react-router-dom'

import styled from 'styled-components'
import { Wrapper } from '../../Flexbox/Flexbox.styles'

const Link = styled(NavLink)`
all: unset;
color: #fff;
font-weight: 700;
cursor: pointer;
width: min-content;
white-space: nowrap;
grid-area: ${({ gridArea }) => gridArea};
grid-column: ${({ col }) => col};
grid-row: ${({ row }) => row};
:hover{
    text-decoration: underline;
}
`;

const FlexWrapper = styled(Wrapper)`
    position: absolute;
    z-index: -1;
    visibility: hidden;
    width: 0;
    height: 0;
    display: none;
    @media only screen and (max-width: 750px), screen and (max-device-width: 750px) {
            position: relative;
            z-index: unset;
            visibility: visible;
            width: 100%;
            height: 100%;
            display: flex;
        }
`;

const FinalStep = ({ setServerResponse, serverResponse, label, fields, handleChange, placeholder, step, onKeyUp, onKeyDown, nextStep, backStep, containerProps }) => {
    const { fieldOrder } = step
    const [showMessage, setShowMessage] = useState(false)
    return (
        <Flexbox
            column width="100%" className="noMarginForText left"
            // border="1px solid #fff" 
            // padding="0 5%"
            maxWidth="690px"
            {...containerProps} overflow="auto" minHeight="min-content">
            <h3 >How can we get in touch?</h3>
            {
                fieldOrder.map((fieldKey, index) => {
                    const field = fields[fieldKey]
                    const { name, type } = field
                    console.log("field", field)
                    if (type === undefined || field['type'] !== 'textArea')
                        return (
                            <>
                                <h4 style={{ margin: '1em 0 0.5em', alignSelf: 'start' }} className="left">
                                    {field.label}
                                </h4>
                                <Flexbox gap="10px" width="100%">

                                    <Flexbox flexGrow="2" flexBasis="100%" width="100%">
                                        <InputField
                                            type={type ? type : 'text'}
                                            onChange={
                                                // name === 'phoneNumber' ? () => { } : 
                                                function (e) {
                                                setShowMessage(false)
                                                field.onChange(e)
                                            }}
                                            name={name}
                                            placeholder={name === 'phoneNumber' ? field.placeholder[name] : field.placeholder}
                                            marginBottom={'20px'}
                                            value={name === 'phoneNumber' ? field.value[name] : field.value}
                                            width="100%"
                                            fontSize="16px"
                                            // onKeyDown={name === 'phoneNumber' ? onKeyDown(`${name}`) : undefined}
                                            // onKeyUp={name === 'phoneNumber' ? onKeyUp : undefined}
                                            uppercaseOff={true}
                                            border="none; border-bottom: 1px solid #fff"
                                            // border="1px solid #000" 
                                            labelTextAlign="left"
                                            labelFontSize="14px"
                                            focusOutline="none" padding="0 40px 0 20px"
                                            outline="none"
                                            focusBorder="none; border-bottom: 2px solid #fff"
                                            flexDirection="column"
                                            background="rgba(255,255,255,0.1)"
                                        />
                                    </Flexbox>
                                </Flexbox>
                            </>
                        )
                    else
                        return (
                            <>
                                <h4 style={{ margin: '1em 0 0.5em', alignSelf: 'start' }}>
                                    {field.label}
                                </h4>
                                <TextArea
                                    {...step}
                                    name={name}
                                    type={type}
                                    value={field.value}
                                    onChange={function (event) {
                                        setShowMessage(false)
                                        field.onChange(event)
                                    }}
                                    background="rgba(255,255,255,0.1)"
                                    border="none; border-bottom: 1px solid #fff"
                                    padding="10px 35px 10px 20px"
                                    focusBorder="none; border-bottom: 2px solid #fff"
                                    focusOutline="none"
                                />
                            </>
                        )
                })
            }
            <Flexbox width="100%" gap="10px" margin="10px 0">
                <FlexWrapper>
                    <Button

                        background="rgba(224, 245, 255,0.25)"
                        // background="var(--formBackground)"
                        color="#fff"
                        colorHover="#376afe"
                        paddingRight="0"
                        paddingLeft="0"
                        border="2px solid #fff"
                        text="Back"
                        letterSpacing="0.05em"
                        bgdHover="#fff"
                        onClick={backStep} width="100%" />
                </FlexWrapper>
                <Button
                    background="#fff"
                    // background="var(--formBackground)"
                    color="var(--defaultButtonBackground)"
                    colorHover="#fff"
                    paddingRight="0"
                    paddingLeft="0"
                    border="2px solid #fff"
                    borderHover="2px solid #fff"
                    text="Submit"
                    letterSpacing="0.05em"
                    bgdHover="var(--defaultButtonBackground)"
                    // colorHover="#fff"
                    onClick={function (e) {
                        if (typeof (setServerResponse) === 'function')
                            setServerResponse(prev => ({ ...prev, shouldShow: true }))
                        nextStep(e)
                        setShowMessage(true)
                    }} width="100%" />
            </Flexbox>
            {serverResponse['shouldShow'] && <p>{serverResponse['message']}</p>}

            <p style={{
                fontSize: '12px',
                margin: '0',
                textAlign: 'left'
            }}>
                By using our service and providing your personal contact information, you agree that we may contact you at the number and/or email provided. Submission of this form confirms you have read and agree to the <Link to="/privacy-policy" target="_blank">Privacy Policy</Link> and <Link to="/terms" target="_blank">Terms and Conditions</Link>.
            </p>
        </Flexbox>
    )
}

export default FinalStep