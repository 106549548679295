import React from 'react'
import styled from 'styled-components'
import harvard from '../images/harvard.png'
import ucla from '../images/ucla.png'
import johnsonAndJohnson from '../images/johnsonAndJohnson.png'
import pAndG from '../images/pandg2.png'
import stanford from '../images/stanford.png'
import novartis from '../images/novartis.png'

const Div = styled.div`
max-width: ${(205 * 0.7 * 6) + 20 * 6 * 2}px;
display: flex;
flex-wrap: wrap;
max-width: 95%;
justify-content: center;
`;

const Img = styled.img`
height: ${(130 * 0.7)}px;
width: ${(205 * 0.7)}px;
position: relative;
margin: 0 20px;
`;

const H5 = styled.h5`
color: #ffffff;
margin: 0 0 10px;
font-weight: 400;
/* font-size: 14px; */
`;
const Logos = () => {
    return (
        <>
            <H5>Our team has worked on AI-driven projects with</H5>
            <Div>
                <Img src={harvard} />
                <Img src={ucla} />
                <Img src={johnsonAndJohnson} />
                <Img src={pAndG} />
                <Img src={stanford} />
                <Img src={novartis} />
            </Div>
        </>
    )
}

export default Logos