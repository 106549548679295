import React from "react";
import PageContentContainer from "../PageContentContainer";
import Flexbox from "../Flexbox";
import styled, { keyframes } from "styled-components";
import { useIntersectionObserver } from "../../hooks/useIntersectionObserver";



const floating = keyframes`
  0% { transform: translate(0,  0px); }
  50% { transform: translate(0, 15px); }
  100% { transform: translate(0, -0px); }
`


const GridContainer = styled.div`
  display: grid;


`;

const H1 = styled.h1`
  z-index: 1;
  position: relative;
  height: min-content;
  width: 100%;
  text-align: left;
  color: #fff;
  font-size: 5rem;
  text-transform: uppercase;
  font-weight: 100;
  margin: 10px 0;
  grid-row: 1 / span 2;
  ::after {
    content: "";
    font-size: inherit;
    position: absolute;
    left: 0;
    bottom: 0;
    /* width: 0; */
    width: 102%;
    height: 1px;
    /* border-radius: 0.1em; */
    transition: all 0.4s ease;
    /* opacity: 0; */
    opacity: 1;
    background-color: transparent;
    /* background: linear-gradient(
      90deg,
      var(--formBlue1),
      var(--formBlue2) 50%,
      transparent 50%
    ); */
    background: linear-gradient(
        90deg,
        #ffffff,
        #ffffff 50%,
        transparent 50%,
        transparent
    );
    background-position: 100% 0%;
    background-size: 200% 100%;
    z-index: -1;
    @media only screen and (max-width: 700px), screen and (max-device-width: 700px) {
  grid-column: 1;
}
  }
  @media only screen and (max-width: 700px), screen and (max-device-width: 700px) {
  /* grid-column: 1; */
  font-size: 2rem;
}
  &.visible::after {
    width: 102%;
    opacity: 1;
    background-position: 0% 0%;
    /* background-color: var(--formBlue1); */
  }
`;

const H2 = styled.h2`
  margin: 0;
  color: #fff;
  text-align: left;
  font-size: 2rem;
`;

const H3 = styled.h3`
  color: #fff;
  text-align: left;
  margin: 0 5px 0 0;
  font-weight: 300;
  grid-row: 4 / -1;
@media only screen and (max-width: 700px), screen and (max-device-width: 700px) {
  grid-row: unset;
}

`;

const PageGrid = styled.div`
  display: grid;
  margin: calc(var(--navBarHeight) * -1) 0 0;
  position: relative;
  grid-template-columns: 50% 50px 1fr 50px 1fr 50px;
  grid-template-rows: 50px 1fr 50px 1fr 50px 1fr 50px;
  background: linear-gradient(90deg, var(--formBlue1), var(--formBlue2));
  width: 100%;
  height: 100%;
  min-height: 100vh;
  justify-content: start;
  align-items: start;
  padding: calc(var(--navBarHeight) * 2) 50px;

@media only screen and (max-width: 700px), screen and (max-device-width: 700px) {
  grid-template-columns: 1fr;
  grid-template-rows: unset;
  grid-gap: 20px;
  padding: calc(var(--navBarHeight) * 2) 5%;
}
`;

const GlassDivText = styled.div`
  display: flex-column;
  justify-content: center;
  align-items: center;
  /* padding: 30px; */
  /* margin: 30px; */
  h2,
  h3,
  h4 {
    color: #fff;
  }
  h4 {
    font-weight: 400;

    margin: 0.5em 0 0;
  }
  h2 {
    font-weight: 100;
    font-size: 3rem;
    line-height: 0.8em;
    margin: 0;
  }
`;

const GlassDiv = styled.div`
  display: flex;
  color: #fff;
  flex-direction: column;
  width: 100%;
  position: absolute;
  height: 100%;
  z-index: 0;

  /* background: rgba(255,255,255,0.15); */
  bottom: 0;
  left: 0;
  ::before {
    content: "";
    /* z-index: 0; */

    z-index: 1;
    position: absolute;

    background: rgba(255, 255, 255, 0.15);
    height: 100%;
    width: 100%;
    top: 5px;
    left: 5px;
  }
  ::after {
    content: "";
    /* z-index: 0; */

    background: ${({ bgd }) => bgd};
    z-index: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    top: -5px;
    left: -5px;
  }
`;

const PricingElementContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: ${({ zIndex }) => zIndex};
  /* margin: 20px; */
  position: relative;
  height: 100%;
  /* max-width: 250px; */
  width: 100%;
  padding: 30px 40px;
  grid-row: ${({ row }) => row};
  grid-column: ${({ col }) => col};

  &:hover{
    /* animation-name: ${floating};
  animation-duration: 2s;
  animation-iteration-count: infinite; */
  /* animation-timing-function: ease-in-out; */
  /* animation-delay: ${({index}) => `calc(${index} * 300ms)`}; */
  }
@media only screen and (max-width: 700px), screen and (max-device-width: 700px) {
  grid-row: ${({ rowM }) => rowM};
  grid-column: ${({ colM }) => colM};
}
`;

const PricingElement = ({ index, zIndex, price, type, bgd, col, row, rowM, colM }) => {
  return (
    <PricingElementContainer col={col} row={row} rowM={rowM} colM={colM} zIndex={zIndex} index={index}>
      <GlassDiv bgd={bgd} />
      <GlassDivText style={{ zIndex: 1 }}>
        <h2>{price}</h2>
        <h4>{type}</h4>
      </GlassDivText>
    </PricingElementContainer>
  );
};

const Pricing = () => {
  const [containerRef, isVisible] = useIntersectionObserver(
    {
      root: null,
      rootMargin: "0px 0px -40% 0px",
      threshold: 0.5, // half of item height
    },
    true
  );
  return (
    <PageGrid
      row
      display="flex"
      margin="calc(var(--navBarHeight) * -1) 0 0"
      position="relative"
      background="linear-gradient(90deg,var(--formBlue1), var(--formBlue2))"
      width="100%"
      height="100%"
      minHeight="100vh"
      justifyContent="start"
      alignItems="start"
      flexDirection="row"
      padding="calc(var(--navBarHeight) * 2) 0 0"
    // background="var(--formBackground90)"
    // top="0"
    // color="#fff"
    // background="var(--formBackground)"
    >
      <Flexbox
        height="min-content"
        justifyContent="start"
        // border="none; border-bottom: 1px solid #ffffff"
        gridCol="1 / span 3"
        gridColMobile="1"
        gridRowMobile="1"
        maxWidth="95%"
        maxHeight="70%"
        mobileMaxHeight="unset"
        gridRow="1 / span 2"
      >
        <H1 ref={containerRef} className={isVisible ? "visible" : undefined}>Pricing</H1>
      </Flexbox>
      <H2>Exclusive Leads. Great Value.</H2>
      <H3>
        We take on the risk of paying for clicks, while you only pay for real
        leads.<br /><br />Advertising costs for specialty leads can vary depending on market conditions, so we'll confirm exact pricing before you place your first order.
        <br /><br />After reviewing your portfolio, we'll send you a checkout link and you can set the number of leads you'd like.
      </H3>
      <PricingElement
        row="1 / span 3"
        index={1}
        col="4 / span 3"
        colM="1"
        rowM="unset"
        bgd="rgba(13,41,255,0.65)"
        price="$375"
        type="Web & Mobile Project Leads"
      />
      <PricingElement
        zIndex="2"
        index={2}
        row="3 / span 3"
        col="2 / span 3"
        colM="1"
        rowM="unset"
        bgd="rgba(0,108,255,.65) "
        price="$330"
        type="Buy 10 or more"
      />
      <PricingElement
        zIndex="3"
        index={3}
        row="5 / span 3"
        col="4 / span 3"
        colM="1"
        rowM="unset"
        bgd="rgba(53,169,255,0.65)"
        price="$300"
        type="Buy 20 or more"
      />
      {/* <Flexbox column position="relative">
        
      </Flexbox> */}
    </PageGrid>
  );
};

export default Pricing;
