import React, { Suspense, useEffect, useState, useRef, useMemo } from 'react'
import PageContentContainer from './PageContentContainer'
import Flexbox from './Flexbox'
import vertexShader from '../shaders/vertex2.glsl'
import fragmentShader from '../shaders/fragment.glsl'
import { useIntersectionObserver } from '../hooks/useIntersectionObserver'
import { Canvas, useFrame, useThree, extend } from "@react-three/fiber";
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import atmosphereVertexShader from '../shaders/atmosphereVertex.glsl'
import atmosphereFragmentShader from '../shaders/atmosphereFragment.glsl'
import { Mesh } from 'three'
import FadeIn from './FadeIn'
import * as THREE from 'three'
import globe from '../images/globe.jpg'
// import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
// import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
// import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass'
// extend({ EffectComposer, RenderPass, UnrealBloomPass })

import { UnrealBloomPass, RenderPass, Select, Selection, Bloom, EffectComposer, Noise } from "@react-three/postprocessing";
import { PerspectiveCamera, OrthographicCamera, Html } from "@react-three/drei";
import styled from 'styled-components'

const Container = styled.div`
display: flex;
flex-direction: column;
margin: 0 0 0 10px;
/* padding: 0 50px; */
  transition: .3s all ease-out;
align-items: flex-start;
height: 100vh;
width: 100%;
overflow: hidden;
position: absolute;
`;

const BgdComponent = styled.div`
display: flex;
flex-direction: column;
margin: 0 50px;
  transition: .3s all ease-out;
align-items: flex-start;
height: 100vh;
width: 100%;
overflow: hidden;
position: absolute;
//column alignItems="start" margin="0 50px" height="100vh" width="100%" overflow="hidden" position="absolute"
//linear-gradient(black 50%, #00005c, #cc3d00, #ffe747)
background: var(--formBlue2);
background: linear-gradient(
        
        #000,
        #000 60%,
        #00005c 95%,
        #cc3d00,
        #ffe747 100%
    );
    background-size: 100% 200% ;
    &.index-1{
    background-position: 0% 100%;
 }
`;

function Sphere({ geometry, x, y, z, s }) {
    const ref = useRef()
    useFrame((state) => {
        let t = state.clock.getElapsedTime();
        ref.current.rotation.y = t * 2
        // ref.current.position.x = x + Math.sin((state.clock.getElapsedTime() * s) / 2)
        // ref.current.position.y = y + Math.sin((state.clock.getElapsedTime() * s) / 2)
        // ref.current.position.z = z + Math.sin((state.clock.getElapsedTime() * s) / 2)
        let mouseX = state.mouse.x;
        let mouseY = state.mouse.y;


        console.log(state)
    })
    return (
        <mesh ref={ref} position={[0, 0, 0]} scale={[s, s, s]} geometry={geometry}>
            <meshStandardMaterial color={new THREE.Color("#FDB813")} roughness={1} />
        </mesh>
    )
}

function Sphere2({ visibleIndex, tOutIndex }) {
    const material = new THREE.ShaderMaterial({
        // vertexShader: vertexShader
        // we can get rid of this ^ because the property has the same name
        // as the variable 
        vertexShader, //places all the vertices in the right place
        // fragmentShader: //fills in the space in between
        fragmentShader,
        uniforms: {
            globeTexture: {
                value: new THREE.TextureLoader().load(globe)
            }
        } // this is where we are passing through different vars to the shader
        // in this case we are passing the image to the shader as the shader
        // itself cannot load a jpg w/import, etc.
        // https://www.youtube.com/watch?v=vM8M4QloVL0 37:00 min
    })
    const earthRef = useRef()
    const sunRef = useRef()
    const { gl, camera, camera: { position: cameraPosition }, size, mouse, viewport, raycaster } = useThree()
    console.log("camera", camera, size, viewport, mouse, raycaster)
    if (earthRef.current)
        earthRef.current.rotation.z = 13
    useFrame((state, delta) => {
        let t = state.clock.getElapsedTime();
        // console.log("camera", camera, size, viewport, mouse, raycaster)
        if (earthRef.current) {
            earthRef.current.rotation.y = t * .5
            if (tOutIndex === 0) {
            // if (visibleIndex === 1) {
                // earthRef.current.position.z = earthRef.current.position.z + Math.sin((state.clock.getElapsedTime() * 2) / 2)
                var currentPos = earthRef.current.position.clone()
                var coordinateGoal = { cameraPosition }
                var vectorToTarget = (new THREE.Vector3(0, 100, 0)).sub(currentPos);
                // var vectorToTarget = (new THREE.Vector3(0, -20, cameraPosition.z)).sub(currentPos);

                var distanceToTarget = vectorToTarget.length();
                if (distanceToTarget > 1) {
                    var direction = vectorToTarget.normalize();
                    var speed = 500;
                    var moveDistance = speed * delta;
                    earthRef.current.position.add(direction.multiplyScalar(moveDistance));
                } else {
                    currentPos = sunRef.current.position.clone()
                    vectorToTarget = (new THREE.Vector3(0, 0, 0)).sub(currentPos);
                    distanceToTarget = vectorToTarget.length();
                    if (distanceToTarget > 1) {
                        var direction = vectorToTarget.normalize();
                        var speed = 50;
                        var moveDistance = speed * delta;
                        sunRef.current.position.add(direction.multiplyScalar(moveDistance));
                    }
                }
            }
        }
        let mouseX = state.mouse.x;
        let mouseY = state.mouse.y;


        console.log(mouseX)
        // ref.current.position.x = x + Math.sin((state.clock.getElapsedTime() * s) / 2)
        // ref.current.position.y = y + Math.sin((state.clock.getElapsedTime() * s) / 2)
        // ref.current.position.z = z + Math.sin((state.clock.getElapsedTime() * s) / 2)
    })
    const material2 = new THREE.ShaderMaterial({
        vertexShader: atmosphereVertexShader,
        fragmentShader: atmosphereFragmentShader,
        // blending is needed otherwise the globe isn't visible
        blending: THREE.AdditiveBlending,
        side: THREE.BackSide,
        uniforms: {
            atmoColor: { value: { x: 0.3, y: 0.6, z: 1.0, w: 1.0 } },
        }
    })

    const materialSun = new THREE.ShaderMaterial({
        vertexShader: atmosphereVertexShader,
        fragmentShader: atmosphereFragmentShader,
        // blending is needed otherwise the globe isn't visible
        blending: THREE.AdditiveBlending,
        side: THREE.BackSide,
        uniforms: {
            atmoColor: { value: { x: 235 / 255, y: 74 / 255, z: 0, w: 1.0 } },
        } // 
    })


    return (
        <>
            <group ref={earthRef}>
                <mesh position={[0, 0, 0]} scale={1} >
                    <sphereGeometry args={[10, 50, 50]} />
                    <shaderMaterial
                        attach="material"
                        {...material}
                    // color="#ecff1a"
                    // emissive="#ecff1a"
                    // emissiveIntensity={50}
                    />
                </mesh>
                <mesh position={[0, 0, 0]} scale={1.1}>
                    <sphereGeometry args={[10, 50, 50]} />
                    <shaderMaterial
                        attach="material"
                        {...material2}
                    // color="#ecff1a"
                    // emissive="#ecff1a"
                    // emissiveIntensity={50}
                    />
                </mesh>
            </group>
            <mesh position={[0, -20, cameraPosition.z]} scale={1} ref={sunRef}>
                <sphereGeometry args={[10, 50, 50]} />
                <shaderMaterial
                    attach="material"
                    {...materialSun}
                // color="#ecff1a"
                // emissive="#ecff1a"
                // emissiveIntensity={50}
                />
            </mesh>
        </>
    )
}

function RandomSpheres() {
    const [geometry] = useState(() => new THREE.SphereGeometry(1, 32, 32), [])
    const data = useMemo(() => {
        return new Array(1).fill().map((_, i) => ({
            x: Math.random() * 0,
            y: Math.random() * 0,
            z: Math.random() * 0,
            s: Math.random() + 10,
        }))
    }, [])
    return data.map((props, i) => <Sphere key={i} {...props} geometry={geometry} />)
}

// function Bloom({ children }) {
//     const { gl, camera, size } = useThree()
//     const [scene, setScene] = useState()
//     const composer = useRef()
//     useEffect(() => void scene && composer.current.setSize(size.width, size.height), [size])
//     useFrame(() => scene && composer.current.render(), 1)
//     return (
//         <>
//             <scene ref={setScene}>{children}</scene>
//             <effectComposer ref={composer} args={[gl]}>
//                 <renderPass attachArray="passes" scene={scene} camera={camera} />
//                 <unrealBloomPass attachArray="passes" args={[new THREE.Vector2(window.innerWidth, window.innerHeight),
//                     1.5,
//                     0.4,
//                     0.85]} />
//             </effectComposer>
//         </>
//     )
// }

const Box = ({ children, bgdRef, bgColor, setVisibleIndex, index, currVisibleIndex, setTOutIndex }) => {
    // console.log("vertexShader", vertexShader)
    const wasInView = useRef(false)
    const [refItem, inView] = useIntersectionObserver({
        root: null,
        rootMargin: "-50% 0px -50% 0px",
        threshold: 0
    }, false);
    useEffect(() => {
        if (inView && bgdRef.current) {
            setVisibleIndex(index)
            wasInView.current = true
            // bgdRef.current.setRGB(...bgColor)
            bgdRef.current.style.background = bgColor
        } else {
            if (currVisibleIndex === index && wasInView.current) {
                wasInView.current = false
                setTOutIndex(index)
            }
        }
    }, [inView]);
    return (<Flexbox refID={refItem} column justifyContent="center" scrollSnapAlign="center" scrollSnapType="y mandatory" height="100vh" flexShrink="0">{children}</Flexbox>)
}

const CameraRig = ({ }) => {
    // var vec = new THREE.Vector3(); // create once and reuse
    // var pos = new THREE.Vector3(); // create once and reuse

    // vec.set(
    //     (event.clientX / window.innerWidth) * 2 - 1,
    //     - (event.clientY / window.innerHeight) * 2 + 1,
    //     0.5);

    // vec.unproject(camera);

    // vec.sub(camera.position).normalize();

    // var distance = - camera.position.z / vec.z;

    // pos.copy(camera.position).add(vec.multiplyScalar(distance));
    const { gl, camera, size, mouse, viewport, raycaster } = useThree()
    const ref = useRef()
    // useEffect(() => {
    //     if (ref.current)
    //         console.log("ref current", ref.current)
    //     const mouseMove = (e) => {
    //         // console.log(e)
    //         // console.log("raycaster", raycaster)
    //         if (ref.current) {
    //             const { ray: { direction, origin } } = raycaster
    //             let fromPoints = [
    //                 new THREE.Vector3(origin),
    //                 new THREE.Vector3(...Object.keys(direction).map( it => origin[it] * 20)),
    //             ]
    //             console.log("raycaster",raycaster, viewport, size)
    //             ref.current.geometry.setFromPoints(fromPoints)
    //         }
    //     }
    //     window.addEventListener('mousemove', mouseMove)
    //     return () => {
    //         window.removeEventListener('mousemove', mouseMove)
    //     }
    // }, [])
    // const buffMaterial = new THREE.BufferGeometry()
    // useFrame(state => {
    //     if (ref.current) {
    //         const { ray: { direction, origin } } = raycaster
    //         ref.current.geometry.setFromPoints([
    //             new THREE.Vector3(...Object.keys(origin).map( it => origin[it])),
    //             new THREE.Vector3(...Object.keys(direction).map( it => origin[it] * 100)),
    //         ])
    //     }
    // })
    if (ref.current)
        console.log("ref.current.geometry", ref.current.geometry)
    return (<>
        <line ref={ref}>
            <bufferGeometry attach="geometry" />
            <lineBasicMaterial color="hotpink" />
        </line>
    </>)
}

const Helix = () => {
    const bgdRef = useRef(null)
    const [visibleIndex, setVisibleIndex] = useState(0)
    const [tOutIndex, setTOutIndex] = useState(-1)
    console.log("tOutIndex",tOutIndex)
    const data = [
        { h1: "ChatGPT is here,", bg: "linear-gradient(black, black)" },
        { h1: "and so marks the dawn of a new era.", bg: "linear-gradient(black 50%, #00005c, #cc3d00, #ffe747)" },
        { h1: "Consulting and Strategy Building", subtext: "Our AI experts can guide you in finding AI model use cases in your domain and help build or integrate them into your system. We assess your needs, identify problems that can be resolved using OpenAI models and offer ongoing improvement suggestions after launching the solution." },
        { h1: "Custom OpenAI Model-powered Solution Development", subtext: "We build custom AI models and AI solutions using the latest OpenAI models, including GPT-3.5 Turbo, DALL-E, CLIP, and more. Our team of experts works with you every step of the way to ensure a seamless development process." },
        { h1: "Model Integration and Deployment", subtext: "We thoroughly evaluate and understand your requirements to ensure secure and effective integration and deployment. Our OpenAI model integration and deployment service covers the entire process, from model selection and configuration to integration, testing and deployment. " },
        { h1: "Fine-tuning Models", subtext: "We apply techniques such as transfer learning, learning rate scheduling, data augmentation, regularization, and hyperparameter tuning to fine- tune a generative AI model for a specific task, enabling the model to leverage existing knowledge to improve performance on the target task." },
    ]
    const cameraRef = useRef()
    // const pointLightRef = useRef
    return (<PageContentContainer
        minHeight="100vh"
        margin="calc(var(--navBarHeight) * -1) 0 0"
        height="100vh"
        padding="0"
        color="#fff"
        scrollBehavior="smooth"
        scrollSnapType="y mandatory"
        background="#000"
        refID={bgdRef}
        transition="0.2s all  linear"
        column
    // background="linear-gradient(var(--darkBlue),var(--deepBlue))"
    >

        <BgdComponent className={visibleIndex === 1 ? "index-1" : undefined} />
        <Canvas concurrent
            colorManagement
        // camera={{ position: [0, 0, 120], fov: 70 }}
        ><Suspense fallback={null}>
                {/* <color args={[0, 0, 0.01]} attach="background" /> */}
                <OrthographicCamera position={[0, 0, 120]} fov={70} makeDefault ref={cameraRef} lookAt={new THREE.Vector3(0, 0, 0)} />
                <PerspectiveCamera position={[0, 0, 120]} fov={70} makeDefault ref={cameraRef} lookAt={new THREE.Vector3(0, 0, 0)} />
                <spotLight intensity={1} position={[1000, 0, 0]} castShadow />
                <pointLight intensity={100} position={[0, 0, 1]} castShadow />
                <Sphere2 visibleIndex={visibleIndex} tOutIndex={tOutIndex}/>
                <CameraRig />
                <Html fullscreen>
                    <Container>
                        <Flexbox column
                            scrollSnapType="y mandatory" overflow="auto scroll" padding="0 30px 0 0" boxSizing="content-box" width="100%" height="100%"
                        >
                            {
                                data.map((it, index) => {
                                    const { h1, subtext, bg } = it
                                    return (<Box
                                        key={index}
                                        index={index}
                                        bgdRef={bgdRef}
                                        currVisibleIndex={visibleIndex}
                                        setVisibleIndex={setVisibleIndex}
                                        setTOutIndex={setTOutIndex}
                                        bgColor={bg || `hsl(${(270 - 200) / data.length * (data.length - index) + 200} , 100%, 40%)`}
                                    >
                                        {h1 && <h1 style={{ fontSize: '3rem', marginBottom: '10px' }}>{h1}</h1>}
                                        {subtext && <p style={{ fontSize: '1.5rem', fontWeight: '100' }}>{subtext}</p>}
                                    </Box>)
                                })
                            }
                        </Flexbox>
                    </Container>
                </Html>
                {/* <mesh
                    position={[0, 0, 0]}>
                    <sphereGeometry args={[10, 20, 20]} />
                    <meshBasicMaterial color="red" /> */}
                {/* <shaderMaterial attach="material" {...material}/> */}
                {/* </mesh> */}
                {/* <Bloom> */}
                {/* <ambientLight /> */}

                {/* <RandomSpheres /> */}

                {/* </Bloom> */}
            </Suspense>
            {/* <Selection>
                <EffectComposer>
                    <Bloom
                        luminanceThreshold={0}
                        luminanceSmoothing={0.9}
                        height={400}
                        opacity={3}
                    ></Bloom>
                    <Noise opacity={0.01} blendFunction={THREE.AdditiveBlending} />
                </EffectComposer>
                <Select enabled>
                    <mesh />
                </Select>
            </Selection> */}
        </Canvas>


        {/* <Container>
            <Flexbox column
                scrollSnapType="y mandatory" overflow="auto scroll" padding="0 30px 0 0" boxSizing="content-box" width="100%" height="100%"
            >
                {
                    data.map((it, index) => {
                        const { h1, subtext, bg } = it
                        return (<Box
                            key={index}
                            index={index}
                            bgdRef={bgdRef}
                            setVisibleIndex={setVisibleIndex}
                            bgColor={bg || `hsl(${(270 - 200) / data.length * (data.length - index) + 200} , 100%, 40%)`}
                        >
                            {h1 && <h1 style={{ fontSize: '3rem', marginBottom: '10px' }}>{h1}</h1>}
                            {subtext && <p style={{ fontSize: '1.5rem', fontWeight: '100' }}>{subtext}</p>}
                        </Box>)
                    })
                }
            </Flexbox>
        </Container> */}
    </PageContentContainer>)
}

export default Helix