import React from 'react'

import Flexbox from '../Flexbox'
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver'

import { Wrapper } from './FadeIn.styles'

const FadeIn = ({ runOnce = true, property = 'opacity', onPropertyVisible = '1', onPropertyHidden = '0', children, root = null, rootMargin = "0px", threshold = 0.3, options, ...props }) => {
    const [containerRef, isVisible] = useIntersectionObserver({
        root,
        rootMargin,
        threshold,
    }, runOnce)

    return (
        <Flexbox refID={containerRef}
            background={property === 'background' ? isVisible ? onPropertyVisible : onPropertyHidden : undefined}
            opacity={property === 'opacity' ? isVisible ? onPropertyVisible : onPropertyHidden : undefined}
            transition="all 0.5s ease-in-out" {...props}>
            {children}
        </Flexbox>
    )
}

export default FadeIn