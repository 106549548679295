import React from 'react'
import Airplane from '../Airplane'
import Flexbox from '../Flexbox'

const Success = ({ heading = 'We will contact you shortly!', subheading = 'Form submission successfully received.'}) => {
    return (
        <Flexbox column color="var(--formColor)" height="100vh" alignItems="center" 
        margin="calc(var(--navBarHeight) * -1) 0 0" justifyContent="center" background="var(--formBackground)">
            <Airplane/>
            <h1>{heading}</h1>
            <p>{subheading}<br></br> </p>

        </Flexbox>
    )
}

export default Success