import React, { useState } from 'react'
import InputField from '../InputField'
import TextArea from '../TextArea'
import TiledInput from '../TiledInput'
import GeographicAreaDetailed from '../GeographicAreaDetailed'
import Dropdown from '../Dropdown'
import Checkbox from '../Checkbox'
import RadioButtons from '../RadioButtons'
import Select from '../Select'
import MultiSelect from '../MultiSelect'
import Button from '../ButtonHome'
import GlassButton from '../GlassButton'
import Button2 from '../Button'
import CheckmarkValidate from '../CheckmarkValidate'
import Flexbox from '../Flexbox'
import styled from 'styled-components'

const Container = styled.div`
display: grid;
grid-auto-flow: row;
grid-template-columns: 2fr;
position: relative;
grid-auto-rows: min-content;
grid-template-rows: ${({ rows }) => rows};
gap: 10px;
width: 100%;
margin: auto;
background: rgba(255,255,255,0.05);
padding: 30px;
justify-items: start;
height: 100%;
max-height: 100vh;
overflow: auto;
/* box-shadow: 0 0 5px rgba(0,0,0,0.5); */
/* border-radius: 20px; */
grid-area: content;
height: min-content;
min-height: 500px;
max-width: 600px;
border-top: 1px solid rgba(255,255,255,0.5);
border-left: 1px solid rgba(255,255,255,0.5);
border-right: 1px solid rgba(255,255,255,0.5);
border-bottom: 1px solid rgba(255,255,255,0.5);
    box-shadow: 
    5px 5px 10px -5px rgba(0, 11, 92, 0.35), 
    inset 3px 3px 6px -3px rgba(var(--formBlueRaw),0.95), 
    inset -3px -3px 6px -3px rgba(158,0,194,0.95), 
    3px 3px 6px -3px rgba(158,0,194,0.95),
    -3px -3px 6px -3px rgba(var(--formBlueRaw),0.95);
/* border-right: 1px solid #000; */
/* border-bottom: 1px solid #000; */
border-radius: 10px;
/* max-height: 200px; */
${({ isLastStep }) => {
        if (!isLastStep)
            return
        else {
            return `
                display: flex;
                flex-direction: column;
                justify-content: center;
                box-shadow: none;
                background: none;
                border: none;
                `;
        }
    }}
    
@media only screen and (max-width: 550px), screen and (max-device-width: 550px) {
    height: 100%;
}
`;

const Label = styled.label`
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
    text-align: left;
`;


const StepGrid = styled.div`
display: grid;
gap: ${({ gap }) => gap};
grid-template-columns: ${({ cols }) => cols};
grid-template-rows: ${({ rows }) => rows};
grid-auto-flow: ${({ autoFlow }) => autoFlow};
grid-auto-rows: ${({ autoRows }) => autoRows};
grid-auto-columns: ${({ autoCols }) => autoCols};
width: ${({ width }) => width};
`;

const Field = ({ shouldShowValidators, type, name, id, shouldShow, value, setShowDropdown, field, setFormData, onChange, handleStateChange, geoHookProps, fields, radioButtonOnClick, multiSelect, onCheck, ...props }) => {
    if (type === 'checkbox') {
        return (
            <Flexbox style={{ position: 'relative' }}>
                <Checkbox onCheck={() => { onCheck(id) }} {...field} />
                {(!field['isValid'] && shouldShowValidators) && <CheckmarkValidate margin="auto 0" right="0" position="relative" top="unset"/>}
            </Flexbox>
        )
    }
    if (['input', 'number', 'price'].includes(type)) {
        return (<InputField {...props}
            useUnderlineTheme={true}
            padding={type === 'price' ? "0 40px 0 25px" : "0 40px 0 20px"}
            {...field}
            label={undefined}
            useGlassmorphic={true}
            showValidationCheckmark={!field['isValid'] && shouldShowValidators}
            value={value} onChange={(e) => {
                onChange(id, e)
            }} />)
    }
    if (type === 'textArea') {
        return (<TextArea {...field}
            useUnderlineTheme={true} onChange={(e) => {
                onChange(id, e)
            }} label={undefined} />)
    }
    if (type === 'multiselect') {
        return (<MultiSelect {...field} onSelect={(v) => multiSelect(id, v)} />)
    }
    if (type === 'radio') {
        return (<RadioButtons
            dropdownRef={null}
            listContainerRef={null}
            onClick={(v) => radioButtonOnClick(id, v)}
            headerFontSize="16px"
            headerHeight="49px"
            itemHeight="49px"
            maxHeight={4 * 49 + 'px'}
            // dropdownListMarginTop={"10px"}
            marginTop="0px"
            {...field}
            showHeader={true}
            showArrow={true}
            name={'Suggested Tags'}
            marginBottom={'1rem'}
            border="none"
            headerBgd={'var(--inputBackground)'}
            boxShadow={" 20px 20px 40px -10px rgba(var(--formBlue2Raw),0.95)"}
            id={'suggested-tags'}
            setValidContinue={() => { }}
            justifyContent="start"
            textAlign="left"
        />)
    }
    if (name === 'geographicArea') {
        return (<TiledInput
            key={"geographicArea"}
            labelTextAlign="left"
            labelFontSize="14px"
            // placeHolder={toTitleCase(formData['fields'][fieldId]['placeholder'])}
            {...field}
            {...geoHookProps}
            display="flex"
            padding="0 40px 0 20px"
            mobileMaxWidth="100%"
            maxWidth="unset"
            focusOutline="none"
            boxShadow="var(--defaultInputBoxShadow)"
            background={'var(--inputBackground)'}
            outline="none"
            border="none"
            inputBorder="none"
            onFocusProps={{ outline: 'none' }}
            // isValid={formData['fields'][fieldId]['isValid']}
            useUnderlineTheme={true}
            multiTermEnabled={false}
            flexDirection="column"
            // showValidationCheckmark={(shouldShowValidators && fields[fieldId]['shouldShow'] !== undefined)}
            fontSize="16px"
            width="100%"></TiledInput>)
    }
    if (name === 'geographicAreaDetailed') {
        let geoDetailedEnabled = field['isEnabled'] || false
        return (<GeographicAreaDetailed
            inputOnly={true}
            key={"geographicArea"}
            labelTextAlign="left"
            labelFontSize="14px"
            disabled={!geoDetailedEnabled}
            geoClassName={geoDetailedEnabled ? "" : "disabled"}
            setDisabled={() => { }}
            {...field}
            {...geoHookProps}
            counties={value}
            onBlur={() => (geoHookProps['onBlurGeoDetailed'])(fields)}
            display="flex"
            padding="0 40px 0 20px"
            mobileMaxWidth="100%"
            maxWidth="unset"
            focusOutline="none"
            boxShadow="var(--defaultInputBoxShadow)"
            background={'var(--inputBackground)'}
            outline="none"
            border="none"
            inputBorder="none"
            onFocusProps={{ outline: 'none' }}
            // isValid={formData['fields'][fieldId]['isValid']}
            useUnderlineTheme={true}
            multiTermEnabled={false}
            flexDirection="column"
            inputFieldProps={{
                cursorDisabled: 'not-allowed',
                backgroundDisabled: '#000a33',
                boxShadow: "var(--defaultInputBoxShadow)",
                background: 'var(--inputBackground)',
                outline: "none",
                border: "none",
                inputBorder: "none",
                mobileMaxWidth: '100%',
                onFocusProps: { outline: 'none' },
                // showValidationCheckmark: (shouldShowValidators && !fields[fieldId]['isValid']) || fields[fieldId]['shouldShow']
            }}
            placeholder="xxx"
            fontSize="16px"
            width="100%"></GeographicAreaDetailed>)
    }
    if (type === 'select') {
        return (
            <Select
                options={field['enum']}
                {...field}
            />)
    }
    if (type === 'dropdown') {
        return (
            <Dropdown
                dropdownRef={null}
                listContainerRef={null}
                headerFontSize="16px"
                headerHeight="49px"
                itemHeight="49px"
                maxHeight={4 * 49 + 'px'}
                dropdownListMarginTop={"10px"}
                {...props}
                showHeader={true}
                showArrow={true}
                name={'Suggested Tags'}
                marginBottom={'1rem'}
                border="none"
                headerBgd={'var(--inputBackground)'}
                handleMenuStateInternally={false}
                menuState={field['showDropdown']}
                setMenuState={() => setShowDropdown({ ...field, field: id })}
                handleStateChange={(e) => handleStateChange(e, field)}
                // handleStateChange={(e) => {
                //     let val = e.target.getAttribute('value')
                //     setFormData(prev => ({
                //         ...prev,
                //         fields: {
                //             ...prev['fields'],
                //             [field['id']]: {
                //                 ...prev['fields'][field['id']],
                //                 value: val,
                //                 showDropdown: false,
                //                 isValid: field['isValid'] === undefined ? undefined : val !== "--"
                //             },
                //         }
                //     }))
                // }}
                currSelection={value ? value : null}
                valueX={value}
                boxShadow={" 0 0 4px rgba(var(--formBlue2Raw),0.95)"}
                useGlassmorphic={true}
                id={'suggested-tags'}
                setValidContinue={() => { }}
                justifyContent="start"
                textAlign="left" />
        )
    }
}

const MultiField = ({ activeFields, fields, tertiaryFieldOrder, gridTemp, ...props }) => {
    console.log("gridTemp", gridTemp)
    const gridDimensions = {
        rows: tertiaryFieldOrder ? tertiaryFieldOrder.length : activeFields.length,
        cols: tertiaryFieldOrder ? tertiaryFieldOrder.reduce((a, b) => {
            if (Array.isArray(b))
                if (b.length > a)
                    a = b.length
            return a
        }, 1) : 1
    }
    // const { rows, cols } = gridDimensions
    return (
        <>
            {
                (activeFields).map(field => {
                    // if (!Array.isArray(field))
                    return (<>
                        {fields[field]['label'] && <Label htmlFor={fields[field].name}>{fields[field]['label']}</Label>}
                        <Field {...fields[field]} field={fields[field]} {...props} fields={fields} />
                    </>)
                }
                )
            }
        </>)
}

const Step = ({ active, fields, isLastStep, nextStep, prevStep, ...props }) => {
    const { isValid, shouldShowValidators } = props
    return (
        <Container
            isLastStep={isLastStep}
            rows={!isLastStep ? undefined : `repeat(7,min-content) 1fr`}
        >
            {(typeof (active) === 'object' && Array.isArray(active['fields'])) &&
                <MultiField activeFields={active['fields']} fields={fields} {...props}
                    tertiaryFieldOrder={active['tertiaryFieldOrder']}
                    gridTemp={active['gridTemp']}
                />}
            {(typeof (active) === 'string') &&
                <>
                    <Label htmlFor={fields[active].name}>{fields[active]['label']}</Label>
                    <Field {...fields[active]} field={fields[active]} {...props} />
                </>
            }
            {isLastStep &&
                <StepGrid cols="1fr" rows="min-content 12px min-content" gap="5px" width="100%" >

                    <GlassButton text="Submit" onClick={nextStep} />
                    <h5 style={{ margin: '0' }}>
                        {(shouldShowValidators && !isValid) && <Flexbox justifyContent="center" width="100%" margin="0 auto"><span style={{ marginRight: '5px', color: '#ff0a0a', fontSize: '10px' }}>Please complete all required fields marked with a </span><CheckmarkValidate position="relative" right="unset" isValid={false} top="unset" /></Flexbox>}
                    </h5>
                    <GlassButton className="dark" text={<span style={{ transform: 'rotate(180deg)', display: 'block' }}>➜</span>} borderRadius="30px" height="30px" width="30px" onClick={prevStep}
                        background="rgba(0,0,0,0)"
                        colorHover="#fff"
                        borderHover="1px solid #fff"
                        border="1px solid #fff" bgdHover="rgba(255,255,255,0.1)" />
                </StepGrid>
            }
        </Container>
    )
}

export default Step