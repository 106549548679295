import { createGlobalStyle } from 'styled-components';

const hexToRgbA = (hex, alpha = 1) => {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
    }
}


export const GlobalStyle = createGlobalStyle`
    :root{
        --maxWidth: 1280px;
        --navBarHeight: 60px;
        --defaultContainerHeight: calc(100vh - var(--navBarHeight));
        --background: #fff;
        --backgroundActiveLi: rgba(255,255,255,0.25);
        --borderRightActiveLi: 2px solid rgb(255,255,255);
        --yellow: #F2BD00;
        --white: #fff;
        --primary: #fff;
        --red:#b30000;
        --fontColor: #fff;
        --activeTab: var(--fontColor);
        --inactiveTab: var(--secondary);
        --formTileBoxShadow: 0 0 5px rgba(0,0,0,0.3);
        --defaultTileBoxShadow: 0 0 5px rgba(0,0,0,0.3);
        --secondary: #919191;
        --black: #000;
        --red: #9e0000;
        --green: #03cc00;
        --grey1: #575555;
        --formBlue1: #386aff;
        --formBlue2: rgb(27, 134, 246);
        --formBlueRaw: 27, 134, 246;
        --defaultBorder: 1px solid var(--white);
        --neonBlue: #00c3e6;
        --darkBlue: #001752;
        --deepBlue: #0276e3;
        --borderMediumThick: 2px solid var(--primary);
        --defaultFont: 'Albert Sans', sans-serif;
        --formColor: #ffffff;
        --defaultPagePadding: 0 50px;
        --formSelectedTileBackground: rgba(255,255,255,0.2);
        --tagBgdState: #fdca91;
        --tagBgdCity: #91c5fd;
        --tagBgdCounty: #91fdc9;
        --formBackground: linear-gradient(135deg,#000, #140033 90%, #320052 );
        --formBackground90: linear-gradient(90deg,var(--formBlue1), var(--formBlue2));
        --formBackgroundAngled: linear-gradient(45deg,var(--formBlue1), var(--formBlue2));
        --buttonDisabledBgd: #d6d6d6;
        --buttonDisabledText: #999999;
        --buttonDisabledBorder: 2px solid #999999;
        --formSelectedTileColor: #fff;
        --greyBackground: #262626;
        --greyBackground: #262626;
        --buttonFontSize: 13px;
        --dropdownLetterSpacing: '';
        --glassBgd: rgba(255,255,255,0.1);
        --dropdownBoxShadow: none;
        --dropdownBackground: linear-gradient(rgba(255,255,255,0.10) 90%,rgba(255,255,255,0.25));
        --dropdownBorder: var(--defaultBorder);
        --dropdownTextTransform: none;
        --dropdownHoverBackground: rgba(255,255,255,0.3); // 
        --nthRowBackground: #e8e8e8; // 
        --tableRowHover: rgb(178, 216, 255);
        --dropdownSelectedItemBackground: var(--glassBgd);
        --dropdownColor: #fff;
        --inputBoxShadow: none;
        --inputBackground: linear-gradient(rgba(255,255,255,0.10) 90%,rgba(255,255,255,0.25));
        --inputPlaceHolderColor: #dedede;
        --inputColor: #fff;
        --inputBackground: var(--glassBgd);
        --inputBorder: var(--defaultBorder);
        --inputFocusOutline: var(--defaultBorder);
        --inputFocusBorder: var(--defaultBorder);
        --inputLabelFontSize: 16px;
        --defaultButtonFontWeight: 700;
        --buttonfontWeightHover: 700;
        --defaultButtonLetterSpacing: 0.03em;
        --defaultButtonPadding: 20px 30px; 
        --defaultButtonBackground: #386aff;
        --buttonDisabledBackground: #c2c2c2;
        --defaultButtonColor: #ffffff;
        --defaultCheckRadioBorder: 3px solid rgba(255,255,255,0.25);
        --checkboxColor: var(--primary);
        --checkboxCheck: 2px solid var(--white);
        --checkboxBoxShadow: none;
        --checkboxBorder: var(--defaultCheckRadioBorder);
        --defaultButtonBackgroundHover: #99c5ff;
        --defaultButtonColorHover: #386aff;
        --defaultButtonBorderHover: 2px solid #386aff;
        --defaultButtonBorder: 2px solid var(--defaultButtonBackground);
        --fontMicro: 10px;
        --navBarPadding: 0px 50px;
        --navBarBoxShadow: 0 0 5px #000;
        --navBarFontWeightActive: 700;
        --fontSmall: 11px;
        --headerWeight: 500;
        --fontMedium: 1.1em;
        --fontBig: 1.5em;
        --fontNormal: 1.0em;
        --bold: 700;
        --medium: 500;
        --boldSmall: 600;
        --boldSmallLetterSpacing: 0.07rem;
        /* --buttonHeight: 56px; */
        --buttonBorder: 1px solid #000;
        --purpleGrey: #696980;
        --defaultInputHeight: 49px;
        --sideBarBackground: #99daff;
        --sideBarActiveBackground: rgba(255,255,255,0.5);
    }
    

    * {
        box-sizing: border-box;
        font-family: var(--defaultFont);
    }
    #root{
        max-width: 100%;
        height: 100%;
    }
    .cp{
        &::after{
            content: "Copyright ©2022-2023 Development Technologies Group. All Rights Reserved.";
        }
    }
    .phoneNumberPrivacyPolicy{
        &::after{
            content: "(332) 333-0426";
        }
    }
    .gradientText{
        background: var(--formBackgroundAngled);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .textLeft{
        text-align: left;
    }
    .noMargin{
        margin: 0;
    }
    .noSelect td{
        user-select: none;
    }
    .toolTipContainer .toolTip{
        display: none;
    }
    .scrollArea {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        overflow: auto;
        /* background: #000; */
    }
    .presentationContainer{
        margin: 0 auto;
        width: 100%;
        max-width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .title {
  font-size: 3rem;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  color: #fff;
  span {
    display: block;
  }
  @media (max-width: 1440px) {
    font-size: 6rem;
  }
}
    .loading {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #171717;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loading-bar-container {
  width: 100px;
  height: 32px;
  background: #272727;
}

.loading-bar {
  height: 32px;
  background: #f15946;
}
    .toolTipContainer:hover .toolTip{
        display: flex;
    }
    .privacyPolicy,.dnsmi{
        h3{
            margin: 2.5em 0 1.5em;
        }
        h3 + p{
            margin-top: 0;
        }
        h4{
            margin: 1.5em 0 .2em;
        }
        h4 + p{
            margin: 0;
        }
    }
    /* span.phoneOb:before{
        content: '(332) ';
    }
    span.phoneOb:after{
        content: '-0426';
    } */
    
    @media only screen and (max-width: 900px), screen and (max-device-width: 900px) {
            .marginOnMobile{
                margin: 1em 0 !important;
            }
            .hideOnMobile{
                visibility: hidden;
                display: none;
            }
            .p50LRMobile{
                padding-left: 50px;
                padding-right: 50px;
            }
            .flexRowMobile{
                display: flex;
                flex-direction: row
            }   
            .wrapMobile{
            flex-wrap: wrap;
            }
            .centerTextMobile{
                text-align: center;
            }
            .noWrapOnMobile{
                flex-wrap: nowrap;
            }
            .colOnMobile{
                flex-direction: column !important;
            }
            .noPaddingMobile{
                padding: 0;
            }
        
        }
    body{
        margin: 0;
        padding: 0;
        background: var(--background);
        color: var(--primary);
        height: 100%;
    }
    @font-face {
        font-family: 'Cano';
        src: local('Cano'), url({CanoOtf}) format('opentype');
        font-weight: 300;
        font-style: normal;
    }
    html{
        margin: 0;
        font-size: 15px;
        font-family: 'Colombia';
        height: 100%;
        color: #000;
        background: var(--background);
        /* h1{
            font-size: 3em;
            font-weight: var(--bold);
        } 
        */
        h1{
            user-select: none;
            font-size: 1.8em;
            font-weight: var(--bold);
            font-family: 'Montserrat';
            letter-spacing: -.03em;
        }
        h2{
            user-select: none;
            font-size: 1.5em;
            font-weight: var(--medium);
        }
        h4{
            font-weight: var(--boldSmall);
            font-size: .9em;
            margin: 0;
            
            /* font-weight: var(--bold);
font-size: 16px;
line-height: 1.2rem;
letter-spacing: 0.07rem;
text-transform: uppercase; */
        }
        p{
            /* font-weight: 300; */
            font-size: 1rem;
        }
        a{
            display: flex;
            text-decoration: none;
            color: var(--fontColor);
        }
    }
    @media screen and (min-width:768px) {
    html {
        font-size: 16px;
        h1{
            font-size: 2rem;
        }
        
    }
    }

    @media screen and (min-width:1024px) {
    html {
        font-size: 16px;
    }
    }

    @media screen and (min-width:1300px) {
    html {
        font-size: 18px;
    }
}
`;