import React from 'react'

import PageContentContainer from './PageContentContainer'
import { useNavigate } from 'react-router-dom'

import Section from './Section'
import GlassButton from './GlassButton'
import Flexbox from './Flexbox'
import GridContainer from './GridContainer'
import GridItem from './GridItem'
import Rocket from './Rocket'
import FAQ from './FAQ'
import HowItWorks from './HowItWorks'
import Building from './Building'
import styled from 'styled-components'

import MatchIconAnimated from './MatchIconAnimated'
import magnify from '../images/magnify.svg'
import phone from '../images/phone.svg'
import matchIcon from '../images/matchIcon.svg'

import stopwatch from '../images/stopwatch.svg'
import chart from '../images/growth.svg'
import diamond from '../images/diamond.svg'
import solicitor from '../images/noSolicitors.svg'
import screenedDevelopers from '../images/screenedDevelopers.svg'
import { RLink } from './PrivacyPolicy'
import Footer from './Footer'
import PageList from './PageListStyle2'
import Logos from './Logos'

const H1 = styled.h1`
font-size: 3rem;
margin: 0;
@media only screen and (max-width: 550px),
    screen and (max-device-width: 550px) {
        font-size: 1.6rem;
        text-align: center;
margin: 0;
    }
`;

const H3 = styled.h3`
margin: 0;
font-weight: 400;
@media only screen and (max-width: 550px){
        text-align: center;
        font-size: 1.3rem;
    }
`

const Home = ({ page = 'default' }) => {
    console.log("the value of page is: ", page)
    const mainHeading = "Let ChatGPT take your company to the stars."

    const navigate = useNavigate()
    const listContent = [
        { heading: 'We research and analyze to discover how ChatGPT can most powerfully improve your business.', img: magnify },
        { heading: 'We strategize with you to see what works, and make an action plan.', img: matchIcon },
        { heading: 'We get ChatGPT integrated into your web and mobile platforms, ready for deployment.', img: phone },
    ]
    const content = {
        title: "How It Works",
        id: 'how-it-works',
        props: {
            color: '#fff',
            // background: 'var(--formBackgroundAngled)',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignContent: 'center'
        },
        justifyContent: 'center',
        flexWrap: 'wrap',
        listItemMargin: 30,
        flexGrow: 0,
        headingFontSize: '1.0rem',
        maxWidth: "1200px",
        subTextStyling: {
            color: '#fff',
            // fontSize: '14px'
        },
        subtext:
            [

                {
                    text:
                        <>
                            <h3 style={{ margin: '0' }}>We analyze your company</h3>
                            <p className="marginOnMobile" style={{ margin: '.5em 0 0' }}>Tell us about your company and we'll make a plan for how ChatGPT (or GPT-4) can make the biggest impact.</p>
                            <p className="marginOnMobile" style={{ margin: '0 0 0.5em' }}>Chatbots and marketing text? Yes. But our specialty is using it in creative ways others have not yet figured out.</p>
                            <h3 style={{ margin: '0' }}>Strategize and implement</h3>
                            <p className="marginOnMobile" style={{ margin: '.5em 0 0' }}>We strategize with you to determine how to best use ChatGPT.</p>
                            <p className="marginOnMobile" style={{ margin: '0 0 0.5em' }}>Once you decide what works best for you, our GPTechies go to work and make the vision come to life.</p>

                            <p className="marginOnMobile" style={{ margin: '0 0 0.5em' }}>Learn more <RLink to="/about">here</RLink>.</p>
                        </>,
                    position: 'start',
                },

            ],
        listContent,
    }

    const whyChooseUs = {
        title: 'Adopt Before Your Competitors',
        subtext: [
            { text: <span>Sources: <a target="_blank" href="https://www.resumebuilder.com/1-in-4-companies-have-already-replaced-workers-with-chatgpt/">Resume Builder survey</a>  and <a target="_blank" href="https://www.reuters.com/technology/chatgpt-sets-record-fastest-growing-user-base-analyst-note-2023-02-01/">Reuters</a>.</span>, position: "end" }
        ],
        subTextStyling: {
            // color: '#fff',
            fontSize: '12px'
        },
        props: {
            // background: 'var(--formBackgroundAngled)',
            color: 'var(--formColor)',
            wrap: 'wrap', gap: '20px',
            justifyContent: 'center',

        },
        listParagraphColor: "#fff",
        listItemMargin: 30,
        flexWrap: 'wrap',
        maxWidth: '800px',
        listClassName: 'noWrapOnMobile',
        listContentTextProps: { lineHeight: '1.2em' },
        listContent: [
            { heading: 'Early bird gets the worm', background: "var(--defaultButtonBackgroundHover)", text: 'Companies late to the internet party went extinct. Early adopters earned free top spots on Google for years. This is the next big thing, its time to be an early adopter.' },
            { heading: 'Other companies are not waiting', text: "49% of companies use ChatGPT, 48% of those have already replaced workers with it. 93% say they plan to expand use of ChatGPT. You can still be early, but its not the time to hit the snooze button.", },
            { heading: 'First adopters LOVE it', text: "55% rate ChatGPT's work as 'excellent' and 34% as 'very good.' 99% say it saved them money, with over half saying they saved $50k or more." },
            { heading: 'Record-breaking growth rate', text: "Reuters states ChatGPT has the fastest growing user base of all time. Its capabilities are increasing exponentially, and so are its number of users." },
        ],
    }

    const growYourBusiness = {
        title: 'Grow Your Business',
        itemProps: {
            flexGrowMobile: '0', flexBasisMobile: 'auto',
            // maxWidth: '200px'
        },
        flexWrap: 'wrap',
        justifyContent: 'center',
        listParagraphColor: "#fff",
        listContainerProps: { maxWidth: '1000px', gap: '10px 40px' },
        listContentTextProps: { lineHeight: '1.2em' },
        // maxWidth: 'calc(200px * 2 + 20px * 2 * 2)',
        props: { wrap: 'wrap', gap: '20px', color: '#fff' },
        subTextStyling: {
            color: '#fff',

        },
        subtext:
            [
                {
                    text:
                        <>
                            Imagine helping more clients realize their vision, while significantly increasing your revenue. We help achieve this by generating quality leads that are more likely to become paying clients.<br /><br /> Leads are matched with developers based on your company's strengths being the best match for their goals, so they're eager to hear from you.
                            <br /><br />
                            We meet any budget requirements.  Start small and scale up as your business grows.
                        </>,
                    position: 'start'
                },],
        listContent: [
            {
                background: "var(--defaultButtonBackgroundHover)",
                heading: 'Save Time',
                text:
                    'Receive leads immediately after client fills out our form. They are actively looking to speak with you and discuss hiring details.',
                img: stopwatch,
                // textColor: 'var(--grey1)'
            },
            {
                heading: 'High Quality',
                text: 'Search ads driven by microtargeting equals more conversions and less wasted time. Each lead is sent to you and no one else.',
                img: diamond,
                // textColor: 'var(--grey1)'
            },
            {
                heading: 'Scale Up', text:
                    'Scale up your company.  We are able to provide significant and scalable lead volume that grows with your business.   ', img: chart,
                // textColor: 'var(--grey1)'
            },
            {
                heading: 'No Hassles', text: 'No sales call required to get started.  No upselling or overcharging. No annoying lead management software.', img: solicitor,
                // textColor: 'var(--grey1)'
            },
        ],
    }

    return (
        <PageContentContainer
            column
            display="flex"

            margin="calc(var(--navBarHeight) * -1) 0 0"
            position="relative"
            // background="var(--formBackground90)"

            // background="linear-gradient(#000,var(--formBlue2))"
            // background="linear-gradient(var(--formBlue1),var(--formBlue2))"
            // background="var(--formBackground)"
            // bgdSize="100% 100vh"
            // backgroundAttachment="fixed"
            // background="linear-gradient(90deg,var(--formBlue1), var(--formBlue2))"
            // top="0"
            // color="#fff"
            width="100%"
            height="100%"
            // background="var(--formBackground)"
            justifyContent="start" alignItems="start" flexDirection="row" padding="0 0 ">
            {/* <div style={{ background: "var(--formBackground)", position: 'fixed', top: '0', width: '100%', height: '100vh', zIndex:"0" }} /> */}
            <GridContainer
                // background="var(--formBackground)" 
                // background="linear-gradient(-45deg, #fff, rgba(27, 134, 246, 1))"
                // color="#fff"
                width="100%"
                gridTempMobile={{ columns: '1fr', rows: '' }}
                gridAutoRowMobile="min-content"
                gridTemp={{ rows: 'repeat(1, min-content)', columns: '2fr 1fr' }}
                gap="20px"
                color="#fff"
                padding="calc(var(--navBarHeight) * 2) 50px 10px"
                paddingMobile="calc(var(--navBarHeight) + 5px) 5% 50px"
                // background="var(--formBackground)"
                background="linear-gradient(var(--black),#00143d)"
                style={{ textAlign: 'left' }}>
                {/* <h1 style={{margin: '0'}}>Couldn't find your freelancelot?</h1> */}
                <Flexbox column alignItems="start" justifyContent="center" alignMobile="center" position="relative">
                    <H1>{mainHeading}</H1>
                    <GridContainer gap="0.1em" margin="1em 0" mobileGap="1em">
                        <H3 >ChatGPT is the most revolutionary technology since the internet.</H3>
                        <H3 >We help you leverage it before your competitors.</H3>
                    </GridContainer>
                    <GlassButton
                        width="90%"
                        mobileWidth="100%"
                        maxWidth="250px"
                        mobileMax="100%"
                        // border="none" borderHover="none" colorHover="#fff" 
                        text="Get Started"
                        boxShadow="rgba(0, 0, 0, 0.5) 0px 0px 7px"
                        onClick={() => navigate('/form')} />

                </Flexbox >
                <GridItem row="2" col="1">
                    {/* <p style={{margin: '0'}}>
                    Did your freelance developer, knight-in-shining-armor, turn out to be more of a Don Quixote?
                    <br/>
                    <br/>
                    Hiring a freelance developer is time consuming.
                    <br/>
                    <br/>
                    Get matched with a real development firm. Save time, get your project launched faster.
                    </p> */}
                </GridItem>
                <GridItem row="1 / span 1" alignSelf="center" col="2" mobileCol="1">
                    <Rocket />
                </GridItem>
            </GridContainer>

            <Flexbox
                column
                width="100%"
                background="linear-gradient(#00143d,#000766,black)"
                position="relative"
            >
                <Logos />
                <Section {...content} itemsPerRow={3} />
                <Building />
                <PageList />
                <Section {...whyChooseUs} itemsPerRow={2} />
                <span style={{ width: '100%', margin: '0 0 50px' }}><RLink to='/about' color="#fff">Learn More</RLink></span>
                {/* <Logos /> */}
            </Flexbox>
        </PageContentContainer>
    )
}

export default Home