import styled from "styled-components";

export const StateLabel = styled.div`
padding: 3px;
border: 1px solid var(--formColor);
border-radius: 5px;
padding: 5px;
cursor: pointer;
    transition: all 0.15s ease-in-out;
:hover, &.selected {
    background: rgba(255,255,255,.25);
  }
`;

export const H5 = styled.h5`
font-size: ${({ fontSize }) => fontSize};
margin: ${({ margin = '0' }) => margin};
`;

export const Div = styled.div`
width: 100%;
display: ${({ display }) => display};
justify-content: center;
@media only screen and (max-width: 550px),
    screen and (max-device-width: 550px) {
      display: flex;
      visibility: visible;
    }
`;

export const H1 = styled.h1`
font-size: ${({ fontSize }) => fontSize};
margin: ${({ margin = '0' }) => margin};
display: block;
max-width: 700px;
@media only screen and (max-width: 550px),
    screen and (max-device-width: 550px) {
      font-size: ${({ fontSizeMobile }) => fontSizeMobile};
    }
`;

export const SVG = styled.svg`
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};
  stroke: ${({ stroke = "var(--geoAreaStroke)" }) => stroke};
  height: ${({ height }) => height};
  max-height: ${({ maxHeight }) => maxHeight};
  min-height: ${({minHeight}) => minHeight};
  path {
    transition: all 0.15s ease-in-out;
    stroke-linejoin: round;
    cursor: pointer;
    fill: ${({ fill = "var(--geoAreaFillDefault)" }) => fill};
  }
  path:hover {
    fill: ${({ hoverFill = "var(--geoAreaFillHover)" }) => hoverFill};
  }
  .selected {
    fill: ${({ selectionFill = "var(--geoAreaFillSelected)" }) =>
    selectionFill};
    transition: all 0.15s ease-in-out;
  }
  @media only screen and (max-width: 550px),
    screen and (max-device-width: 550px) {
      width: ${({ widthMobile = '100%' }) => widthMobile};
      max-width: ${({ maxWidthMobile = '100%' }) => maxWidthMobile};
      min-height: unset;
      height: 100%;
    }
`;
