import React from 'react'

import {IconWrapper} from './Icon.styles'

const Icon = ({ onClick, ...props }) => {
    return (
        <IconWrapper {...props} />
    )
}

export default Icon