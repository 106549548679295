import React, { useRef } from 'react'
import { useLongForm } from '../../hooks/useLongForm'
import styled from 'styled-components'
import Step from './Step'
import NavArrow from '../Form/NavArrow'
import GridItem from '../GridItem'
import Button from '../ButtonHome'

const GridContainer = styled.div`
display: grid;
height: 100vh;
align-content: start;
grid-template-columns: minmax(80px,min-content) minmax(300px,600px) minmax(80px,min-content);
gap: 20px;
grid-template-rows: 1fr;
grid-template-areas: 
'back content next';
padding: 50px;
/* background: linear-gradient(#000d57, #007be0); */
/* background: #000d57; */
/* background: linear-gradient(
        217deg,
        rgba(8, 0, 255, 0.8),
        rgba(8, 0, 255, 0) 70.71%
    ), linear-gradient(127deg, rgba(0, 2, 71, 0.8), rgba(0, 2, 71, 0) 70.71%),
    linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 70.71%); */
/* background: radial-gradient(
        circle at 0 0,
        rgba(8, 0, 255, 1),
        rgba(8, 0, 255, 0)), radial-gradient(circle at 100% 0, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)),
    radial-gradient(circle at 0 100%, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 70.71%); */
    background: var(--formBackground);
margin: auto;
margin-top: calc( -1 * var(--navBarHeight));
overflow: auto;
justify-content: center;
@media only screen and (max-width: 550px), screen and (max-device-width: 550px) {
    grid-template-areas: 
    'content content'
    'back next';
    grid-template-rows: 1fr min-content;
grid-template-columns: 1fr 1fr;
padding: 50px 5%;
}
`;


const FormNew = () => {
    const { fieldOrder, formData, formatData, setFormData, active, setShowDropdown, nextStep, prevStep, isFirstStep, isLastStep, ...remaining } = useLongForm()
    const { fields, isValid } = formData
    console.log("fields", fields)
    return (
        <GridContainer>
            <GridItem
                alignSelf="center"
                className="formNavigation"
                // position="fixed"
                // row="1 / -1"
                gridArea="back">
                {!isLastStep && <NavArrow direction="back" disabled={isFirstStep} isLastStep={false} onClick={prevStep} />}
            </GridItem>
            <Step
                active={active}
                fields={fields}
                setShowDropdown={setShowDropdown}
                setFormData={setFormData}
                {...remaining}
                isFirstStep={isFirstStep}
                isLastStep={isLastStep}
                prevStep={prevStep}
                nextStep={() => nextStep(fields, formData)}
            />

            <GridItem
                alignSelf="center"
                justifySelf="end"
                className="formNavigation"
                // position="fixed"
                // row="1 / -1"
                // width="100%"
                gridArea="next">
                {!isLastStep && <NavArrow direction="next" disabled={!isValid} isLastStep={false} onClick={() => nextStep(fields, formData)} />}
            </GridItem>
        </GridContainer>
    )
}

export default FormNew