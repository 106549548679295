import React from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom';

const SVG = styled.svg`
height: 35px;
width: 131px;
object-fit: contain;
.gptechieslogo-cls-1,.gptechieslogo-cls-2, #svglogotext{
    fill:${({ fillColor }) => fillColor};
    /* transition: all 0.2s linear; */
}
.gptechieslogo-cls-3{
    fill:none;
stroke:${({ fillColor }) => fillColor};
stroke-miterlimit:10;
stroke-width:.72px;
}
.gptechieslogo-cls-4{letter-spacing:-.07em;
}
#svglogotext{font-family:Figtree-Bold, Figtree;
font-size:22.04px;
font-variation-settings:'wght' 700;
font-weight:700;
/* transition: all 1s linear; */
}

`;




const GPTechiesLogo = ({ fillColor }) => {
    return (
        <SVG fillColor={fillColor} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.81 35">
            <g >
                <text className="gptechieslogo-cls-2" id="svglogotext">
                    <tspan x="32.92" y="28.23">PTechies</tspan>
                </text>
                <g>
                    <circle className="gptechieslogo-cls-3" cx="18.7" cy="22.07" r="1.51" />
                    <circle className="gptechieslogo-cls-3" cx="13.49" cy="19" r="1.51" />
                    <circle className="gptechieslogo-cls-3" cx="19.95" cy="15.93" r="1.51" />
                    <path className="gptechieslogo-cls-1" d="m31.37,17v6.07c-.23,2.1-.89,4.01-1.99,5.67-1.31,2-3.1,3.58-5.29,4.68-2.12,1.05-4.49,1.59-7.1,1.59-3.3,0-6.3-.79-8.9-2.35-2.64-1.56-4.69-3.74-6.09-6.47-1.33-2.58-2-5.53-2-8.74s.7-6.22,2.1-8.84c1.44-2.68,3.47-4.81,6.03-6.33C10.67.77,13.57,0,16.77,0c1.74,0,3.48.3,5.16.92,1.61.58,3.12,1.4,4.48,2.47,1.42,1.09,2.6,2.39,3.5,3.82l-4.74,2.95c-.54-.87-1.29-1.67-2.19-2.37-.89-.7-1.89-1.24-2.96-1.63-1.06-.39-2.15-.58-3.25-.58-2.18,0-4.12.51-5.79,1.49-1.7,1-2.99,2.38-3.96,4.18-.96,1.79-1.44,3.88-1.44,6.2s.46,4.4,1.38,6.19c.93,1.8,2.24,3.18,3.98,4.23,1.73,1.03,3.77,1.55,6.04,1.55,1.73,0,3.28-.33,4.59-.99,1.19-.6,2.65-1.57,2.93-5.09v-.19h1.51c.59,0,1.07-.49,1.07-1.07s-.48-1.07-1.07-1.07h-1.47v-.93h3.43c.59,0,1.07-.48,1.07-1.07s-.49-1.07-1.07-1.07h-3.43v-.92h6.82Z" />
                    <path className="gptechieslogo-cls-1" d="m31.01,16.29h-9.56c-.2,0-.36-.16-.36-.36s.16-.36.36-.36h9.56c.2,0,.36.16.36.36s-.16.36-.36.36Z" />
                    <path className="gptechieslogo-cls-1" d="m27.97,19.36h-12.98c-.2,0-.36-.16-.36-.36s.16-.36.36-.36h12.98c.2,0,.36.16.36.36s-.16.36-.36.36Z" />
                    <path className="gptechieslogo-cls-1" d="m26.02,22.43h-5.81c-.2,0-.36-.16-.36-.36s.16-.36.36-.36h5.81c.2,0,.36.16.36.36s-.16.36-.36.36Z" />
                </g>
            </g>
        </SVG>
    )
}

export default GPTechiesLogo