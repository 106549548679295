import React from 'react'

const Select = ({ options = [], value }) => {
    return (
        <select>{
            options.map(it => {
                return (<option>{it}</option>)
            })}</select>
    )
}

export default Select