import React, { Suspense, useLayoutEffect, useEffect, useRef, useMemo, useState } from 'react'
import { ScrollControls, Scroll, OrthographicCamera, useScroll, OrbitControls } from '@react-three/drei'
import { Canvas, useFrame, useThree, extend } from "@react-three/fiber";
import * as THREE from 'three'
import atmosphereVertexShader from '../shaders/atmosphereVertex.glsl'
import atmosphereFragmentShader from '../shaders/atmosphereFragment.glsl'
import cardano from '../images/cardano.svg'

function hslToRgb(h, s, l) {
    var r, g, b;
    var hue = h / 360
    if (s == 0) {
        r = g = b = l; // achromatic
    } else {
        var hue2rgb = function hue2rgb(p, q, t) {
            if (t < 0) t += 1;
            if (t > 1) t -= 1;
            if (t < 1 / 6) return p + (q - p) * 6 * t;
            if (t < 1 / 2) return q;
            if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
            return p;
        }

        var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        var p = 2 * l - q;
        r = hue2rgb(p, q, hue + 1 / 3);
        g = hue2rgb(p, q, hue);
        b = hue2rgb(p, q, hue - 1 / 3);
    }

    return { r: Math.round(r * 255) / 255, g: Math.round(g * 255) / 255, b: Math.round(b * 255) / 255 };
}


const Foo = ({ position, color, shift, rotation, radius = 10, hue }) => {
    let col = hslToRgb(hue, 100 / 100, 50 / 100)
    console.log("col", col)
    const materialSun = new THREE.ShaderMaterial({
        vertexShader: atmosphereVertexShader,
        fragmentShader: atmosphereFragmentShader,
        // blending is needed otherwise the globe isn't visible
        blending: THREE.AdditiveBlending,
        side: THREE.BackSide,
        uniforms: {
            atmoColor: { value: { x: col.r, y: col.g, z: col.b, w: 0.5 } },
            // atmoColor: { value: { x: 235 / 255, y: 74 / 255, z: 0, w: 0.5 } },
        } // 
    })
    // console.log("rotation", rotation)
    const objectRef = useRef()
    const scroll = useScroll()
    useFrame((state, delta) => {
        // console.log("scroll", scroll)
        // const b = scroll.range(1 / 3, 1 / 3)
        // const d = scroll.curve(1 / 3, 1 / 3)
        // const f = scroll.visible(1 / 3, 1 / 3)
        // console.log("scroll", b, d, f)
        //     console.log("boop")
        // let t = state.clock.getElapsedTime();
        if (objectRef.current) {
            let group = objectRef.current;
            // console.log(group.rotation)
            // group.rotation.y = t * 2;
        }

    });
    // console.log("rotation", rotation)
    // console.log("position", position, new THREE.Vector3(position.x * -1, position.y, position.z))
    return (
        <group position={shift} ref={objectRef} rotation={rotation}>
            <mesh
                position={position}>
                <sphereGeometry args={[radius, 50, 50]} />
                {/* <icosahedronGeometry args={[radius, 3]} /> */}
                {/* <meshBasicMaterial color={color} /> */}
                <shaderMaterial
                    attach="material"
                    {...materialSun}
                // color="#ecff1a"
                // emissive="#ecff1a"
                // emissiveIntensity={50}
                />
            </mesh>
            <mesh
                position={new THREE.Vector3(position.x * -1, position.y, position.z)}>
                <sphereGeometry args={[radius, 50, 50]} />
                {/* <icosahedronGeometry args={[radius, 3]} /> */}
                <shaderMaterial
                    attach="material"
                    {...materialSun}
                // color="#ecff1a"
                // emissive="#ecff1a"
                // emissiveIntensity={50}
                />
                {/* <meshPhysicalMaterial
                    // color={color}
                    // attach="material"
                    opacity={1}
                    metalness={0.9}
                    clearcoat={1}
                    envMapIntensity={0.9}
                    transparent={true}
                    roughness={0.05}
                    // transmission={0.9}
                    reflectivity={0.2}
                    refractionRatio={0.985}
                    thickness={0.5}
                    ior={.9}
                    side={THREE.BackSide}
                /> */}
                {/* <meshPhysicalMaterial
                    // color={color}
                    // attach="material"
                    // opacity={0.5}
                    // metalness={0.9}
                    // clearcoat={1}
                    // envMapIntensity={0.9}
                    transparent={true}
                    roughness={0.7}
                    transmission={1}
                    // reflectivity={0.2}
                    // refractionRatio={0.985}
                    thickness={0.5}
                    // ior={.9}
                    // side={THREE.BackSide}
                /> */}
            </mesh>
            <mesh
                position={new THREE.Vector3(0, 0, 0)} rotation={[0, 0, Math.PI / 2]}>
                <cylinderGeometry args={[2, 2, position.x * 2 - radius * 2, 20]} />
                {/* <meshBasicMaterial color={color} /> */}
                <shaderMaterial
                    attach="material"
                    {...materialSun}
                // color="#ecff1a"
                // emissive="#ecff1a"
                // emissiveIntensity={50}
                />
            </mesh>
        </group>
    )
}

const Helix = () => {
    const arr = Array(15).fill(0)
    return (
        <>
            {
                arr.map((it, index) => {
                    const shift = Math.floor(arr.length / 2) * -1 + index
                    return (<Foo
                        hue={(263 - 182) / arr.length * (arr.length - index) + 182}
                        position={new THREE.Vector3(80, 0, 0)} color="red"
                        shift={new THREE.Vector3(0, shift * 30, 0)}
                        rotation={[0, index * 2 * Math.PI / arr.length, 0]} />)
                })
            }
        </>)
}

const Env = () => {

    const { viewport } = useThree()


    return (
        <ScrollControls pages={3} damping={0.25}>
            <Scroll>
                {/* Canvas contents in here will scroll along */}
                {/* <mesh
                    position={[0, 0, -100]}>
                    <planeGeometry args={[100, 100]} />
                    <meshBasicMaterial color={"#ff0000"} />
                </mesh> */}
                <Helix />
                {/* <Foo position={[0, viewport.height, 0]} color="green" /> */}
                {/* <Foo position={[0, viewport.height * 1, 0]} color="blue" /> */}
            </Scroll>
        </ScrollControls>
    )
}
const ThreeAnim = () => {
    return (
        <div style={{
            height: '100vh',
            width: '100%',
            marginTop: 'calc(var(--navBarHeight) * -1)',
            background: 'linear-gradient(#000,#001157)'
        }}>
            <Canvas concurrent
                colorManagement>
                <ambientLight intensity={1.0} />
                <spotLight color={[1, 0, 1]} intensity={1.5} angle={0.6} position={[5, 5, 0]} />
                <directionalLight intensity={0.2} position={[0, 10, 10]} color="#ffff00" />
                <spotLight color={[1, 1, 1]} intensity={1.5} angle={0.6} position={[0, 0, 5]} />
                <pointLight intensity={0.9} position={[-10, -10, 10]} color="#ff0000" />
                <Env />
                <OrbitControls target={[0, 0, 0]} />
                <OrthographicCamera position={[0, 0, 120]} fov={70} makeDefault
                // lookAt={new THREE.Vector3(0, 0, 0)} 
                />
                <Suspense>

                </Suspense>
                {/* <color args={[0, 0, 0.01]} attach="background" /> */}
            </Canvas>
        </div>
    )
}

export default ThreeAnim