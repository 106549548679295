import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import anime from 'animejs'

import GridItem from '../GridItem'
import Flexbox from '../Flexbox'
import GridContainer from '../GridContainer'

import { fixSvgElementCoordinates, setSvgElementCoords } from '../../utils'
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver'
const SVG = styled.svg`
width: 100%;
height: 100%;
position: relative;
object-fit: contain;
.cls-1{fill:url(#linear-gradient-2);}
/* #cloudL3Path{
    stroke: #fff;
} */
.cls-2{fill:rgba(255,255,255,.2);stroke-linecap:round;stroke-linejoin:round;}.cls-2,.cls-3,.cls-4,.cls-5{stroke:#fff;}.cls-3,.cls-4,.cls-6,.cls-5{stroke-miterlimit:10;}.cls-3,.cls-7{fill:none;}.cls-4{fill:url(#linear-gradient-4);}
.cls-6{fill:#fff;stroke:#7da2fe;}.cls-8{clip-path:url(#clippath);}.cls-5{fill:url(#linear-gradient);stroke-width:2px;}.cls-9{fill:url(#linear-gradient-3);}
@media only screen and (max-width: 550px){
        height: auto;
        width: 39vh;
    }
`;
const Rocket = () => {
    const animationPlayed = useRef(false)
    const [containerRef, isVisible] = useIntersectionObserver({
        root: null,
        rootMargin: "0px",
        threshold: 0.5
    }, true)
    const initialRef = useRef(true)
    useEffect(() => {
        function convertPolyToPath(poly) {
            var svgNS = poly.ownerSVGElement.namespaceURI;
            var path = document.createElementNS(svgNS, 'path');
            var pathdata = 'M ' + poly.getAttribute('points');
            if (poly.tagName == 'polygon') pathdata += 'z';
            path.setAttribute('d', pathdata);
            poly.getAttributeNames().forEach(function (name) {
                if (name !== 'points')
                    path.setAttribute(name, poly.getAttribute(name))
            })
            poly.parentNode.replaceChild(path, poly);
        }
        // var poly = document.querySelector('#rocketExhaust')
        // convertPolyToPath(poly)
        const initializeTimeline = () => {
            // anime.set(`#rocket`, {
            //     translateY: 224
            // })
            // anime.set(`#cloudL3`, {
            //     cx: 0,
            //     cy: 0,
            //     opacity: 0
            //     // scale: 0.2
            // })
            // const leftCloudCoords = fixSvgElementCoordinates('#cloudBigLeft', 1, 1)
            // const rightCloudCoords = fixSvgElementCoordinates('#cloudBigRight', 0, 1)
            // fixSvgElementCoordinates('#cloudL3')
            // // fixSvgElementCoordinates('#cloudL3Path')
            // var path1 = anime.path('#cloudL3Path')
            // console.log("path1", path1('x'))
            // // setSvgElementCoords(document.querySelector('#'), leftCloudCoords[0])
            // console.log("leftCloudCoords", leftCloudCoords)
            // console.log("rightCloudCoords", rightCloudCoords)
            // anime.set(`#cloudBigLeft`, {
            //     scale: 0.5
            // })
            // anime.set(`#cloudBigRight`, {
            //     scale: 0.5
            // })
            // let arrow1_dy = document.querySelector("#arrow1").getBBox()['height'] + 1;
            // let arrow2_dy = document.querySelector("#arrow2").getBBox()['height'] + 1;
            // let arrow3_dy = document.querySelector("#arrow3").getBBox()['height'] + 1;

            // var tl = anime.timeline({
            //     easing: 'linear',
            //     duration: 6000,
            //     autoplay: true,
            //     loop: true
            // })
            // tl
            //     .add({
            //         targets: `#arrow1`,
            //         easing: 'easeOutQuad',
            //         translateY: -arrow1_dy,
            //         duration: 1000,
            //         // endDelay: 3000,
            //     })
            //     .add({
            //         targets: `#arrow2`,
            //         easing: 'easeOutQuad',
            //         translateY: -arrow2_dy,
            //         duration: 1000,

            //         // endDelay: 3000,
            //     }, "-=750")
            //     .add({
            //         targets: `#arrow3`,
            //         easing: 'easeOutQuad',
            //         translateY: -arrow3_dy,
            //         duration: 1000,

            //         // endDelay: 3000,
            //     }, "-=750")
            //     .add({
            //         targets: `#cloudBigLeft`,
            //         scale: 1,
            //         duration: 1500
            //     }, '-=1500')
            //     .add({
            //         targets: `#cloudBigRight`,
            //         scale: 1,
            //         duration: 1500,
            //         // endDelay: 2000
            //     }, '-=1500')
            //     .add({
            //         targets: `#rocket`,
            //         easing: 'easeOutQuart',
            //         translateY: 0,
            //         duration: 2000
            //         // endDelay: 3000,
            //     }, "-=750")
            //     .add({
            //         targets: '#rocketExhaust',
            //         easing: 'easeOutQuart',
            //         d: [
            //             // {
            //             //     value: 'M365.9 660.2 344.7 660.2 349.22 660.2 361.38 660.2 365.9 660.2z',
            //             // },
            //             {
            //                 value: 'M365.9 660.2 344.7 660.2 349.22 424.48 361.38 424.48 365.9 660.2z',
            //             },

            //         ],
            //         duration: 2000,
            //         // endDelay: 2000
            //     }, '-=2000')

            //     .add({
            //         targets: `#cloudL3`,
            //         translateX: path1('x'),
            //         opacity: 1,
            //         translateY: path1('y'),
            //         r: [{ value: '23.47' }],
            //         duration: 1500,
            //         endDelay: 2000
            //     }, '-=2000')


            // tl.play()
        }
        if (initialRef.current) {
            initialRef.current = false
            // initializeTimeline()
        }
        anime.set(`#rocket`, {
            translateY: 224
        })
        anime.set(`#cloudL3`, {
            cx: 0,
            cy: 0,
            opacity: 0
            // scale: 0.2
        })
        const leftCloudCoords = fixSvgElementCoordinates('#cloudBigLeft', 1, 1)
        const rightCloudCoords = fixSvgElementCoordinates('#cloudBigRight', 0, 1)
        fixSvgElementCoordinates('#cloudL3')
        // fixSvgElementCoordinates('#cloudL3Path')
        var path1 = anime.path('#cloudL3Path')
        // setSvgElementCoords(document.querySelector('#'), leftCloudCoords[0])
        anime.set(`#cloudBigLeft`, {
            scale: 0.5
        })
        anime.set(`#cloudBigRight`, {
            scale: 0.5
        })
        let arrow1_dy = document.querySelector("#arrow1").getBBox()['height'] + 1;
        let arrow2_dy = document.querySelector("#arrow2").getBBox()['height'] + 1;
        let arrow3_dy = document.querySelector("#arrow3").getBBox()['height'] + 1;

        var tl = anime.timeline({
            easing: 'linear',
            duration: 6000,
            autoplay: false,
            loop: false
        })
        tl
            .add({
                targets: `#arrow1`,
                easing: 'easeOutQuad',
                translateY: -arrow1_dy,
                duration: 1000,
                // endDelay: 3000,
            })
            .add({
                targets: `#arrow2`,
                easing: 'easeOutQuad',
                translateY: -arrow2_dy,
                duration: 1000,

                // endDelay: 3000,
            }, "-=750")
            .add({
                targets: `#arrow3`,
                easing: 'easeOutQuad',
                translateY: -arrow3_dy,
                duration: 1000,

                // endDelay: 3000,
            }, "-=750")
            .add({
                targets: `#cloudBigLeft`,
                scale: 1,
                duration: 1500
            }, '-=1500')
            .add({
                targets: `#cloudBigRight`,
                scale: 1,
                duration: 1500,
                // endDelay: 2000
            }, '-=1500')
            .add({
                targets: `#rocket`,
                easing: 'easeOutQuart',
                translateY: 0,
                duration: 2000
                // endDelay: 3000,
            }, "-=750")
            .add({
                targets: '#rocketExhaust',
                easing: 'easeOutQuart',
                d: [
                    // {
                    //     value: 'M365.9 660.2 344.7 660.2 349.22 660.2 361.38 660.2 365.9 660.2z',
                    // },
                    {
                        value: 'M365.9 660.2 344.7 660.2 349.22 424.48 361.38 424.48 365.9 660.2z',
                    },

                ],
                duration: 2000,
                // endDelay: 2000
            }, '-=2000')

            .add({
                targets: `#cloudL3`,
                translateX: path1('x'),
                opacity: 1,
                translateY: path1('y'),
                r: [{ value: '23.47' }],
                duration: 1500,
                endDelay: 2000
            }, '-=2000')

        if (isVisible)
            tl.play()

        return () => {

        }
    }, [isVisible])

    return (
        <Flexbox refID={containerRef} justifyMobile="center">
            <SVG id="Layer_2" viewBox="0 0 599.93 687.9">
                <defs>
                    <linearGradient id="linear-gradient" x1="346.25" y1="360.49" x2="364.35" y2="360.49" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" gradientUnits="userSpaceOnUse">
                        <stop offset="0" stopColor="#386aff" />
                        <stop offset="1" stopColor="#1b86f6" />
                    </linearGradient>
                    <linearGradient id="linear-gradient-2" x1="1" y1="1" x2="1" y2="0" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" gradientUnits="objectBoundingBox">
                        <stop offset="0" stopColor="#386aff" stopOpacity="0" />
                        <stop offset=".5" stopColor="#e25300" />
                        <stop offset="1" stopColor="#fad336" />
                    </linearGradient>
                    {/* <linearGradient id="linear-gradient-2" x1="355.3" y1="660.2" x2="355.3" y2="424.48" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" gradientUnits="userSpaceOnUse">
                                <stop offset="0" stopColor="#386aff" stop-opacity="0" />
                                <stop offset=".5" stopColor="#e25300" />
                                <stop offset="1" stopColor="#fad336" />
                            </linearGradient> */}
                    <linearGradient id="linear-gradient-3" x1="409.57" y1="687.4" x2="409.57" y2="0" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" gradientUnits="userSpaceOnUse">
                        <stop offset="0" stopColor="#016dd6"   stopOpacity="1"/>
                        <stop offset="0.3" stopColor="#015fc1" stopOpacity="1"/>
                        <stop offset="1" stopColor="#001f5f" stopOpacity="0"/>
                    </linearGradient>
                    <linearGradient id="linear-gradient-4" x1="207.35" x2="207.35"
                    // xlink:href="#linear-gradient-3"
                    />
                    <clipPath id="clippath">
                        <rect id="arrow-mask" className="cls-7" x="15.43" y="402.05" width="272.15" height="257.5" />
                    </clipPath>
                </defs>
                <path id="cloudL3Path" class="cls-7" d="M352,658.29s-8.6-25.1-45.28-35.84-65.72,13.79-102.37-32.21" />
                <g id="Layer_2-2">
                    <g>
                        <path className="cls-2" id="cloudBigRight" d="M599.43,648.98c0,.13,0,.25-.01,.38-.16,5.69-4.88,10.19-10.57,10.19h-229.52c-2.19,0-4.03-1.75-4.03-3.94v-.04c0-11.78,9.54-21.32,21.32-21.32h.12c2.68-19.15,19.1-33.85,38.96-33.85,5.56,0,10.86,1.16,15.66,3.24,2.18-3.51,4.69-6.79,7.51-9.79,11.33-12.09,27.46-19.65,45.35-19.65,26.19,0,48.6,16.2,57.75,39.13,5.81-3.04,12.46-4.66,19.51-4.43,21.33,.71,38.38,18.74,37.95,40.08Z" />
                        <circle className="cls-2" cx="425.2" cy="593.51" r="16.8" />
                        <path className="cls-2" d="M436.73,647.39c0,4.43-1.2,8.59-3.29,12.16h-41.74c-2.09-3.57-3.28-7.73-3.28-12.16,0-13.35,10.81-24.16,24.15-24.16s24.16,10.81,24.16,24.16Z" />
                        <g id="rocket">
                            <path className="cls-2" d="M370.08,351.44l-14.78-24.47-14.78,24.47s-4.01,33.01,.17,48.42c3.16,11.63,9.04,18.08,9.04,18.08h11.12s5.89-6.44,9.04-18.08c4.19-15.42,.17-48.42,.17-48.42Z" />
                            <circle className="cls-5" cx="355.3" cy="360.49" r="9.05" />
                            <path className="cls-6" d="M348.12,418.63l-2.67,4-1.58,12.41c-.15,1.18-1.75,1.44-2.27,.36l-8.06-16.86c-.51-1.06-.43-2.31,.2-3.31l6.72-10.54c2.8,7.72,6.47,12.5,7.66,13.92Z" />
                            <path className="cls-6" d="M370.14,404.7l6.72,10.54c.63,.99,.71,2.24,.2,3.31l-8.06,16.86c-.51,1.08-2.12,.82-2.27-.36l-1.58-12.41-2.67-4c1.19-1.43,4.86-6.21,7.66-13.92Z" />
                            <polygon className="cls-6" points="360.86 419.37 355.3 419.37 349.74 419.37 348.38 421.47 349.09 422.63 355.3 422.63 361.51 422.63 362.22 421.47 360.86 419.37" />
                        </g>
                        <path id="cloudBigLeft" className="cls-2" d="M304.93,659.55h-123.1c-33.36,0-60.4-27.04-60.4-60.4s27.04-60.4,60.4-60.4c18.73,0,35.48,8.53,46.56,21.92,4.4-1.24,9.04-1.92,13.84-1.92,20.85,0,38.75,12.66,46.41,30.72,2.86-1.65,6.19-2.59,9.73-2.59,10.8,0,19.57,8.76,19.57,19.57,0,3.55-.95,6.88-2.6,9.75,3.77,1.93,6.93,4.85,9.12,8.44,2.18-1.05,4.62-1.64,7.21-1.64,9.22,0,16.69,7.48,16.69,16.69s-7.48,16.69-16.69,16.69c-3.96,0-7.59-1.37-10.44-3.67-4.15,4.22-9.93,6.84-16.32,6.84" />
                        {/* <circle id="cloudL3" className="cls-2" cx="204.34" cy="590.24" r="23.47" /> */}
                        <circle id="cloudL3" class="cls-2" cx="352" cy="658.29" r="6.43" />
                        <circle className="cls-2" cx="281.12" cy="641.44" r="16.86" />
                        <circle className="cls-2" cx="171.96" cy="630.57" r="8.12" />
                        <circle className="cls-2" cx="101.49" cy="574.2" r="8.12" />
                        <circle className="cls-2" cx="557.71" cy="578.66" r="14.85" />
                        <path className="cls-2" d="M560.61,641.95c0,6.95-2.93,13.2-7.6,17.6h-33.21c-4.68-4.4-7.6-10.65-7.6-17.6,0-10.64,6.86-19.67,16.4-22.9,2.45-.84,5.08-1.29,7.81-1.29,13.36,0,24.2,10.83,24.2,24.2Z" />
                        <path className="cls-2" d="M257.93,659.55c12.13-6.98,20.3-20.07,20.3-35.07,0-22.33-18.1-40.44-40.44-40.44s-40.44,18.1-40.44,40.44c0,15,8.16,28.09,20.3,35.07" />
                        <path className="cls-2" d="M536.32,647.28c0,4.21-.47,8.32-1.36,12.27h-108.23c-.89-3.94-1.36-8.06-1.36-12.27,0-6.39,1.08-12.54,3.07-18.25,7.55-21.68,28.16-37.23,52.41-37.23,20.33,0,38.1,10.93,47.76,27.24,4.91,8.27,7.72,17.93,7.72,28.24Z" />
                        {/* <polygon id="rocketExhaust" className="cls-1" points="365.9 660.2 344.7 660.2 349.22 424.48 361.38 424.48 365.9 660.2" /> */}
                        <path d="M365.9 660.2 344.7 660.2 349.22 660.2 361.38 660.2 365.9 660.2z" id="rocketExhaust" className="cls-1"></path>
                        <g id="chartaxis">
                            <polyline className="cls-3" points="423.45 362.83 423.45 186.25 423.45 56.76 442.79 95.44" />
                            <polygon className="cls-9" points="414.2 0 414.2 233.34 414.2 586.86 414.2 600.82 414.2 614.92 414.2 623.14 414.2 659.55 414.2 687.4 404.95 687.4 404.95 659.55 404.95 624.3 404.95 602.33 404.95 233.34 404.95 0 414.2 0" />
                            <polyline className="cls-4" points="404.95 74.84 404.95 233.34 404.95 602.33 404.95 624.3 404.95 659.55 404.95 678.15 .5 678.15 .5 687.4 414.2 687.4 414.2 659.55 414.2 623.14 414.2 614.92 414.2 600.82 414.2 586.86 414.2 233.34 414.2 0" />
                        </g>
                        <g className="cls-8">
                            <g id="arrows">
                                <polygon id="arrow1" className="cls-6" points="37.71 660.2 21.92 687.55 32.24 687.55 32.24 735.55 43.17 735.55 43.17 687.55 53.49 687.55 37.71 660.2" />
                                <polygon id="arrow2" className="cls-6" points="147.11 660.2 131.33 687.55 141.65 687.55 141.65 777.85 152.58 777.85 152.58 687.55 162.9 687.55 147.11 660.2" />
                                <polygon id="arrow3" className="cls-6" points="256.52 660.2 240.73 687.55 251.05 687.55 251.05 834.28 261.98 834.28 261.98 687.55 272.31 687.55 256.52 660.2" />
                            </g>
                        </g>
                    </g>
                </g>
            </SVG>
        </Flexbox>

    )
}

export default Rocket