import styled from 'styled-components'

export const Wrapper = styled.div`
display: grid;
background: ${({ background }) => background};
width: ${({ width }) => width};
max-width: ${({ maxWidth }) => maxWidth};
height: ${({ height }) => height};
max-height: ${({ maxHeight }) => maxHeight};
gap: ${({ gap }) => gap};
padding: ${({ padding }) => padding};
margin: ${({ margin }) => margin};
grid-template-rows: ${({ gridTemp }) => gridTemp ? gridTemp['rows'] : ''};
grid-template-columns: ${({ gridTemp }) => gridTemp ? gridTemp['columns'] : ''};
grid-template-areas: ${({ gridTempAreas }) => gridTempAreas};
grid-auto-rows: ${({ autoRows }) => autoRows};
grid-auto-columns: ${({ autoCols }) => autoCols};
justify-content: ${({ justify }) => justify};
justify-self: ${({ justifySelf }) => justifySelf};
justify-items: ${({ justifyItems }) => justifyItems};
align-items: ${({ align }) => align};
align-content: ${({ alignContent }) => alignContent};
left: ${props => props.left ? props.left : ''};
right: ${props => props.right ? props.right : ''};
top: ${props => props.top ? props.top : ''};
bottom: ${props => props.bottom ? props.bottom : ''};
position: ${props => props.position ? props.position : ''};
scroll-snap-type: ${({ scrollSnapType }) => scrollSnapType};
scroll-snap-align: ${({ scrollSnapAlign }) => scrollSnapAlign};
color: ${({ color }) => color};
box-shadow: ${({ boxShadow }) => boxShadow};
opacity: ${({ opacity }) => opacity};
font-size: ${({ fontSize }) => fontSize};
@media only screen and (max-width: 550px), screen and (max-device-width: 550px) {
    gap: ${({ mobileGap }) => mobileGap};
            display: ${({ mobileDisplay }) => mobileDisplay};
            z-index: ${({ mobileZindex }) => mobileZindex};
            width: ${({ mobileWidth }) => mobileWidth};
            visibility: ${({ mobileVisibility }) => mobileVisibility};
            height: ${({ mobileHeight }) => mobileHeight};
            flex-basis: ${({ mobileFlexBasis }) => mobileFlexBasis};
            grid-template-rows: ${({ gridTempMobile, gridTempRowsMobile }) => gridTempMobile && gridTempMobile['rows']? gridTempMobile['rows'] : gridTempRowsMobile ? gridTempRowsMobile :  ''};
            grid-template-columns: ${({ gridTempMobile, gridTempColsMobile }) => gridTempMobile && gridTempMobile['columns'] ? gridTempMobile['columns'] : gridTempColsMobile ? gridTempColsMobile : ''};
            grid-template-areas: ${({ gridTempAreasMobile }) => gridTempAreasMobile};
            grid-auto-rows: ${({ gridAutoRowMobile }) => gridAutoRowMobile};
            grid-auto-columns: ${({ gridAutoColsMobile }) => gridAutoColsMobile};
            justify-items: ${({ justifyItemsMobile }) => justifyItemsMobile};
            margin: ${({ mobileMargin }) => mobileMargin};
            padding: ${({ paddingMobile }) => paddingMobile};
            flex-grow: ${({ flexGrowMobile }) => flexGrowMobile};
            align-items: ${({ alignMobile }) => alignMobile};
        }
        >div{
            padding: ${({ cellPadding }) => cellPadding};
            border: ${({ cellBorder }) => cellBorder};
            outline: ${({ cellOutline }) => cellOutline};
            margin: ${({ cellMargin }) => cellMargin};
        }
        >div:nth-of-type(2n+1){
            padding: ${({ cellPaddingOdd }) => cellPaddingOdd};
            border: ${({ cellBorderOdd }) => cellBorderOdd};
            outline: ${({ cellOutlineOdd }) => cellOutlineOdd};
            margin: ${({ cellMarginOdd }) => cellMarginOdd};
            text-align: ${({ textAlignOdd }) => textAlignOdd};
        }
        >div:nth-of-type(2n){
            padding: ${({ cellPaddingEven }) => cellPaddingEven};
            border: ${({ cellBorderEven }) => cellBorderEven};
            outline: ${({ cellOutlineEven }) => cellOutlineEven};
            margin: ${({ cellMarginEven }) => cellMarginEven};
            text-align: ${({ textAlignEven }) => textAlignEven};
        }
`;