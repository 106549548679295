import React from "react";
import styled from "styled-components";
import Flexbox from "../Flexbox";
import GridContainer from "../GridContainer";
import GridItem from "../GridItem";
import { useIntersectionObserver } from "../../hooks/useIntersectionObserver";
import diamond from "../../images/diamond.svg";
import chart from "../../images/growth.svg";
import stopwatch from "../../images/stopwatch.svg";
import solicitor from "../../images/noSolicitors.svg";
import Building from "../Building";
// import
const OrderedList = styled.ul`
  color: #fff;
  list-style: none;
  padding: 0;
  /* max-width: 700px; */
  align-self: center;
  width: 90%;
  h3 {
    text-align: left;
    margin: 0;
  }
  & > :not(:last-child) {
    margin: 0 0 20vh;
    /* margin: 0 0 50px; */
  }
  @media only screen and (max-width: 600px),
    screen and (max-device-width: 600px) {
    & > :not(:last-child) {
      margin: 0 0 50px;
      /* margin: 0 0 50px; */
    }
  }
  /* margin: 100px 0; */
`;

const H1 = styled.h1`
  /* opacity: 0;
transform: translateY(20px);
transition: all 0.4s ease;

&.visible{
  opacity: 1;
  transform: translateY(0);
} */
`;

const Span = styled.h2`
  z-index: 1;
  position: relative;
  height: min-content;
  width: min-content;
  white-space: nowrap;
  align-self: center;
  margin: 0;
  /* margin-top: -0.45em; */
  font-size: 3rem;
  line-height: 1.1em;
  transition: all 0.4s ease;
  font-family: "Montserrat";
  letter-spacing: -.03em;
  transform: translateY(20px);
  opacity: 0;
  &.visible {
    transform: translateY(0); 
  opacity: 1;
  }
  ::after {
    content: "";
    font-size: inherit;
    position: absolute;
  transition: all 1s ease;
    left: 0;
    bottom: 0.1em;
    /* width: 0; */
    width: 110%;
    height: 2px;
    /* border-radius: 0.1em; */
    /* opacity: 0; */
    opacity: 1;
    background-color: transparent;
    /* background: linear-gradient(
      90deg,
      var(--formBlue1),
      var(--formBlue2) 50%,
      transparent 50%
    ); */
    background: linear-gradient(
      90deg,
      #ffffff,
      transparent 50%,
      transparent 50%
    );
    background-position: 100% 0%;
    background-size: 200% 100%;
    z-index: -1;
  }
  &.visible::after {
    width: 110%;
    background-position: 0% 0%;
  transition: all 1s ease;

    /* background-color: var(--formBlue1); */
  }

`;

const ListItem = styled.li`
  display: grid;
  width: 100%;
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content min-content;
  grid-template-areas:
    "icon heading"
    ". text";
  column-gap: 20px;

  /* text-shadow: 0 0 1px var(--formBlue1); */
  h3 {
    transition: all 0.4s ease;
    font-size: 1.5rem;
    transform: translateY(20px);
    opacity: 0;
  }
  &.visible {
    h3 {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @media only screen and (max-width: 600px),
    screen and (max-device-width: 600px) {
    justify-content: center;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 40px min-content;
    grid-gap: 15px;
    grid-template-areas:
      "icon"
      "heading"
      "text";
    justify-items: center;
    text-align: center !important;
    h3 {
      text-align: center !important;
      font-size: 1.4rem;
    }
    h2 {
      font-size: 1.5rem;
    }
  }
`;

const Marker = styled.div`
  display: flex;
  background: transparent;
  /* position: absolute; */
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 70px;
  width: 70px;
  padding: 8px;
  border-radius: 50%;
  transition: all 0.4s ease;
  /* border: 1px solid var(--formBlue1); */

  &.visible {
    box-shadow: -5px -5px 12px rgba(168, 245, 255, 0.25),
      6px 6px 15px rgba(0, 29, 194, 0.6),
      inset 5px 5px 5px rgba(255, 255, 255, 0.25), inset -5px -5px 5px rgba(0, 29, 194, 0.3);
    /* box-shadow: 0 0 5px var(--formBlue1); */
  }
  img {
    position: relative;
    height: 90%;
    width: 90%;
  }
  
`;
const ListComponent = ({ img, heading, text }) => {
  const [containerRef, isVisible] = useIntersectionObserver(
    {
      root: null,
      rootMargin: "0px 0px -10% 0px",
      // rootMargin: "10px",
      threshold: 0.2, // half of item height
    },
    true
  );
  return (
    <ListItem className={isVisible ? "visible" : undefined}>
      <Marker className={isVisible ? "visible" : undefined}>
        <img src={img} />
      </Marker>
      <Span
        ref={containerRef}
        gridArea="heading"
        className={isVisible ? "visible" : undefined}
      >
        {heading}
      </Span>
      <h3 style={{ fontWeight: "300", gridArea: "text" }}>{text}</h3>
    </ListItem>
  );
};

const PageList = () => {
  const listItems = [
    {
      img: stopwatch,
      heading: "Save Time",
      text: `ChatGPT has the potential to accomplish what takes a human hours, days, or weeks, in a matter of moments.`,
    },
    {
      heading: "Make Money",
      text: <>ChatGPT's capabilities are powerful enough to not just save money on labor, but to actually generate revenue.</>,
      img: diamond,
    },
    {
      // heading: 'A Monetary Motivation to Win',
      heading: "Scale Up",
      text: "ChatGPT can automate many aspects of business.  Test it out.  Be amazed. Scale it up throughout your entire operation.",
      img: chart,
    },
    {
      heading: "No Hassles",
      text: "Automate the hassles that take up your team's time so everyone can focus on increasing revenue.",
      img: solicitor,
    },
  ];

  const [containerRef, isVisible] = useIntersectionObserver(
    {
      root: null,
      rootMargin: "0px 0px -40% 0px",
      threshold: 0.5, // half of item height
    },
    true
  );

  return (
    <Flexbox
      column
      alignItems="center"
      width="100%"
      color="#fff"
      padding="50px 50px"
      mobilePadding="50px 5%"
      fontWeight="400"
      // background="var(--formBackground)"
    >
      <OrderedList>
        {listItems.map((item, index) => (
          <ListComponent key={index} {...item} />
        ))}
      </OrderedList>
    </Flexbox>
  );
};

export default PageList;
