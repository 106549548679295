import styled from 'styled-components';


export const LabelStyled = styled.label`
    position: relative;
    font-weight: ${props => props.labelWeight ? props.labelWeight : ''};
    font-size:  ${props => props.labelFontSize ? props.labelFontSize : '1em'};
    text-transform:  ${props => props.labelTextTransform ? props.labelTextTransform : 'none'};
    letter-spacing: ${props => props.labelTextTransform ? '0.1rem' : ''};
    display:inline-block;
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : '0'};

`;


export const TextAreaStyled = styled.textarea`
    resize: none;
    font-size: ${({ fontSize = '16px' }) => fontSize};
    line-height: ${({ lineHeight }) => lineHeight};
    height: ${props => props.height ? props.height : 'auto'};
    width: ${props => props.width ? props.width : '100%'};
    rows: ${props => props.rows ? props.rows : 'auto'};
    margin: ${({ margin = '2px' }) => margin};
    border-radius: 0;
    padding: ${({ padding }) => padding || '10px 20px'};
    width: 100%;
    color: ${({ color = '#fff' }) => color};
    background: ${({ background }) => background || "var(--inputBackground)"};
    border: ${({ border }) => border || 'var(--inputBorder)'};
    outline: ${({ outline }) => outline || 'var(--inputOutline)'};
    position: relative;
    transition: ${({ transition }) => transition};
    min-width:${({ minWidth }) => minWidth};
    max-width:${({ maxWidth }) => maxWidth};
    
    background: rgba(255,255,255,0.05);
    box-shadow: 
    inset -3px -3px 6px -3px rgba(0, 11, 92, 0.95), 
    /* inset 3px 3px 6px -3px rgba(255,255,255,0.95),  */
    inset 3px 3px 6px -3px rgba(255, 255, 255,0.5)
    ;
    border-radius: 15px;
    box-shadow: inset 3px 3px 6px -3px rgba(var(--formBlueRaw),0.5), inset -3px -3px 6px -3px rgba(255, 255, 255,0.10);
    border: 1px solid rgba(255,255,255,0.5);
    
            border: 1px solid rgba(var(--formBlueRaw),0.5);
            border-width: 1px 0 0 1px;
            /* border-radius: 5px; */
    ::placeholder{
        color: ${({ inputPlaceHolderColor = 'var(--inputPlaceHolderColor)' }) => inputPlaceHolderColor};
    }
    :disabled{
        background: ${({ disabledBgd }) => disabledBgd};
    }
    :focus{
        outline: ${({ focusOutline = 'var(--inputFocusOutline)' }) => focusOutline };
        outline: none;
        border: ${({ focusBorder = 'var(--inputFocusBorder)' }) => focusBorder };
        
        border: 1px solid var(--neonBlue);
    }

`;

export const Wrapper = styled.div`
    position: relative;
    width: ${props => props.width ? props.width : ''};
`;

export const InnerWrapper = styled.div`
  position: relative;
  margin-top: ${props => props.marginTop ? props.marginTop : '0'};
  width: ${props => props.width ? props.width : '100%'};
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const DollarSign = styled.div`
user-select: none;
position: absolute;
left: 1rem;
z-index: 1;
`;

export const AlertIconWrapper = styled.div`
position: absolute;
right: 5px;
`;

export const SearchIcon = styled.img`
height: 15px;
width: 15px;
cursor: pointer;
opacity: ${props => props.opacity ? props.opacity : '0.25'};
position: absolute;
right: 1rem;
user-select: none;
`;