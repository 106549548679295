import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
all: unset;
display: flex;
justify-content: center;
align-items: center;
background: rgba(159, 128, 255,0.25);
color: #fff;
font-family: inherit;
font-weight: 700;
font-size: 12px;
letter-spacing: 0.05em;
box-shadow: inset -3px -3px 7px 0px rgba(0, 199, 209, 0.75), inset 3px 3px 7px -2px rgba(0, 140, 255, 0.95), 0px 0px 3px 0px rgba(0, 11, 92, 0.25);
border-radius: 30px;
transition: all 0.3s ease-in;
cursor: ${({disabled}) => disabled ? "not-allowed" : "pointer"};
/* border-top: 2px solid rgba(52, 0, 209,0.3);
border-left: 2px solid rgba(52, 0, 209,0.3);
border-bottom: 2px solid rgba(0, 43, 214,0.5);
border-right: 2px solid rgba(0, 43, 214,0.5); */
box-sizing: border-box;
height: ${({height = "60px"}) => height};
width: ${({width = "100%"}) => width};
max-width: ${({maxWidth}) => maxWidth};
background: linear-gradient(
      90deg,
      rgba(var(--formBlueRaw), 0.25),
      rgba(var(--formBlueRaw),0.95) 50%,
      rgba(var(--formBlueRaw), 0.25) 100%
    );
    background-size: 240% 100%;
    background: transparent;

&.dark{
    background: linear-gradient(
      90deg,
      rgba(0, 52, 184, 0.95),
      rgba(0, 52, 184, 0.25) 50%,
      rgba(0, 52, 184, 0.95)  100%
    );
    border: none;
    /* box-shadow: inset 0 0 7px 3px rgba(0,0,0,0.5),  0px 0px 4px 1px rgba(0, 11, 92, 0.95); */
    
    background-size: 240% 100%;
    background: transparent;
}
:hover {
    /* color: var(--formBlue2); */
    /* width: 102%; */
    /* opacity: 1; */
    background-position: 100% 0%;
    /* background-color: var(--formBlue1); */
  }
`;
const GlassButton = ({ text, ...props }) => {
    return (
        <Button {...props}>{text}</Button>
    )
}

export default GlassButton