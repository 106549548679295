import styled from "styled-components";

export const ButtonStyle = styled.button`
    /* all: unset; */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: ${({ margin }) => margin};
    height: ${props => props.height ? props.height : 'var(--buttonHeight)'};
    font-size: ${props => props.fontSize ? props.fontSize : 'var(--buttonFontSize)'};
    color: ${({ color }) => color || 'var(--defaultButtonColor)'};
    width: ${({ width }) => width || 'var(--defaultButtonWidth)'};
    max-width: ${({ maxWidth }) => maxWidth};
    text-transform: ${({ textTransform }) => textTransform || 'var(-defaultButtonTextTransform)'};
    cursor: ${({ cursor = 'pointer' }) => cursor};
    box-shadow: ${({ boxShadow }) => boxShadow};
    border: ${({ border }) => border || 'var(--defaultButtonBorder)'};
    border-radius: ${({ borderRadius }) => borderRadius || 'var(--defaultButtonBorderRadius)'};
    background: var(--formBlue2);
    background: ${({ background }) => background || 'var(--defaultButtonBackground)'};
    font-weight: ${({ fontWeight }) => fontWeight || 'var(--defaultButtonFontWeight)'};
    padding: ${({ padding }) => padding || 'var(--defaultButtonPadding)'};
    padding-left: ${({ paddingLeft }) => paddingLeft || 'var(--defaultButtonPadding)'};
    padding-right: ${({ paddingRight }) => paddingRight || 'var(--defaultButtonPadding)'};
    letter-spacing: ${({ letterSpacing }) => letterSpacing || 'var(--defaultButtonLetterSpacing)'};
    white-space: nowrap;
    opacity: ${(props) => props.setOpacity ? props.setOpacity : ''};
    transition: 
        background 0.2s ease-in,
        color 0.2s ease-in,
        border 0.2s ease-in;
    :hover:not(.disabled){
        background: ${({ bgdHover }) => bgdHover || 'var(--defaultButtonBackgroundHover) '};
        border: ${({ borderHover }) => borderHover || 'var(--defaultButtonBorderHover) '};
        color: ${({ colorHover }) => colorHover || 'var(--defaultButtonColorHover)'};
        font-weight: ${({ fontWeightHover }) => fontWeightHover || 'var(--buttonfontWeightHover)'}
    }
    .disabled{
        cursor: not-allowed;
        background: ${({ bgdDisabled }) => bgdDisabled || 'var(--buttonDisabledBackground)'};
        font-weight: ${({ fontWeightDisabled }) => fontWeightDisabled || 'var(--buttonDisabledFontWeight)'}
    }
    @media only screen and (max-width: 900px), screen and (max-device-width: 900px) {
        max-width: ${({ mobileMax }) => mobileMax};
        width: ${({ mobileWidth }) => mobileWidth};
    }
`; 