import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Flexbox from "./Flexbox";
import anime from "animejs";
import { fixSvgElementCoordinates } from "../utils";
import { useIntersectionObserver } from '../hooks/useIntersectionObserver'
const SVG = styled.svg`
  height: auto;
  width: 90%;
  max-width: 400px;
  flex-shrink: 0;
  .building-cls-1,
  .building-cls-2,
  .building-cls-3 {
    fill: none;
  }

  .building-cls-4 {
    fill: #2f73fc;
  }

  .building-cls-4,
  .building-cls-2,
  .building-cls-3,
  .building-cls-5,
  .building-cls-6,
  .building-cls-7,
  .building-cls-8 {
    stroke: #fff;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .building-cls-9 {
    clip-path: url(#building-clippath);
  }

  .building-cls-3 {
    stroke-width: 1.82px;
  }

  .building-cls-5 {
    stroke-width: 0.77px;
  }

  .building-cls-5,
  .building-cls-6,
  .building-cls-7,
  .building-cls-8,
  .building-cls-10 {
    fill: rgba(255, 255, 255, 0.2);
  }

  .building-cls-6 {
    stroke-width: 0.6px;
  }

  .building-cls-8 {
    stroke-width: 0.55px;
  }
`;

const H1 = styled.h1`
text-align: left;
font-weight: inherit;
color: inherit;
font-size: 4rem;
@media only screen and (max-width: 800px),
    screen and (max-device-width: 800px) {
      font-size: 2rem;

    }
`;
const Building = () => {
    const [containerRef, isVisible] = useIntersectionObserver({
        root: null,
        rootMargin: "0px",
        threshold: 0.5
    }, true)
  useEffect(() => {
    // fixSvgElementCoordinates(".treeBranch");
    anime.set(`#building`, {
      translateY: 400,
    });
    var tl = anime.timeline({
    //   easing: 'steps(5)',
    easing: "easeOutQuad",
      duration: 6000,
      autoplay: false,
      loop: false,
    });
    tl.add({
      targets: `#building`,
      //   easing: "easeOutQuad",
      translateY: 400 * 3/4,
      duration: 500,
      endDelay: 0,
    }).add({
        targets: `#building`,
        //   easing: "easeOutQuad",
        translateY: 400 * 2/4,
        duration: 1000,
        endDelay: 500,
      })
    .add({
        targets: `#building`,
        //   easing: "easeOutQuad",
        translateY: 400 * 1/4,
        duration: 1000,
        // endDelay: 3000,
      })
      .add({
        targets: `#building`,
        //   easing: "easeOutQuad",
        translateY: 400 * 0/4,
        duration: 1000,
        // endDelay: 3000,
      })
    if (isVisible)
    tl.play();
  }, [isVisible]);
  return (
    <Flexbox row  color="#fff" 
    mobilePadding="50px 5%"
    padding="50px 50px" flexWrapMobile="wrap">
    <Flexbox
      maxWidth="1000px"
      column
      alignItems="start"
      margin="0 50px 0 0"
      mobileMargin="0"
    >
      <H1
      >
        Grow Your Business
      </H1>
      <h3
        style={{
          textAlign: "left",
          fontWeight: "inherit",
          color: "inherit",
        }}
      >
        Imagine life with an army of high-performance employees who love working around the clock...without pay. Every business wants this, and ChatGPT is the world's first AI to really deliver.
      </h3>
      <h3
        style={{
          textAlign: "left",
          fontWeight: "inherit",
          color: "inherit",
        }}
      >
        ChatGPT has the dual-powers of potentially providing enormous savings on labor costs, while simultaneously generating more revenue.
      </h3>
      <h3
        style={{
          textAlign: "left",
          fontWeight: "inherit",
          color: "inherit",
        }}
      >
        ChatGPT has the potential to automate a significant portion of many business operations, whether it's saving money by reducing the number of tasks employees are required to perform, or generating revenue by turbocharging your sales, marketing and advertising. 
      </h3>
    </Flexbox>
    <SVG
        id="Layer_2"
        dataName="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 684.41 695.19"
        ref={containerRef}
      >
        <defs>
          <clipPath id="building-clippath">
            <rect
              id="mask"
              className="building-cls-1"
              x="287.71"
              y=".5"
              width="201.65"
              height="586.13"
            />
          </clipPath>
        </defs>
        <g id="Layer_1-2" data-name="Layer 1">
          <line
            className="building-cls-3"
            x1="10.7"
            y1="689.53"
            x2="683.5"
            y2="689.53"
          />
          <rect
            className="building-cls-7"
            x="308.17"
            y="597.48"
            width="160.73"
            height="92.05"
          />
          <rect
            className="building-cls-7"
            x="294.87"
            y="586.63"
            width="187.34"
            height="10.85"
          />
          <rect
            className="building-cls-7"
            x="331.78"
            y="628.19"
            width="30.67"
            height="61.35"
          />
          <rect
            className="building-cls-7"
            x="383.47"
            y="628.19"
            width="72.19"
            height="35.41"
          />
          <g className="building-cls-9">
            <g id="building">
              <g id="building">
                <rect
                  className="building-cls-7"
                  x="294.87"
                  y="224.48"
                  width="187.34"
                  height="10.85"
                />
                <rect
                  className="building-cls-7"
                  x="308.17"
                  y="235.33"
                  width="53.86"
                  height="351.3"
                />
                <rect
                  className="building-cls-7"
                  x="415.05"
                  y="235.33"
                  width="53.86"
                  height="351.3"
                />
                <g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="324.21"
                      y="557.72"
                      width="14.7"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="324.21"
                      y="557.72"
                      width="14.7"
                      height="14.7"
                    />
                  </g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="324.21"
                      y="532.11"
                      width="14.7"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="324.21"
                      y="532.11"
                      width="14.7"
                      height="14.7"
                    />
                  </g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="324.21"
                      y="506.51"
                      width="14.7"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="324.21"
                      y="506.51"
                      width="14.7"
                      height="14.7"
                    />
                  </g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="324.21"
                      y="480.9"
                      width="14.7"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="324.21"
                      y="480.9"
                      width="14.7"
                      height="14.7"
                    />
                  </g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="324.21"
                      y="455.3"
                      width="14.7"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="324.21"
                      y="455.3"
                      width="14.7"
                      height="14.7"
                    />
                  </g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="324.21"
                      y="429.7"
                      width="14.7"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="324.21"
                      y="429.7"
                      width="14.7"
                      height="14.7"
                    />
                  </g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="324.21"
                      y="404.09"
                      width="14.7"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="324.21"
                      y="404.09"
                      width="14.7"
                      height="14.7"
                    />
                  </g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="324.21"
                      y="378.49"
                      width="14.7"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="324.21"
                      y="378.49"
                      width="14.7"
                      height="14.7"
                    />
                  </g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="324.21"
                      y="352.89"
                      width="14.7"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="324.21"
                      y="352.89"
                      width="14.7"
                      height="14.7"
                    />
                  </g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="324.21"
                      y="327.28"
                      width="14.7"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="324.21"
                      y="327.28"
                      width="14.7"
                      height="14.7"
                    />
                  </g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="324.21"
                      y="301.68"
                      width="14.7"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="324.21"
                      y="301.68"
                      width="14.7"
                      height="14.7"
                    />
                  </g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="324.21"
                      y="276.08"
                      width="14.7"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="324.21"
                      y="276.08"
                      width="14.7"
                      height="14.7"
                    />
                  </g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="324.21"
                      y="250.47"
                      width="14.7"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="324.21"
                      y="250.47"
                      width="14.7"
                      height="14.7"
                    />
                  </g>
                </g>
                <rect
                  className="building-cls-7"
                  x="362.03"
                  y="235.33"
                  width="53.02"
                  height="351.3"
                />
                <rect
                  className="building-cls-7"
                  x="359.67"
                  y="142.42"
                  width="57.73"
                  height="82.06"
                />
                <g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="371.95"
                      y="557.72"
                      width="33.18"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="371.95"
                      y="557.72"
                      width="33.18"
                      height="14.7"
                    />
                  </g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="371.95"
                      y="532.11"
                      width="33.18"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="371.95"
                      y="532.11"
                      width="33.18"
                      height="14.7"
                    />
                  </g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="371.95"
                      y="506.51"
                      width="33.18"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="371.95"
                      y="506.51"
                      width="33.18"
                      height="14.7"
                    />
                  </g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="371.95"
                      y="480.9"
                      width="33.18"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="371.95"
                      y="480.9"
                      width="33.18"
                      height="14.7"
                    />
                  </g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="371.95"
                      y="455.3"
                      width="33.18"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="371.95"
                      y="455.3"
                      width="33.18"
                      height="14.7"
                    />
                  </g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="371.95"
                      y="429.7"
                      width="33.18"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="371.95"
                      y="429.7"
                      width="33.18"
                      height="14.7"
                    />
                  </g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="371.95"
                      y="404.09"
                      width="33.18"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="371.95"
                      y="404.09"
                      width="33.18"
                      height="14.7"
                    />
                  </g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="371.95"
                      y="378.49"
                      width="33.18"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="371.95"
                      y="378.49"
                      width="33.18"
                      height="14.7"
                    />
                  </g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="371.95"
                      y="352.89"
                      width="33.18"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="371.95"
                      y="352.89"
                      width="33.18"
                      height="14.7"
                    />
                  </g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="371.95"
                      y="327.28"
                      width="33.18"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="371.95"
                      y="327.28"
                      width="33.18"
                      height="14.7"
                    />
                  </g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="371.95"
                      y="301.68"
                      width="33.18"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="371.95"
                      y="301.68"
                      width="33.18"
                      height="14.7"
                    />
                  </g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="371.95"
                      y="276.08"
                      width="33.18"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="371.95"
                      y="276.08"
                      width="33.18"
                      height="14.7"
                    />
                  </g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="371.95"
                      y="250.47"
                      width="33.18"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="371.95"
                      y="250.47"
                      width="33.18"
                      height="14.7"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="436.77"
                      y="557.72"
                      width="14.7"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="436.77"
                      y="557.72"
                      width="14.7"
                      height="14.7"
                    />
                  </g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="436.77"
                      y="532.11"
                      width="14.7"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="436.77"
                      y="532.11"
                      width="14.7"
                      height="14.7"
                    />
                  </g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="436.77"
                      y="506.51"
                      width="14.7"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="436.77"
                      y="506.51"
                      width="14.7"
                      height="14.7"
                    />
                  </g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="436.77"
                      y="480.9"
                      width="14.7"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="436.77"
                      y="480.9"
                      width="14.7"
                      height="14.7"
                    />
                  </g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="436.77"
                      y="455.3"
                      width="14.7"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="436.77"
                      y="455.3"
                      width="14.7"
                      height="14.7"
                    />
                  </g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="436.77"
                      y="429.7"
                      width="14.7"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="436.77"
                      y="429.7"
                      width="14.7"
                      height="14.7"
                    />
                  </g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="436.77"
                      y="404.09"
                      width="14.7"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="436.77"
                      y="404.09"
                      width="14.7"
                      height="14.7"
                    />
                  </g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="436.77"
                      y="378.49"
                      width="14.7"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="436.77"
                      y="378.49"
                      width="14.7"
                      height="14.7"
                    />
                  </g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="436.77"
                      y="352.89"
                      width="14.7"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="436.77"
                      y="352.89"
                      width="14.7"
                      height="14.7"
                    />
                  </g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="436.77"
                      y="327.28"
                      width="14.7"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="436.77"
                      y="327.28"
                      width="14.7"
                      height="14.7"
                    />
                  </g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="436.77"
                      y="301.68"
                      width="14.7"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="436.77"
                      y="301.68"
                      width="14.7"
                      height="14.7"
                    />
                  </g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="436.77"
                      y="276.08"
                      width="14.7"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="436.77"
                      y="276.08"
                      width="14.7"
                      height="14.7"
                    />
                  </g>
                  <g>
                    <rect
                      className="building-cls-10"
                      x="436.77"
                      y="250.47"
                      width="14.7"
                      height="14.7"
                    />
                    <rect
                      className="building-cls-2"
                      x="436.77"
                      y="250.47"
                      width="14.7"
                      height="14.7"
                    />
                  </g>
                </g>
                <rect
                  className="building-cls-7"
                  x="349"
                  y="131.57"
                  width="79.08"
                  height="10.86"
                />
                <g>
                  <polygon
                    className="building-cls-7"
                    points="406.61 108.34 409.18 120.49 403.12 114.49 388.54 100.08 373.96 114.49 367.89 120.49 370.45 108.34 365.55 108.34 372.56 102.02 388.54 87.6 404.52 102.02 411.52 108.34 406.61 108.34"
                  />
                  <polygon
                    className="building-cls-7"
                    points="396.15 70.27 398.22 80.05 398.1 80.05 388.54 71.43 378.98 80.05 378.86 80.05 380.92 70.27 378.86 70.27 385.95 63.87 388.54 61.53 391.12 63.87 398.22 70.27 396.15 70.27"
                  />
                  <polygon
                    className="building-cls-7"
                    points="388.54 61.53 385.95 63.87 385.95 63.86 388.54 61.53"
                  />
                  <polygon
                    className="building-cls-7"
                    points="391.12 63.87 388.54 61.53 391.12 63.86 391.12 63.87"
                  />
                  <polygon
                    className="building-cls-7"
                    points="401.12 85.86 404.53 102.02 404.52 102.02 388.54 87.61 372.56 102.02 372.54 102.02 375.95 85.86 372.54 85.86 378.98 80.05 388.54 71.43 398.1 80.05 404.53 85.86 401.12 85.86"
                  />
                  <polygon
                    className="building-cls-7"
                    points="391.12 63.86 391.12 63.87 388.54 61.53 385.95 63.87 385.95 63.86 388.54 3.8 391.12 63.86"
                  />
                  <polygon
                    className="building-cls-7"
                    points="411.52 131.57 365.55 131.57 367.82 120.82 367.89 120.49 373.96 114.49 388.54 100.08 403.12 114.49 409.18 120.49 409.25 120.82 411.52 131.57"
                  />
                  <polygon
                    className="building-cls-7"
                    points="367.82 120.82 367.56 120.82 367.89 120.49 367.82 120.82"
                  />
                  <polygon
                    className="building-cls-7"
                    points="409.25 120.82 409.18 120.49 409.51 120.82 409.25 120.82"
                  />
                </g>
                <path
                  className="building-cls-7"
                  d="m404.83,167.55v48.1h-32.59v-48.1c0-8.99,7.3-16.29,16.3-16.29s16.29,7.3,16.29,16.29Z"
                />
              </g>
            </g>
          </g>
        </g>
        <g id="Layer_2-2" data-name="Layer 2">
          <path
            className="building-cls-5"
            d="m199.6,471.22c0,.1,0,.19,0,.29-.12,4.4-3.78,7.88-8.18,7.88H13.82c-1.69,0-3.12-1.35-3.12-3.05v-.03c0-9.12,7.38-16.5,16.5-16.5h.09c2.07-14.82,14.78-26.19,30.15-26.19,4.3,0,8.4.9,12.12,2.51,1.69-2.72,3.63-5.25,5.81-7.58,8.77-9.35,21.25-15.2,35.09-15.2,20.27,0,37.61,12.54,44.69,30.28,4.5-2.35,9.64-3.61,15.1-3.43,16.5.55,29.7,14.5,29.36,31.01Z"
          />
          <path
            className="building-cls-6"
            d="m240.81,473.07c0,.08,0,.15,0,.23-.1,3.41-2.92,6.1-6.33,6.1H97.06c-1.31,0-2.41-1.05-2.41-2.36v-.02c0-7.05,5.71-12.76,12.76-12.76h.07c1.6-11.47,11.44-20.27,23.33-20.27,3.33,0,6.5.69,9.38,1.94,1.31-2.1,2.81-4.07,4.5-5.86,6.78-7.24,16.44-11.77,27.15-11.77,15.68,0,29.1,9.7,34.58,23.43,3.48-1.82,7.46-2.79,11.68-2.65,12.77.43,22.98,11.22,22.72,24Z"
          />
          <path
            className="building-cls-8"
            d="m512.21,434.92c0,.07,0,.14,0,.21.09,3.11,2.67,5.57,5.78,5.57h125.42c1.2,0,2.2-.96,2.2-2.15v-.02c0-6.44-5.21-11.65-11.65-11.65h-.07c-1.46-10.46-10.44-18.5-21.29-18.5-3.04,0-5.93.63-8.56,1.77-1.19-1.92-2.56-3.71-4.1-5.35-6.19-6.61-15.01-10.74-24.78-10.74-14.31,0-26.56,8.85-31.56,21.38-3.17-1.66-6.81-2.55-10.66-2.42-11.66.39-20.97,10.24-20.74,21.9Z"
          />
        </g>
        {/* <path
          className="building-cls-2 tree"
          d="m518.94,690.01h-9.12c3.26-10.14,3.72-8.83,1.61-65.2h5.89c0,54.56-1.32,53.1,1.61,65.2Z"
        />
        <path
          className="building-cls-4 treeBranch"
          d="m508.85,619.85c1.89.32,3.67,1.25,5.59,1.34,2.07.09,4.39-.76,6.09.42,1.6,1.12,1.78,3.51,3.23,4.82,1.46,1.31,3.78,1.17,5.33,2.37,1.6,1.23,1.94,3.52,1.78,5.54s-.69,4.05-.31,6.04c.26,1.38.95,2.82.4,4.11-.33.78-1.06,1.33-1.46,2.07-1.12,2.09.49,5.3-1.38,6.76-1.68-1.25-4.06.18-5.35,1.83s-2.48,3.72-4.53,4.11c-1.52.29-3.03-.47-4.56-.73-3.15-.54-6.62,1.01-9.45-.47-1.09-.57-1.91-1.52-2.71-2.45-1.68-1.95-3.43-4.04-3.82-6.59-.17-1.12-.08-2.29-.51-3.33-.69-1.65-2.65-3.03-2.09-4.72.17-.53.58-.94.84-1.44.7-1.33.24-2.93-.03-4.41s-.2-3.3,1.08-4.08c.44-.12.87-.25,1.31-.37.46-2.08,1.24-4.09,2.29-5.93.1-.17,1.86-.4,2.26-.68.92-.65.8-1.63,1.36-2.21,1.39-1.45,2.27-2.41,4.65-2.01Z"
        /> */}
        {/* <g id="awning">
          <polygon
            className="building-cls-4"
            points="328.29 579.48 308.17 579.48 295.8 613.82 319.02 613.82 328.29 579.48"
          />
          <polygon
            className="building-cls-4"
            points="348.41 579.48 328.29 579.48 319.02 613.82 342.23 613.82 348.41 579.48"
          />
          <polygon
            className="building-cls-4"
            points="368.53 579.48 348.41 579.48 342.23 613.82 365.44 613.82 368.53 579.48"
          />
          <polygon
            className="building-cls-4"
            points="388.65 579.48 368.53 579.48 365.44 613.82 388.65 613.82 388.65 579.48"
          />
          <polygon
            className="building-cls-4"
            points="408.77 579.48 388.65 579.48 388.65 613.82 411.86 613.82 408.77 579.48"
          />
          <polygon
            className="building-cls-4"
            points="428.89 579.48 408.77 579.48 411.86 613.82 435.08 613.82 428.89 579.48"
          />
          <polygon
            className="building-cls-4"
            points="449.01 579.48 428.89 579.48 435.08 613.82 458.29 613.82 449.01 579.48"
          />
          <polygon
            className="building-cls-4"
            points="469.13 579.48 449.01 579.48 458.29 613.82 481.5 613.82 469.13 579.48"
          />
          <path
            className="building-cls-4"
            d="m307.41,613.46h11.61c0,5.57-5.24,10.15-11.61,10.15s-11.61-4.58-11.61-10.15h11.61Z"
          />
          <path
            className="building-cls-4"
            d="m330.62,613.46h11.61c0,5.57-5.24,10.15-11.61,10.15s-11.61-4.58-11.61-10.15h11.61Z"
          />
          <path
            className="building-cls-4"
            d="m353.83,613.46h11.61c0,5.57-5.24,10.15-11.61,10.15s-11.61-4.58-11.61-10.15h11.61Z"
          />
          <path
            className="building-cls-4"
            d="m377.05,613.46h11.61c0,5.57-5.24,10.15-11.61,10.15s-11.61-4.58-11.61-10.15h11.61Z"
          />
          <path
            className="building-cls-4"
            d="m400.26,613.46h11.61c0,5.57-5.24,10.15-11.61,10.15s-11.61-4.58-11.61-10.15h11.61Z"
          />
          <path
            className="building-cls-4"
            d="m423.47,613.46h11.61c0,5.57-5.24,10.15-11.61,10.15s-11.61-4.58-11.61-10.15h11.61Z"
          />
          <path
            className="building-cls-4"
            d="m446.68,613.46h11.61c0,5.57-5.24,10.15-11.61,10.15s-11.61-4.58-11.61-10.15h11.61Z"
          />
          <path
            className="building-cls-4"
            d="m469.89,613.46h11.61c0,5.57-5.24,10.15-11.61,10.15s-11.61-4.58-11.61-10.15h11.61Z"
          />
        </g> */}
      </SVG>
  </Flexbox>
  );
};

export default Building;
