import React from 'react';

import { ButtonStyle } from './Button.styles';

const Button = ({ children, onClick, text, ...props }) => {
    return (
        <ButtonStyle
            onClick={onClick}
            {...props}>
            {text}{children}
        </ButtonStyle>
    )
}
export default Button;