import React from 'react'
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom'

const DescriptionAndKeywords = ({ pathname }) => {
        return (
            <Helmet prioritizeSeoTags>
                
                <title>GPTechies - ChatGPT Consulting & Software Development - GPT4</title>
                <meta
                    name="description"
                    content="ChatGPT & GPT-4 can revolutionize your business. We show you how, then we build & integrate. Dramatically increase efficiency & profit. Custom ChatGPT training."
                />
                <meta
                    name="keywords"
                    content="" />
            </Helmet>
        )

}

const DynamicMetaData = () => {
    const { pathname } = useLocation()
    return (
        <DescriptionAndKeywords pathname={pathname} />
    )
}

export default DynamicMetaData