import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const location = useLocation();
  const { pathname } = location

  useEffect(() => {
    if (!location['hash'] || location['hash'].length === 0)
      window.scrollTo(0, 0);
    else
      window.location.replace(`${location['hash']}`)

  }, [pathname]);

  return null;
}