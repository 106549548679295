import React from 'react'
import styled from 'styled-components'

const Image = styled.img`
position: ${({ position = "relative" }) => position};
height: ${({ height }) => height};
width: ${({ width }) => width};
max-width: ${({ maxWidth }) => maxWidth};
max-height: ${({ maxHeight }) => maxHeight};
flex-shrink: ${({ flexShrink }) => flexShrink};
object-fit: ${({ objectFit }) => objectFit};
object-position: ${({ objectPosition }) => objectPosition};
margin: ${({ margin }) => margin};
@media only screen and (max-width: 550px),
    screen and (max-device-width: 550px) {
        max-height: ${({ maxHeightMobile }) => maxHeightMobile};
        align-self: ${({ alignSelfMobile }) => alignSelfMobile};
    }
`;
const ResponsiveImage = ({ defaultImg, srcSet, ...props }) => {
    return (
        <Image
            src={defaultImg}
            srcSet={srcSet.reduce((a, b, index) => {
                if (index > 0)
                    a = `${a}, ${b} ${(index + 1)}x`
                else
                    return `${b} ${index + 1}x`
                return a
            }, '')}
            {...props}
        />
    )
}

export default ResponsiveImage