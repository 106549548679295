import React, { useState, useEffect, useRef } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import states from "../utils/states.js";
import { camelToTitleCase, toTitleCase, formatPhoneNumber, RadioButtonEl, newEnum } from '../utils'
import { useGeoHook } from './useGeoHook.js';
import { validateEmail } from '../components/Form'
import API from '../API.js';

const Link = styled(NavLink)`
color: var(--formBlue1);
display: inline;
`;

export const useLongForm = () => {
    const navigate = useNavigate()
    // const [valid, setValid] = useState(false)
    // const [shouldShowValidators, setShouldShowValidators] = useState(false)

    let previousFormData = window.sessionStorage.getItem("formDataLong");
    const getPrevValue = (name, defaultVal = "") => {
        // let _v = JSON.parse(previousFormData)
        console.log("parse(previousFormData)[name]", JSON.parse(previousFormData))
        return ((previousFormData && (JSON.parse(previousFormData)[name] !== undefined)
            ? JSON.parse(previousFormData)[name]
            : defaultVal))
    }
    const fields = {
        "field-6": {
            id: "field-6",
            name: "name",
            label: 'Name',
            placeholder: "Name",
            type: "input",
            value: getPrevValue("name", ""),
            isValid: false,
            shouldShow: false,
            validate: function (v = this.value) {
                return v.trim().length > 0
            },
            get isValid() {
                return this.validate(this.value)
            },
        },
        "field-9": {
            id: "field-9",
            name: "company",
            placeholder: "Company",
            label: 'Company',
            type: "input",
            value: getPrevValue("company", ""),
            isValid: false,
            shouldShow: false,
            validate: function (v = this.value) {
                return v.trim().length > 0
            },
            get isValid() {
                return this.validate(this.value)
            },
        },
        // "field-8": {
        //     id: "field-8",
        //     name: "chatGptGoals",
        //     type: "select",
        //     valueArrayKey: 'displayName',
        //     valueIsSelectedKey: 'displayName',
        //     textPosition: 'top',
        //     itemsPerRow: 1,
        //     itemsPerRowMobile: 1,
        //     labelInternal: false,
        //     label: "What applies to you?",
        //     tileProps: {
        //         width: '100%',
        //         maxWidth: 'unset',
        //         fontSize: "calc(1/0.90 * 16px)",
        //         height: "min-content"
        //     },
        //     outerContainerProps: {
        //         justifyContent: 'start'
        //     },
        //     options: ["I want to explore what chatgpt can do for me", "I already know what I want"],
        //     value: getPrevValue("chatGptGoals", ""),
        //     isOptional: false,
        //     validate: function () {
        //         if (typeof (this.value) !== 'string')
        //             return true
        //         return (
        //             this.value.trim() !== "" &&
        //             this.value.trim() !== "no-answer" &&
        //             this.value.trim() !== "N/A"
        //         );
        //     },
        // },
        "field-8": new RadioButtonEl({
            id: "field-8", name: "chatGptGoals", label: "What applies to you?", getPrevValue, options: [
                "I want to explore what ChatGPT/GPT-4 can do for me", "I already know what I want"]
        }),
        "field-7": new RadioButtonEl({ id: "field-7", name: "companySize", label: "What size is your company?", getPrevValue, options: ["Less than 10 Employees", "10-20 Employees", "20-50 Employees", "50+ Employees"] }),
        "field-10": {
            id: "field-10",
            name: "automationGoals",
            label: <>What are you looking to automate?<br /><span style={{ fontWeight: "300", fontSize: '12px' }}>Select all that apply</span></>,
            value: getPrevValue("automationGoals", []),
            type: "multiselect",
            options: [
                "Customer Service",
                "Sales",
                "Advertising",
                "Copywriting",
                "Other",
                "Not Sure Yet"
            ]
        },
        "field-11": {
            id: "field-11",
            label: "What industry?",
            name: "industry",
            type: "dropdown",
            options:
                [
                    "Education",
                    "Retail/Ecommerce",
                    "Restaurant",
                    "Finance",
                    "Auto",
                    "Food and Beverage",
                    "Tech",
                    "Energy",
                    "Real Estate",
                    "Health/Medical",
                    "Sports/Entertainment",
                    "Legal",
                    "Other"
                ].map(it => ({ id: it, value: it, innerText: it })),
            get value() { return (getPrevValue("industry", "--")) },
            validate: function (v = this.value) {
                console.log("v is: ", v)
                return v !== "--"
            },
            get isValid() {
                return this.validate(this.value)
            },
        },
        "field-24": {
            id: "field-24",
            name: 'additionalDetails',
            type: 'textArea',
            value: getPrevValue("additionalDetails", ""),
            label: "Additional Details (Optional)",
            wrapperWidth: '100%',
            isValid: true,
            placeholder: 'Please tell us what you have in mind.',
        },
        "field-23": {
            id: "field-23",
            name: 'repetitiveTasks',
            type: 'textArea',
            value: getPrevValue("repetitiveTasks", ""),
            label: <>What are some of the repetitive tasks that your business performs on a regular basis? (Optional)<br /><span style={{ fontSize: '12px', fontWeight: '300' }}>Such as customer support inquiries, order tracking, or appointment scheduling.</span></>,
            wrapperWidth: '100%',
            isValid: true,
            placeholder: "Enter repetitive tasks that might be candidates for automation.",
        },
        "field-21": {
            id: "field-21",
            name: 'painPoints',
            type: 'textArea',
            value: getPrevValue("painPoints", ""),
            label: <>Are there any industry-specific challenges or pain points that you think ChatGPT could help address? (Optional)</>,
            wrapperWidth: '100%',
            isValid: true,
            placeholder: 'Enter industry-specific challenges.',
        },
        "field-20": {
            id: "field-20",
            name: 'currentChatBotsOrAI',
            type: 'textArea',
            value: getPrevValue("currentChatBotsOrAI", ""),
            label: <>Do you currently have any chatbots or AI systems in place? If so, what have been the results so far? (Optional)</>,
            wrapperWidth: '100%',
            isValid: true,
            placeholder: "i.e. 'I use chatbots but have seen limited results in user engagement.'",
        },
        "field-25": {
            id: "field-25",
            type: "input",
            name: "email",
            placeholder: "Email",
            value: getPrevValue("email", ""),
            label: "Email",
            validate: function (v = this.value) {
                return (validateEmail(v))
            }
        },
        "field-26": {
            id: "field-26",
            type: "input",
            name: "phoneNumber",
            value: getPrevValue("phoneNumber", ""),
            label: "Phone",
            placeholder: "Phone",
            get isValid() {
                return this.validate(this.value)
            },
            shouldShow: false,
            validate: function (v = this.value) {
                let isValid = true;
                let val = v.trim()
                if (val.length === 0)
                    isValid = true
                if (val.length > 0 && !/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(val))
                    isValid = false
                return isValid
            },
        },
        "field-27": {
            id: "field-27",
            type: "checkbox",
            name: "termsAgreement",
            minHeight: "unset",
            checked: getPrevValue("termsAgreement", false),
            value: getPrevValue("termsAgreement", false),
            isValid: getPrevValue("termsAgreement", false),
            text:
                <p style={{ fontSize: '12px', margin: '0' }}>I agree to the <Link to="/terms" target="_blank">Terms</Link> and <Link to="/privacy-policy" target="_blank">Privacy Policy</Link>
                </p>,
            validate: function (v = this.value) {
                return v
            },
        },
    }
    const fieldOrder = [
        "field-7",
        "field-11",
        "field-23",
        "field-21",
        "field-20",
        { fields: ["field-10", "field-8",], id: "group-3" },
        { fields: ["field-6", "field-9", "field-25", "field-26", "field-24", "field-27"], id: "group-7" },

    ]

    const formatData = (fields, formData = {}) => {
        let _fieldOrder = fieldOrder.reduce((a, b) => {
            if (typeof (b) === 'string') {
                a.push([fields[b].name, fields[b].value])
                if (fields[b]['additionalKeyValues']) {
                    a.push(...Object.entries(fields[b].getAdditionalKeyValues(fields[b].value)))
                }
            }
            else {
                const { fields: _fields } = b
                let returnArr = []
                _fields.forEach(it => {
                    if (fields[it]['getAdditionalKeyValues']) {
                        returnArr.push(...Object.entries(fields[it].getAdditionalKeyValues(fields[it].value)))
                    }
                    returnArr.push([fields[it].name, fields[it].value])
                })
                a.push(...returnArr)
            }
            return a
        }, [])
        if (formData) {
            _fieldOrder.push(["startTime", formData['startTime']])
            _fieldOrder.push(["endTime", Date.now()])
        }
        return Object.fromEntries(_fieldOrder)
    }
    const [formData, setFormData] = useState({
        fields,
        fieldOrder,
        active: getPrevValue("active", fieldOrder[0]),
        isValid: getPrevValue("isValid", true),
        startTime: getPrevValue("startTime", Date.now()),
        endTime: null,
        shouldShowValidators: false
    })

    const { active, isValid, shouldShowValidators } = formData
    console.log("isValid:::", isValid, getPrevValue("isValid", true))
    const setShouldShowValidators = () => {
        setFormData(prev => ({ ...prev, shouldShowValidators: true }))
    }

    const setShowDropdown = (field) => {
        setFormData(prev => ({
            ...prev,
            fields: {
                ...prev['fields'],
                [field['id']]: {
                    ...prev['fields'][field['id']],
                    showDropdown: !prev['fields'][field['id']]['showDropdown']
                },
            }
        }))
    }

    const handleStateChange = (e, fieldObj) => {
        let val = e.target.getAttribute('value')
        let fieldId = fieldObj['id']
        sessionStorage.setItem("formDataLong", JSON.stringify({ ...JSON.parse(sessionStorage.getItem("formDataLong")), [fields[fieldId].name]: val, isValid: validateActiveStep(fieldId, val) }))
        setFormData(prev => ({
            ...prev,
            fields: {
                ...prev['fields'],
                [fieldId]: {
                    ...prev['fields'][fieldId],
                    value: val,
                    showDropdown: false,
                    isValid: fieldObj['isValid'] === undefined ? undefined : val !== "--"
                },
            },
            isValid: validateActiveStep(fieldId, val)
        }))
    }

    const validateActiveStep = (overrideField = undefined, overrideValue = undefined) => {
        return (validateStep(active, overrideField, overrideValue))
    }

    const validateStep = (stepToValidate, overrideField = undefined, overrideValue = undefined) => {
        let fieldsToValidate = typeof (stepToValidate) === 'string' ? [stepToValidate] : stepToValidate['fields']
        let _value = fieldsToValidate.reduce((val, b, index) => {
            if (!val)
                return val
            if (overrideField && b === overrideField && typeof (fields[b].validate) === 'function') {
                val = fields[b].validate(overrideValue)
            }
            else if (typeof (fields[b].validate) === 'function' && fields[b]['value'] !== undefined) {
                val = fields[b].validate()
            } else {
                // return val
            }
            return val
        }, true)
        return _value
    }

    const getActiveIndex = (f = active) => fieldOrder.findIndex(it => {
        if (typeof (f) !== typeof (it))
            return false
        if (typeof (f) === 'object')
            return (it['id'] === f['id'])
        else
            return (it === f)
    })

    const submitForm = async (data) => {
        try {
            console.log("data", data)
            const res = await API.submitForm(data)
            if (window.sessionStorage.getItem('formDataLong')) {
                sessionStorage.removeItem('formDataLong')
            }
            if (res) {
                const { googleCount } = res
                // if (googleCount) {
                if (window.dataLayer && Array.isArray(window.dataLayer)) {
                    let ev = {
                        'event': 'form_submission',
                        'form_id': 'PRIMARY FORM',
                        // 'form_entry': formEntry.current
                    }
                    // if (googleCount)
                        ev['isCAC'] = true
                    window.dataLayer.push(ev)
                }
            }
            navigate("/success");
        } catch (error) {
            console.log("error", error)
            if (error.response["data"]) {
                const {
                    response: {
                        status,
                        data: { msg },
                    },
                } = error;
                navigate("/error", {
                    state: { error: { status, msg }, ref: "form-submission" },
                });
            } else {
                navigate("/error", {
                    state: {
                        error: { status: error.response.status, msg: "An error occurred" },
                        ref: "form-submission",
                    },
                });
            }
        }
    }

    const nextStep = async (fields = null, formData = null) => {
        const nextIndex = getActiveIndex() + 1
        console.log("isValid", isValid, formData['isValid'])
        if (isValid && nextIndex < fieldOrder.length) {
            setFormData(prev => ({
                ...prev,
                active: fieldOrder[nextIndex],
                isValid: validateStep(fieldOrder[nextIndex]),
                shouldShowValidators: false
            }))
            sessionStorage.setItem("formDataLong", JSON.stringify({
                ...JSON.parse(sessionStorage.getItem("formDataLong")), active: fieldOrder[nextIndex],
                isValid: validateStep(fieldOrder[nextIndex])
            }))
        }
        else if (isValid && isLastStep) {
            let _data = formatData(fields, formData)
            await submitForm(_data)
        }
        else if (!isValid) {
            setShouldShowValidators(true)
        }
    }

    const prevStep = () => {
        if (!isFirstStep) {
            setFormData(prev => ({
                ...prev,
                shouldShowValidators: false,
                active: fieldOrder[getActiveIndex() - 1],
                isValid: true
            }))
            sessionStorage.setItem("formDataLong", JSON.stringify({
                ...JSON.parse(sessionStorage.getItem("formDataLong")), active: fieldOrder[getActiveIndex() - 1],
                isValid: true
            }))
        }
    }

    const isFirstStep = getActiveIndex() === 0
    const isLastStep = getActiveIndex() === fieldOrder.length - 1

    const getFieldIdByName = (name) => Object.keys(fields).find(field => fields[field].name === name)

    let geoId = getFieldIdByName("geographicArea")
    let geoDId = getFieldIdByName("geographicAreaDetailed")

    let geoHookProps = useGeoHook({ setFormData, fields, geoId, geoDId, validateActiveStep })
    // {
    //     getSuggestions,
    //     setItems
    // }
    const onChange = (field, e) => {
        let value;
        if (typeof (e) === 'string')
            value = e
        else
            value = e.target.value
        let { shouldShow } = fields[field]
        let isValid = false
        if (fields[field]['type'] === 'number')
            value = value.replace(/[^\d.-]/g, "")
        if (fields[field]['name'] === 'phoneNumber') {
            value = formatPhoneNumber(value.replace(/\D/g, ''))
        }
        if (typeof (fields[field].validate) === 'function') {
            isValid = fields[field].validate(value)
            console.log("isValid", isValid)

        } else {
            isValid = true
        }
        if (shouldShowValidators)
            shouldShow = true
        sessionStorage.setItem("formDataLong", JSON.stringify({ ...JSON.parse(sessionStorage.getItem("formDataLong")), [fields[field].name]: value, isValid: validateActiveStep(field, value) }))
        setFormData(prev => ({
            ...prev,
            fields: {
                ...prev['fields'],
                [field]: {
                    ...prev['fields'][field],
                    value,
                    isValid,
                    shouldShow
                }
            },
            isValid: validateActiveStep(field, value)
        }))
    }

    const radioButtonOnClick = (field, v) => {
        sessionStorage.setItem("formDataLong", JSON.stringify({ ...JSON.parse(sessionStorage.getItem("formDataLong")), [fields[field].name]: v, isValid: validateActiveStep(field, v) }))
        setFormData(prev => ({
            ...prev,
            fields: {
                ...prev['fields'],
                [field]: {
                    ...prev['fields'][field],
                    value: v,
                    selected: v
                    // isValid,
                    // shouldShow
                }
            },
            isValid: validateActiveStep(field, v)
        }))
    }

    const multiSelect = (field, v) => {
        setFormData(prev => {

            let value = [...prev['fields'][field].value, v]
                .reduce((a, b) => {
                    if (a.includes(b))
                        a = a.filter(it => it !== b)
                    else
                        a.push(b)
                    return a
                }, [])
            sessionStorage.setItem("formDataLong", JSON.stringify({ ...JSON.parse(sessionStorage.getItem("formDataLong")), [fields[field].name]: value, isValid: validateActiveStep(field, value) }))
            return ({
                ...prev,
                fields: {
                    ...prev['fields'],
                    [field]: {
                        ...prev['fields'][field],
                        value,
                        selected: v
                        // isValid,
                        // shouldShow
                    }
                },
                isValid: validateActiveStep(field, v)
            })
        })
    }

    const onCheck = (fieldId) => {
        // let newValue = !fields[fieldId]['checked']
        setFormData(prev => {
            let _newValue = !prev['fields'][fieldId]['checked']
            sessionStorage.setItem("formDataLong", JSON.stringify({ ...JSON.parse(sessionStorage.getItem("formDataLong")), [fields[fieldId].name]: _newValue, isValid: validateActiveStep(fieldId, _newValue) }))
            return ({
                ...prev,
                fields: {
                    ...prev['fields'],
                    [fieldId]: {
                        ...prev['fields'][fieldId],
                        checked: _newValue,
                        value: _newValue,
                        isValid: _newValue === true

                    }
                },
                isValid: validateActiveStep(fieldId, _newValue)
            })
        })
    }



    return { validateActiveStep, formData, setFormData, getPrevValue, fieldOrder, active, setShowDropdown, handleStateChange, nextStep, prevStep, isFirstStep, isLastStep, onChange, geoHookProps, radioButtonOnClick, multiSelect, onCheck, shouldShowValidators, setShouldShowValidators }
}