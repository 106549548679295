import React, { useState, useEffect, useRef } from 'react'

import TextArea from '../TextArea'
import InputField from '../InputField'

import { List, ListItem, Check } from './RadioButtons.styles'

import Flexbox from '../Flexbox'

const RadioButtons = ({ flexDirection, options, onClick, selected, listWidth, textAreaOnChange, inputFieldOnChange, maxWidth, marginTop }) => {
    const triggerFieldFocus = useRef(false)
    return (
        <List listWidth={listWidth} maxWidth={maxWidth} flexDirection={flexDirection} marginTop={marginTop}>
            {
                Object.keys(options).map((option, index) => {
                    const _option = options[`${option}`]
                    return (
                        <Flexbox column key={(_option['id'] || index) + 'container'} alignItems="start" width="auto" gap="5px">
                            <ListItem id={_option['id'] || index} key={_option['id'] || index} name={_option['id']} checked={selected === _option['id']}
                                onClick={function () {
                                    onClick(_option['id'])
                                    if (_option['inputField'] !== undefined || _option.textArea !== undefined) {
                                        console.log("should trigger")
                                        triggerFieldFocus.current = true
                                    }
                                }}>
                                <Check className="check" checked={selected === _option['id']} />
                                <label htmlFor={_option['id']} className="labelText">{_option['label']}</label>

                            </ListItem>
                            {
                                (_option.textArea !== undefined && textAreaOnChange) &&

                                <TextArea onFocus={function () { onClick(_option['id']) }} margin={"0 0 0 30px"} onChange={textAreaOnChange} />

                            }
                            {
                                (_option.inputField !== undefined && inputFieldOnChange) &&

                                <InputField {..._option['inputFieldProps']} onFocus={function () {
                                    onClick(_option['id'])
                                }} margin={"0 0 0 30px"} onChange={function (e) { inputFieldOnChange(e, option) }} triggerFocus={triggerFieldFocus} value={_option.inputField} />

                            }
                        </Flexbox>
                    )
                })
            }
        </List >
    )
}

export default RadioButtons