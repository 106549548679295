import React, { useState, useEffect, useRef } from 'react'

import { useNavigate, NavLink } from 'react-router-dom'
import Flexbox from '../Flexbox'
import TiledInput from '../TiledInput'
import GridContainer from '../GridContainer'
import GridItem from '../GridItem'
import API from '../../API'

import styled from 'styled-components'

import states from '../../utils/states.js'
import { toTitleCase } from '../../utils'

const SVG = styled.svg`
fill: ${(fill) => fill};
transition: all 1.5s ease-in-out;
opacity: 0.5;
bottom: 50%;
left: 50%;
transform: translate(-50%,50%);
path {
    stroke-linejoin: round;
    stroke-linecap: round;
    /* cursor: pointer; */
}
.selected{
    fill: var(--geoAreaFillSelected);
    transition: all 0.35s ease-in-out;
    
}
`;

const Link = styled(NavLink)`
all: unset;
z-index: 1;
color: #fff;
position: relative;
`;

const GeographicAreaDetailed = ({ placeHolder, onBlur, inputOnly = false, className, inputFieldProps, geoClassName, disabled, setDisabled, svgPath, initialValue, counties = [], setCounties, selectedStates = [], onSuggestionsRetrieval, input, setInput }) => {
    console.log("countiescountiescounties", onBlur)
    const navigate = useNavigate()
    const defaultResponse = {
        h5: 'Enter city where the accident happened.',
        p: '',
    }
    const [serverResponse, setServerResponse] = useState(defaultResponse)
    const [timer, setTimer] = useState(null)
    const svgRef = useRef(null)
    const selectionRef = useRef(null)
    const shouldResetResponseMessage = useRef(false)
    const resultsLength = useRef(null)
    const isTyping = useRef(false)
    const responsePending = useRef(false)
    const [resMessage, setResMessage] = useState({
        msg: '',
        isTyping: false,
        resPending: false,
        typingTimeout: null
    })
    useEffect(() => {
        if (svgRef.current && !Array.isArray(selectedStates)) {

            const _state = getStateByName(selectedStates)
            if (svgPath && typeof (svgPath) === 'object') {
                svgRef.current.appendChild(svgPath)
                console.log("svgPath", svgPath)
            } else {
                let svgPathStorage = sessionStorage.getItem('svgPath')
                if (svgPathStorage) {
                    svgPathStorage = JSON.parse(svgPathStorage)
                    let { svgPath: path, svgPathId } = svgPathStorage
                    const _svgPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                    _svgPath.setAttribute('d', path)
                    _svgPath.setAttribute('id', svgPathId)
                    _svgPath.classList.add('selected')
                    console.log("_svgPath", _svgPath)

                    svgRef.current.appendChild(_svgPath)
                }

            }
            const newEl = document.querySelector(`#${_state.abbreviation}`)
            if (newEl) {
                const bbox = newEl.getBBox()
                const newViewbox = `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`
                svgRef.current.setAttribute("viewBox", newViewbox)
            }

        }
    }, [svgPath])

    const validatorAddItem = (trimmedInput, _counties) => {
        return true
        if (!Array.isArray(counties))
            return true
        if (!_counties.find(it => it.id === trimmedInput)) {
            //setCounties
            return true
        }
        return false
    }

    const getStateByName = (stateName) => states.find(it => it.name === stateName)

    const formatSelection = (trimmedInput) => {
        return trimmedInput
    }

    const isSelected = (name) => {
        if (!Array.isArray(counties))
            return false
        return counties.find(it => it.id === name)
    }

    useEffect(() => {
        if (!Array.isArray(selectedStates)) {
            console.log("counties", counties, selectedStates)
            if (counties['state'] !== selectedStates) {
                if (selectedStates === 'District Of Columbia')
                    setCounties({ id: 'Washington', value: 'Washington', pla_code: "1150000", innerText: "Washington", state: selectedStates })
                else
                    setCounties('')
            }
        }

    }, [selectedStates])

    const findSearchMatch = (value, apiCallResults = []) => {
        return apiCallResults.filter(it => {
            return (!isSelected(it.id))
            //&& findMatchAcrossKeys(state, value))
        })
    }

    async function getSuggestions(_input, setInput) {
        // responsePending.current = true
        //value: state.name, id: state.name
        clearTimers()
        resultsLength.current = null
        setServerResponse(prev => ({ ...prev, h5: 'Searching... This may take a few moments.' }))
        const apicallResults = []
        let records = await API.searchPlaces(_input.replaceAll(' ', '+'), selectedStates)
        if (!records || records.length === 0) {
            setServerResponse(prev => ({ p: '', h5: "We couldn't find that city. Type it in and press next." }))
            // return
        }
        if (records && records.length > 0) {
            records = records.reduce((arr, item) => {

                if (isSelected(item.id))
                    return arr

                const { fields: { coty_name, pla_name, pla_code, geo_point_2d, geo_shape, ste_name, coty_code } } = item

                // if (!pla_name.includes(toTitleCase(_input.split(',')[0].trim())))
                //     return arr


                const formattedItem = {
                    innerText: `${pla_name}`,
                    value: `${pla_name}`,
                    county: coty_name,
                    coty_name,
                    coty_code,
                    pla_code,
                    state: ste_name,
                    id: `${pla_name}`,
                    geo_point_2d,
                    geo_shape
                }

                arr.push({
                    ...formattedItem,
                    onClick: () => {
                        if (!isSelected(item.id)) {
                            // addState(state)
                            setCounties(formattedItem)
                            selectionRef.current = formattedItem.id
                            setServerResponse({ h5: '', p: '' })
                            setInput('')
                            clearTimers()
                        }
                    },
                    key: item.id
                })
                return arr
            }, [])
            setServerResponse(prev => ({ p: "If you don't see the city in the dropdown type it in and press next.", h5: "Select city from dropdown below." }))
        }
        resultsLength.current = records.length


        //     if (!isTyping.current && !responsePending.current)
        // }
        // else {
        //     if (!isTyping.current && !responsePending.current)
        // setServerResponse("If you don't see your city in the dropdown type it in and press next.")
        // }
        // setServerResponse('Select the city from the dropdown menu.')
        // shouldResetResponseMessage.current = false

        if (typeof (onSuggestionsRetrieval) === 'function')
            onSuggestionsRetrieval(records)
        return records

    }

    const clearTimers = () => {
        console.log("clearing timers", timer)
        window.clearTimeout()
        if (timer) {
            clearTimeout(timer)
            setTimer(null)
        }
        shouldResetResponseMessage.current = true
    }

    const handleNoResponse = (delay = 7000) => {
        let canceled = false
        const timer = setTimeout(() => {
            console.log("counties", counties)
            // if (!isTyping.current && !responsePending.current)
            // setServerResponse(<span>If you don't see your city in the dropdown type it in and press next.<br />If you're having trouble, <Link to='/contact' target="_blank">contact us</Link>.</span>)
            if (!canceled && !shouldResetResponseMessage.current && (resultsLength.current === null || resultsLength.current > 0))
                setServerResponse({
                    h5: "If you don't see your city in the dropdown type it in and press next.",
                    p: <>If you're having trouble,
                        <Link to='/contact' target="_blank">contact us</Link>.</>
                })
            else
                shouldResetResponseMessage.current = false
            // else if (shouldResetResponseMessage.current) {
            //     setServerResponse('')
            //     shouldResetResponseMessage.current = false
            // }
        }, delay)
        setTimer(timer)
        return () => {
            canceled = true
            clearTimeout(timer)
            setTimer(null)
        }
    }

    const getStateById = (id) => states.find(it => it.abbreviation === id)

    const additionalReset = () => {
        let geographicAreaDetailedInput_ = document.getElementById('geographicAreaDetailedInput')
        if (!Array.isArray(counties) && counties === '' && (geographicAreaDetailedInput_ && geographicAreaDetailedInput_['value'].trim().length === 0))
            setDisabled(true)
        // else if (geographicAreaDetailedInput_ && geographicAreaDetailedInput_['value'].trim().length > 0)
        //     setDisabled(false)
    }
    console.log(typeof (counties), counties['id'] === "")
    if (!inputOnly)
        return (
            <GridContainer className={className} column align="center" maxWidth="90%" width="700px" justify="center" justifyItems="center" gap="7px" gridTemp={{ rows: 'min-content .83rem 13px min-content' }}>
                {/* <GridItem row="1 / -1"> */}
                <SVG
                    ref={svgRef}
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    viewBox="0 0 1000 589"
                    height="400px"
                    maxWidth="90%"
                    width="auto"
                    id="map"
                    fill={'var(--geoAreaFillDefault)'}
                    style={{
                        position: 'absolute',
                        cursor: 'default !important',
                        stroke: 'var(--geoAreaDetailedFillDefault)',
                        strokeLinejoin: 'round',
                        strokeLinecap: 'round',
                        transition: 'fill 0.5s ease-in-out'
                    }}
                >
                </SVG>
                {/* </GridItem> */}
                <h1 style={{ margin: '0' }}>What city were you in?</h1>
                <h5 style={{
                    margin: '0',
                    maxWidth: '500px',
                    textAlign: 'center'
                }}>{serverResponse['h5']}</h5>
                <Flexbox fontSize="13px" lineHeight="1em" >{serverResponse['p']}</Flexbox>
                <TiledInput
                    // onFocus={onFocus}
                    // background="rgba(255,255,255,0.25)"
                    width="100%"
                    maxWidth="350px"
                    inputId="geographicAreaDetailedInput"
                    disabled={disabled}
                    onBlur={onBlur}
                    className={geoClassName}
                    inputFieldProps={inputFieldProps}
                    setDisabled={setDisabled}
                    shouldResetResponseMessage={shouldResetResponseMessage}
                    validatorAddItem={validatorAddItem}
                    responsePending={responsePending}
                    isTyping={isTyping}
                    multiTermEnabled={false}
                    useOptionOnClick={true}
                    placeHolder={placeHolder || "Enter City"}
                    items={
                        (typeof (counties) === 'string' && counties === '')
                            || (typeof (counties) === 'object' && counties['id'] === '') ? [] : [counties]}
                    setItems={setCounties}
                    tagOnClick={function (_tag) {
                        setCounties('')
                        selectionRef.current = null
                        clearTimers()
                    }}
                    // validatorRemoveItem={validatorRemoveItem}
                    formatSelection={formatSelection}
                    additionalReset={additionalReset}
                    handleNoResponse={handleNoResponse}
                    // _input={input}
                    // _setInput={setInput}
                    getSuggestions={getSuggestions} />


            </GridContainer>
        )
    else
        return (<TiledInput
            // onFocus={onFocus}
            // background="rgba(255,255,255,0.25)"
            width="100%"
            maxWidth="350px"
            inputId="geographicAreaDetailedInput"
            tooltip={disabled ? "Enter state above first" : undefined}
            disabled={disabled}
            onBlur={onBlur}
            className={geoClassName}
            inputFieldProps={inputFieldProps}
            setDisabled={setDisabled}
            {...inputFieldProps}
            shouldResetResponseMessage={shouldResetResponseMessage}
            validatorAddItem={validatorAddItem}
            responsePending={responsePending}
            isTyping={isTyping}
            multiTermEnabled={false}
            useOptionOnClick={true}
            placeHolder={placeHolder || "Enter City"}
            items={
                (typeof (counties) === 'string' && counties === '')
                    || (typeof (counties) === 'object' && counties['id'] === '') ? [] : [counties]}
            setItems={setCounties}
            tagOnClick={function (_tag) {
                setCounties('')
                selectionRef.current = null
                clearTimers()
            }}
            // validatorRemoveItem={validatorRemoveItem}
            formatSelection={formatSelection}
            additionalReset={additionalReset}
            handleNoResponse={handleNoResponse}
            // _input={input}
            // _setInput={setInput}
            getSuggestions={getSuggestions} />)
}

export default GeographicAreaDetailed