export default `uniform sampler2D globeTexture;//default type for textures
varying vec2 vertexUV; // after we add this to vertex.glsl, we add it here too
varying vec3 vertexNormal;

void main() {
    //looping over the pixels
    
    // js version of this would be [1, 0, 0, 1] for red 100% opacity (rgb)
    // we have to pass the texture of the globe (img) using a "uniform"
    // like props from react
    // gl_FragColor = vec4(0, 1, 1, 1);
    // texture2D(sampler2D sampler, vec2 coord) second thing is the uv
    // u and v are just x and y coords

    // comes with glsl by default. bad documentation.
    // try book of shaders instead https://thebookofshaders.com/
    // gl_FragColor = vec4(0, 1, 1, 1); //teal sphere

    // grid system is laid out onto the texture
    // then we are returning the color for each vertex that is overlaid
    // then return 2d texture @ that coord
    // gl_FragColor =texture2D(globeTexture, vertexUV);

    // gl_FragColor =texture2D(globeTexture, vertexUV).xyz allows us to modify the xyz stuff
    // but we must convert back to vec4(texture2D(texture,uv), 1)
    // adding a red tint on top for example:
    // gl_FragColor =vec4(vec3(0, 0, 1) + texture2D(globeTexture, vertexUV).xyz, 1.0);


    // creating an atmospheric glow
    // vectorNormal is the direction the specific vertex is facing
    // any time you want to pass vertex data to the fragment shader,
    // it has to first be done through the vertex shader, declared as varying
    float intensity = 1.05 - dot(vertexNormal, vec3(0.0, 0.0, 1.0)); // info @ 49:52 https://www.youtube.com/watch?v=vM8M4QloVL0
    vec3 atmosphere = vec3(0.3, 0.6, 1.0) * pow(intensity, 1.5); // the value in vec3 is the color
    //gl_FragColor =vec4(atmosphere + texture2D(globeTexture, vertexUV).xyz, 1.0);
    gl_FragColor = texture2D(globeTexture, vertexUV);
    }`