import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import PageContentContainer from '../PageContentContainer'
import Flexbox from '../Flexbox'
import InputField from '../InputField'
import TextArea from '../TextArea'
import Button from '../Button'
import { formatPhoneNumber, toTitleCase } from '../../utils'
import { validateEmail } from '../Form'
import { StyledLink } from '../PrivacyPolicy'
import Airplane from '../Airplane'
import API from '../../API'

function validateName(str) {
    return /\d/.test(str);
}

const ContactForm = () => {
    const navigate = useNavigate()
    const initial = {
        'field-1': {
            name: 'name',
            type: 'input',
            value: '',
            label: "Name",
            wrapperWidth: '100%',
            isValid: false,
            shouldShow: false,
            validate: function (v = this.value) {
                return { value: v.trim() !== '', message: 'Name required' }
            },
        },
        'field-4': {
            name: 'company',
            type: 'input',
            value: '',
            label: "Company",
            wrapperWidth: '100%',
            isValid: true,
            shouldShow: false,
            // validate: function () {
            //     return { value: this.value.trim() !== '', message: 'Name required' }
            // },
        },
        'field-5': {
            name: 'phoneNumber',
            type: 'input',
            value: '',
            label: "Phone (Optional)",
            wrapperWidth: '100%',
            isValid: true,
            shouldShow: false,
            validate: function (v = this.value) {
                let isValid = true;
                let val = v.trim()
                if (val.length > 0 && !/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(val))
                    isValid = false
                return { value: isValid, message: 'Phone Number invalid' }
            },

        },
        'field-2': {
            name: 'email',
            type: 'input',
            value: '',
            label: "Email",
            wrapperWidth: '100%',
            isValid: false,
            shouldShow: false,
            placeholder: 'hello@company.com',
            validate: function (v = this.value) {
                return { value: validateEmail(v), message: 'Invalid email' }
            }
        },
        'field-3': {
            name: 'message',
            type: 'TextArea',
            value: '',
            isValid: false,
            shouldShow: false,
            label: "Message",
            validate: function (v = this.value) {
                return { value: v.trim() !== '', message: 'Must include message' }
            }
        },
    }
    const onChange = (field, e) => {
        let value = e.target.value
        let { shouldShow } = formData[field]
        let isValid = false
        if (formData[field]['name'] === 'phoneNumber') {
            value = formatPhoneNumber(value.replace(/\D/g, ''))
        }
        if (typeof (formData[field].validate) === 'function') {
            isValid = formData[field].validate(value)
            console.log("isValid", isValid)
            isValid = isValid['value']
        } else {
            isValid = true
        }
        if (shouldShowValidators)
            shouldShow = true
        setFormData(prev => ({
            ...prev,
            [field]: {
                ...prev[field],
                value,
                isValid,
                shouldShow
            }
        }))
    }
    const [shouldShowValidators, setShouldShowValidators] = useState(false)
    const [formData, setFormData] = useState(initial)
    const [serverResponse, setServerResponse] = useState({ msg: '', didSend: false, sending: false, buttonText: 'Submit' })
    const [valid, setValid] = useState(false)

    function validateFields(returnBoolOnly = false) {
        if (serverResponse['sending'])
            return false
        if (window.sessionStorage.getItem('didSubmitContactForm')) {
            setServerResponse({ msg: 'Message sent!', didSend: true, sending: false, buttonText: '' })
            return false
        }
        let _value = Object.keys(formData).reduce((val, b) => {

            if (typeof (formData[b].validate) === 'function' && val['value']) {
                val = formData[b].validate()
            } else {
                // return val
            }
            return val
        }, { value: true, message: '' })

        if (returnBoolOnly)
            return _value['value']
        else
            return _value
    }

    function resetValidatorFields() {
        let _formData = formData
        Object.keys(_formData).forEach(field => {
            _formData[field]['shouldShow'] = false
        })
        setFormData(prev => ({
            ...prev,
            ..._formData
        }))
    }

    async function submitContactForm() {
        const { value: _isValid, message } = validateFields()
        resetValidatorFields()
        if (_isValid) {
            setShouldShowValidators(false)
            setServerResponse({ msg: '', didSend: false, sending: true, buttonText: 'Sending...' })
            try {
                const req = {}
                Object.keys(formData).forEach(key => {
                    req[formData[key].name] = formData[key].value
                })
                console.log("req", req)
                const res = await API.submitContactForm(req)
                sessionStorage.setItem('didSubmitContactForm', true)
                const { data: { msg } } = res
                setServerResponse({ msg: msg, didSend: true, sending: false, buttonText: 'Sent!' })
            } catch (error) {
                console.log("an error occurred: ", error)
                const { response: { data: { msg } } } = error
                setServerResponse({ msg: msg, didSend: false, sending: false, buttonText: 'Submit' })
            }
        } else {
            setShouldShowValidators(true)
            // setServerResponse({ msg: message, didSend: false, sending: false, buttonText: 'Submit' })
        }
    }
    useEffect(() => {
        if (validateFields(true))
            setValid(true)
        else
            setValid(false)

        // return () => {
        //     sessionStorage.removeItem('didSubmitContactForm')
        // }

    }, [formData])
    const { didSend } = serverResponse

    return (
        <PageContentContainer justifyContent="center" alignItems="start" background="var(--formBackground)" padding="30px 0" height="100%" minHeight="100vh" margin="calc(var(--navBarHeight) * -1) 0 0" position="relative">
            <Flexbox margin="auto 0"
                color="#fff"
                // border="1px solid #000" 
                padding="30px" column alignItems="start"
                mobilePadding="0"
                // background="#fff" 
                width="90%" maxWidth="700px">
                {
                    serverResponse['didSend'] &&

                    <>
                        <Airplane />
                        <h1 style={{ fontSize: '3rem', textAlign: 'center', alignSelf: "center", marginTop: '0' }}>{
                            serverResponse['msg']
                        }</h1>
                    </>

                }
                {
                    !serverResponse['didSend'] &&
                    <>
                        <h2 style={{ textAlign: 'center', alignSelf: "center" }}>Contact Us</h2>
                        <p style={{ margin: '0' }}>You can also reach us by email at <StyledLink>{process.env.REACT_APP_SUPPORT_EMAIL}</StyledLink>.</p>
                        {
                            Object.keys(formData).map(field => {
                                console.log("formdata", formData[field])
                                if (formData[field].type === 'input')
                                    return (
                                        <InputField
                                            // color="#000"
                                            key={field}
                                            border="none; border-bottom: 1px solid #fff"
                                            // border="1px solid #000" 
                                            labelTextAlign="left"
                                            labelFontSize="14px"
                                            focusOutline="none" padding="0 40px 0 20px"
                                            outline="none"
                                            focusBorder="none; border-bottom: 2px solid #fff"
                                            flexDirection="column"
                                            background="rgba(255,255,255,0.1)"
                                            // focusOutline="1px solid var(--formBlue1)"
                                            placeholder={toTitleCase(formData[field]['name'] !== 'phoneNumber' ? formData[field]['name'] : '123-456-7890')}
                                            {...formData[field]}
                                            display="flex"
                                            showValidationCheckmark={(shouldShowValidators && !formData[field]['isValid']) || formData[field]['shouldShow']}
                                            isValid={formData[field]['isValid']}
                                            onChange={(e) => {
                                                onChange(field, e)

                                            }}
                                            width="100%"></InputField>)
                                else
                                    return (
                                        <>
                                            <label style={{ fontSize: '14px' }}>
                                                <h3 style={{ textAlign: 'left' }}>{formData[field]['label']}</h3>
                                            </label>
                                            <TextArea maxLength={1000} showCharCount={true}
                                                key={field}
                                                // color="#000" 
                                                // border="1px solid #000" 
                                                // focusOutline="1px solid var(--formBlue1)" 
                                                color="#fff"
                                                placeholder={toTitleCase(formData[field]['name'])}
                                                background="rgba(255,255,255,0.1)"
                                                border="none; border-bottom: 1px solid #fff"
                                                padding="10px 35px 10px 20px"
                                                focusBorder="none; border-bottom: 2px solid #fff"
                                                focusOutline="none"
                                                {...formData[field]}
                                                showValidationCheckmark={(shouldShowValidators && !formData[field]['isValid']) || formData[field]['shouldShow']}
                                                isValid={formData[field]['isValid']}
                                                onChange={(e) => { onChange(field, e) }} label={undefined}></TextArea>
                                        </>
                                    )
                            })
                        }
                        <Flexbox>
                            <p style={{ fontSize: '12px', margin: '15px 0 0' }}>
                                Your information will be handled as detailed in our <span style={{
                                    color: '#ffffff',
                                    textDecoration: 'underline',
                                    // color: 'var(--formBlue1)',
                                    fontWeight: '700', cursor: 'pointer'
                                }} onClick={() => navigate('/privacy-policy#personal-information-collection')}>Privacy Policy</span>.
                            </p>
                        </Flexbox>
                        <Button text={serverResponse['buttonText']} className={!valid || serverResponse['sending'] ? 'disabled' : undefined}
                            // background={!valid || serverResponse['sending'] ? 'var(--buttonDisabledBackground)' : undefined}
                            // border={!valid || serverResponse['sending'] ? 'var(--buttonDisabledBorder)' : undefined}
                            // color={!valid || serverResponse['sending'] ? 'var(--buttonDisabledColor)' : undefined}
                            borderHover="1px solid #ffffff"
                            bgdHover="#ffffff"
                            background={!valid || serverResponse['sending'] ? 'rgba(255,255,255,0.1)' : '#ffffff'}
                            border={!valid || serverResponse['sending'] ? '1px solid #fff' : '1px solid #fff'}
                            // color={!valid || serverResponse['sending'] ? 'var(--buttonDisabledColor)' : 'var(--formBlue1)'}
                            color={!valid || serverResponse['sending'] ? 'var(--buttonDisabledColor)' : 'var(--formBlue1)'}
                            cursor={!valid || serverResponse['sending'] ? 'not-allowed' : undefined}
                            onClick={submitContactForm}
                            margin="30px 2px 0" width="calc(100% - 8px)" padding="20px 0" />

                        <Flexbox fontSize="12px" height="1em" width='100%' margin="1em 0">
                            {
                                serverResponse['msg']
                            }
                        </Flexbox>
                    </>
                }

            </Flexbox>

        </PageContentContainer>
    )
}

export default ContactForm