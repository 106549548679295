import React, { useState, useEffect, useRef } from 'react'
import states from "../utils/states.js";
import { toTitleCase, formatPhoneNumber } from '../utils'

export const useGeoHook = ({ setFormData, fields, geoId, geoDId, validateActiveStep }) => {

    const setItems = (items) => {
        let newValue = items.length > 0 ? items[0].value : ""
        console.log("newValue", newValue)
        sessionStorage.setItem("formDataLong", JSON.stringify({ ...JSON.parse(sessionStorage.getItem("formDataLong")), [fields[geoId].name]: newValue }))
        if (geoDId && geoId)
            setFormData(prev => ({
                ...prev,
                fields: {
                    ...prev.fields,
                    [geoId]: {
                        ...prev.fields[geoId],
                        items: items,
                        value: newValue,
                    },
                    [geoDId]: {
                        ...prev.fields[geoDId],
                        selectedStates: newValue,
                        isEnabled: items.length > 0 ? true : false,
                        placeHolder: items.length > 0 ? 'Enter City' : 'Enter State Above First',
                    }
                },
                isValid: validateActiveStep(geoId, newValue)
            }))
    }

    function addState(state) {
        setItems([{ value: state.name, id: state.name }])
    }

    const isSelected = (stateName) => {
        if (geoId)
            return fields[geoId]['items'].find(it => it.value === stateName)
        else
            return false
    }

    const normalizeString = (str) => {
        return str.toLowerCase()
    }

    const findMatchAcrossKeys = (obj, value) => {
        return Object.keys(obj).reduce((a, key) => {
            let v = normalizeString(value)
            let vK = normalizeString(obj[key])
            // let regex = new 
            if (normalizeString(obj[key]).includes(normalizeString(value)))
                return true
            else return a
        }, false)
    }

    const findSearchMatch = (value) => {
        return states.filter(state => {
            return (!isSelected(state.name) && findMatchAcrossKeys(state, value))
        })
    }

    const getSuggestions = (_input, setInput) => {
        //value: state.name, id: state.name
        return findSearchMatch(_input).map(state => {
            return ({
                innerText: state.name,
                value: state.abbreviation,
                onClick: () => {
                    if (!isSelected(state.name)) {
                        addState(state)
                        setInput('')
                    }
                },
                key: state.abbreviation
            })
        })
    }

    const setCounties = (v) => {
        sessionStorage.setItem("formDataLong", JSON.stringify({ ...JSON.parse(sessionStorage.getItem("formDataLong")), [fields[geoDId].name]: v }))
        setFormData(prev => ({
            ...prev,
            fields: {
                ...prev['fields'],
                [geoDId]: {
                    ...prev['fields'][geoDId],
                    value: v
                },
            },
            isValid: validateActiveStep(geoDId, v)
        }))
    }

    const onBlurGeoDetailed = (_fields = fields) => {
        if (_fields[geoDId]['value'] === '') {
            const inputField = document.getElementById('geographicAreaDetailedInput')
            if (inputField && inputField['value'].trim().length > 0)
                setFormData(prev => {

                    sessionStorage.setItem("formDataLong", JSON.stringify({
                        ...JSON.parse(sessionStorage.getItem("formDataLong")), [_fields[geoDId].name]:
                        {
                            id: inputField['value'],
                            innerText: inputField['value'],
                            value: inputField['value'],
                            state: prev['fields'][geoId]['value']
                        }
                    }))
                    return ({
                        ...prev,
                        fields: {
                            ...prev['fields'],
                            [geoDId]: {
                                ...prev['fields'][geoDId],
                                value: {
                                    id: inputField['value'],
                                    innerText: inputField['value'],
                                    value: inputField['value'],
                                    state: prev['fields'][geoId]['value']
                                }
                            },
                        },
                        isValid: validateActiveStep(geoDId, inputField['value'])
                    })
                })
        }
    }

    return {
        getSuggestions,
        setItems,
        setCounties,
        onBlurGeoDetailed
    }
}