import React from 'react'
import Checkbox from '../Checkbox'
import Flexbox from '../Flexbox'
const MultiSelect = ({ options, onSelect, value = [], ...props }) => {
    console.log("value", value)
    return (
        <Flexbox column maxHeight="350px" flexWrap="wrap" mobileMaxHeight="unset" fontSize="16px">
            {
                options.map((it, index) => <Checkbox {...it} key={index + 1} text={it} minHeight="unset"
                    fontSize="16px"
                    margin="5px 20px 5px 0"
                    onCheck={(e) => {
                        // console.log(e.target.value)
                        console.log("it", it)
                        onSelect(it)
                    }} checked={value.includes(it)} />)
            }
        </Flexbox>
    )
}

export default MultiSelect