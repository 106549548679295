export default `varying vec2 vertexUV; //we have to pass the UV to the fragment shader
varying vec3 vertexNormal;
//some people use vUV instead of vertexUV
void main() {

    // for every vertex we are setting its position.
    // this is the xyz coord of the vert you're looping over
    // [1, 0, 0, 1] all vertices have an x of 1, all vertices would be moved to x=1
    // last coord is for translates and transforms
    // all types must be declared
    // glsl version of [1, 0, 0, 1] is vec4(1, 0, 0, 1)
    //gl_Position = vec4(1, 0, 0, 1);

    // THREE js passes in a few variables by default to this shader
    // which includes vertices position in relation to the sphere they are placed on
    // https://threejs.org/docs/#api/en/renderers/webgl/WebGLProgram
    // link shows what's provided to shader by default

    // position is a vec3, but gl_Position requires vec4. just replace first
    // three indexes of vec4 with position
    // but the shader needs to know how THREE js does calcs to translate the 3d
    // objects onto 2d space. (see link above)
    // gl_Position = vec4(position, 1); //position is vec3(x, y, z)


    vertexUV = uv; //uv from https://threejs.org/docs/#api/en/renderers/webgl/WebGLProgram
    // vertexNormal = normal;
    vertexNormal = normalize(normalMatrix * normal);
    // therefore, the code above becomes the code below (again, see link)
    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );


}`