import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import PageContentContainer from '../PageContentContainer'
import GridContainer from '../GridContainer'
import GridItem from '../GridItem'
import Button from '../Button'
import API from '../../API'
import InputField from '../InputField'
import { formatPrice, GridTemp } from '../../utils'
import styled from 'styled-components'

import { StyledLink } from '../PrivacyPolicy'

const H2 = styled.h2`
font-size: inherit;
`;

const Checkout = () => {
    let { userId } = useParams();
    const [state, setState] = useState({
        initialLoad: true,
        email: '',
        success: false,
        sending: false,
        didSend: false,
        currentBudget: 0,
        paymentFieldValue: '0',
        isValid: false
    })

    useEffect(() => {
        const getBudget = async () => {
            try {
                const { data: { client: { budget, email } } } = await API.getBudget(userId)
                if (budget !== undefined && email !== undefined) {
                    setState(prev => ({ ...prev, currentBudget: budget, initialLoad: false, email }))
                }
            } catch (error) {
                const { msg } = error['response']['data']
            }

        }
        if (userId) {
            getBudget()
        }
    }, [userId])

    const checkout = async () => {
        try {
            const { url } = await API.checkout({
                userId,
                fundsToAdd: paymentFieldValue
            })
            console.log("checkoutURL", url)
            window.location = url
        } catch (error) {
            console.log("error is: ", error['response']['data'])
        }

        // window.location = checkoutURL
    }
    const { email, currentBudget, paymentFieldValue, initialLoad, sending, isValid } = state
    return (
        <PageContentContainer background="var(--formBackground)" justifyContent="center" color="#fff">
            <GridContainer maxWidth="500px" gap="10px" gridTemp={new GridTemp('min-content', '1fr 1fr')} autoRows='min-content' textAlignEven="right" textAlignOdd="right" fontSize="1rem" >
                <h1 style={{ gridColumn: '1 / span 2' }}>Leads Budget - Add Funds</h1>
                <GridItem col="1 / span 2" textAlign="left !important"><p>Leads will be sent to your email address on file, <strong>{email}</strong>. Contact us at <StyledLink>{process.env.REACT_APP_SUPPORT_EMAIL}</StyledLink> to update your email address.</p></GridItem>
                <GridItem><H2>Funds Remaining</H2></GridItem>
                <GridItem padding="0 20px 0 0"><H2>{formatPrice(currentBudget)}</H2></GridItem>
                <GridItem><H2>Additional Deposit</H2></GridItem>
                <GridItem>
                    <InputField
                        border="none; border-bottom: 1px solid #fff"
                        name="price"
                        labelTextAlign="left"
                        labelFontSize="14px"
                        value={state['paymentFieldValue']}
                        display="flex"
                        focusOutline="none"
                        outline="none"
                        padding="0 20px 0 40px"
                        textAlign="right"
                        focusBorder="none; border-bottom: 2px solid #fff"
                        flexDirection="column"
                        background="rgba(255,255,255,0.1)"
                        onChange={(value) => {
                            setState(prev => ({ ...prev, paymentFieldValue: value, isValid: Number(value.replace(/\D/g, "")) > 0 }))
                        }}
                        width="100%"></InputField>
                </GridItem>
                <GridItem><H2>Budget After Deposit: </H2></GridItem>
                <GridItem padding="0 20px 0 0"><H2>{formatPrice(Number(paymentFieldValue.replace(/\D/g, "")) + currentBudget)}</H2></GridItem>
                <GridItem col="1 / span 2"><Button text="Checkout"
                    className={sending || !isValid ? 'disabled' : undefined}
                    // background={sending  || !isValid ? 'var(--buttonDisabledBackground)' : undefined}
                    // border={sending  || !isValid ? 'var(--buttonDisabledBorder)' : undefined}
                    // color={sending  || !isValid ? 'var(--buttonDisabledColor)' : undefined}
                    borderHover="1px solid #ffffff"
                    bgdHover="#ffffff"
                    disabled={sending || !isValid}
                    background={sending || !isValid ? 'rgba(255,255,255,0.1)' : '#ffffff'}
                    border={sending || !isValid ? '1px solid #fff' : '1px solid #fff'}
                    // color={sending  || !isValid ? 'var(--buttonDisabledColor)' : 'var(--formBlue1)'}
                    color={sending || !isValid ? 'var(--buttonDisabledColor)' : 'var(--formBlue1)'}
                    cursor={sending || !isValid ? 'not-allowed' : undefined}
                    onClick={checkout}
                    margin="30px 2px 0" width="calc(100% - 8px)" padding="20px 0" /></GridItem>

            </GridContainer>

        </PageContentContainer>
    )
}

export default Checkout