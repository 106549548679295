import React, { useState, useEffect, useRef } from 'react'

import Dropdown from '../Dropdown'
import Flexbox from '../Flexbox'

import { debounce } from '../../utils'

import { InnerContainer, TagItem, TagsInputContainer, Input } from './SearchTermHints'

const SearchTermHints = ({
    height,
    minHeight,
    marginTop,
    dropdownRef,
    trigger, // input field is default
    suggestions = [],
    setSuggestions,
    getAndFormatSearchResults,
    dropdownSelection,
    setDropdownSelection,
    shouldExecuteSearch,
    shouldResetSearchSuggestions,
    listContainerRef,
    handleNoResponse,
    currSelection,
    resetSearchResults,
    inputRef,
    shouldResetResponseMessage,
    delay = 1000

}) => {
    let _timeout;
    const initial = useRef(true)
    // const listContainerRef = useRef(null)
    const [showDropdown, setShowDropdown] = useState(false)


    async function searchFunction(canceled = false) {
        console.log("go")
        const res = await getAndFormatSearchResults()
        if (!canceled) {
            if (res && res.length > 0) {
                setSuggestions(res)
                setDropdownSelection(null)
                setShowDropdown(true)
            } else {
                setSuggestions([])
                setDropdownSelection(null)
                setShowDropdown(false)
            }
            handleNoResponse()
        }

    }

    useEffect(() => {
        if (initial.current) {
            // note we can mutate this directly instead of using a "setStateXyz"
            // function because it's a useRef
            initial.current = false;
            return; // if initial render, skip useEffect
        }
        if (shouldExecuteSearch()) {
            // console.log("should execute")
            // // debounce(async (e) => await searchFunction(e), delay)
            // _timeout = setTimeout(async function () {
            //     clearTimeout(_timeout)
            //     await searchFunction()
            //     // if (typeof (handleNoResponse) === 'function')
            //     //     handleNoResponse()
            // }, delay)
            // // return () => clearTimeout(_timeout)
            let canceled = false
            const timer = setTimeout(async () => {
                await searchFunction(canceled)
            }, delay)
            return () => {
                canceled = true
                clearTimeout(timer)
            }
        } else if (shouldResetSearchSuggestions()) {
            console.log("should reset")
            if (typeof (resetSearchResults) === 'function')
                resetSearchResults()
        }

        // if (inputRef.current !== null) {

    }, [trigger])

    return (
        <>
            {suggestions.length > 0 &&
                <Flexbox position='absolute' width="100%" height={height} minHeight={minHeight} >
                    <Dropdown
                        dropdownRef={dropdownRef}
                        listContainerRef={listContainerRef}
                        label='Tempo'
                        headerHeight="49px"
                        itemHeight="49px"
                        maxHeight={4 * 49 + 'px'}
                        dropdownListMarginTop={marginTop}
                        showHeader={false}
                        name={'Suggested Tags'}
                        marginBottom={'1rem'}
                        options={suggestions}
                        menuState={showDropdown}
                        handleStateChange={() => setShowDropdown(!showDropdown)}
                        currSelection={currSelection ? currSelection : null}
                        valueX={[]}
                        id={'suggested-tags'}
                        setValidContinue={() => { }}
                        justifyContent="start"
                        textAlign="left"
                        // letterSpacing="0.1rem"
                        // fontSize="var(--fontSmall)"
                        // fontWeight="700"
                        // boxShadow='0 0 2px #000'
                        // background="#000"
                        width="100%"
                    ></Dropdown>
                </Flexbox>
            }
        </>
    )
}

export default SearchTermHints